import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
  Form,
  Row,
  Col,
} from 'react-bootstrap';

// Components
import SidePanel from './SidePanel';
import IncrementBtnCircle from '../IncrementBtnCircle/IncrementBtnCircle';
import FooterButton from './FooterButton';

import update from './update';

// Locale
import messages from '../../locale/messages';

// Style
import s from './ListPlaceStep1.css';
import bt from '../../components/commonStyle.css';

class RoomDetailsPage extends Component {

  static propTypes = {
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    beds: PropTypes.number,
    nextPage: PropTypes.any,
    isExistingList: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      beds: {
        itemName: null,
        otherItemName: null,
        startValue: 0,
        endValue: 0
      },
      bedrooms: {
        itemName: null,
        otherItemName: null,
        startValue: 0,
        endValue: 0
      },
      personCapacity: {
        itemName: null,
        otherItemName: null,
        startValue: 0,
        endValue: 0
      },
      privateBathrooms: {
        itemName: null,
        otherItemName: null,
        startValue: 0,
        endValue: 0
      },
      sharedBathrooms: {
        itemName: null,
        otherItemName: null,
        startValue: 0,
        endValue: 0
      },
    }
  }

  UNSAFE_componentWillMount() {
    const { listingFields } = this.props;

    if (listingFields != undefined) {
      this.setState({
        beds: listingFields.beds[0],
        bedrooms: listingFields.bedrooms[0],
        personCapacity: listingFields.personCapacity[0],
        privateBathrooms: listingFields.privateBathrooms[0],
        sharedBathrooms: listingFields.sharedBathrooms[0],
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { listingFields } = nextProps;
    if (listingFields != undefined) {
      this.setState({
        beds: listingFields.beds[0],
        bedrooms: listingFields.bedrooms[0],
        personCapacity: listingFields.personCapacity[0],
        privateBathrooms: listingFields.privateBathrooms[0],
        sharedBathrooms: listingFields.sharedBathrooms[0],
      });
    }
  }

  renderCircleButtonCounter = (field) => (
    <IncrementBtnCircle
      {...field}
    />
  );

  renderSelectField = ({ input, label, meta: { touched, error }, children }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <select
          {...input}
        >
          {children}
        </select>
        {touched && error && <span>{formatMessage(error)}</span>}
      </div>
    )
  }

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className, disabledOptionLabel }) => {
    return (
      <div>
        <Form.Select {...input} className={className}>
          <option value='' disabled hidden>{disabledOptionLabel}</option>
          {children}
        </Form.Select>
      </div>
    )
  }

  render() {
    const { handleSubmit, previousPage, nextPage, isExistingList, formPage, step, roomTypeSelected, listingFields, formValues } = this.props;
    const { formatMessage } = this.props.intl;
    const { bedrooms, beds, personCapacity, privateBathrooms, sharedBathrooms } = this.state;

    let path = "location";
    if (isExistingList) {
      path = "map";
    }

    let nextButtonIsDisabled = true;
    // if (formValues.personCapacity > 0
    //   && formValues.bedrooms > 0
    //   && formValues.bedTypesCount?.filter(item => item !== null && item !== 0).length > 0
    //   && formValues.privateBathrooms > 0
    //   && formValues.sharedBathrooms > 0
    // ) {
      nextButtonIsDisabled = false;
    // }
    // else {
    //   nextButtonIsDisabled = true;
    // }

    return (
      <div className={cx(s.grid, 'arrowPosition')}>
        <SidePanel
          title={formatMessage(messages.stepOneCommonHeading)}
          landingContent={formatMessage(messages.howManyGuests)}
        />
        <form onSubmit={handleSubmit}>
          <div className={s.landingMainContent}>
            <div className='mb-5'>
              <div className={cx(s.landingLabel, 'textWhite')}>
                {formatMessage(messages.become_host_guests_count_title)}
              </div>
              <Form.Group className={cx(s.space5, s.roomDetailsFormGroup, 'd-flex justify-content-between')}>
                <Form.Label>
                  {formatMessage(messages.guestsCount)}
                </Form.Label>
                <Field
                  name="personCapacity"
                  type="text"
                  component={this.renderCircleButtonCounter}
                  labelSingular={personCapacity.itemName}
                  labelPlural={personCapacity.otherItemName}
                  maxValue={personCapacity.endValue}
                  minValue={personCapacity.startValue}
                  incrementBy={1}
                />
              </Form.Group>
            </div>

            <div className='mb-5'>
              <div className={cx(s.landingLabel, 'textWhite')}>
                {formatMessage(messages.howManyBedrooms)}
              </div>
              <Form.Group className={cx(s.space5, s.roomDetailsFormGroup, 'd-flex justify-content-between')}>
                <Form.Label>
                  {messages.bedroomsCount ? formatMessage(messages.bedroomsCount) : 'Nb. of Bedrooms'}
                </Form.Label>
                <Field
                  name="bedrooms"
                  type="text"
                  component={this.renderCircleButtonCounter}
                  labelSingular={bedrooms.itemName}
                  labelPlural={bedrooms.otherItemName}
                  incrementBy={1}
                  minValue={(roomTypeSelected === '113') ? 1 : bedrooms.startValue}
                  maxValue={(roomTypeSelected === '113') ? 1 : bedrooms.endValue}
                />
              </Form.Group>
            </div>

            <Form.Group className={cx(s.roomDetailsFormGroup, 'mb-5')}>
              <Form.Label className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.howManyBeds} />
              </Form.Label>
              <Row className="bedtype-counters-wrapper d-flex justify-content-between">
              {
                listingFields?.bedType?.map((bedTypeData, index) => (
                  <Col xs={6} key={index} className="bedtype-counter mb-3 d-flex flex-column" style={{alignItems: (index%2) ? 'flex-end' : 'flex-start'}}>
                    <div>{messages[bedTypeData.itemName] ? formatMessage(messages[bedTypeData.itemName]) : bedTypeData.itemName}</div>
                    <Field
                      name={`bedTypesCount.${bedTypeData.id}`}
                      type="text"
                      component={this.renderCircleButtonCounter}
                      labelSingular={bedTypeData.itemName + '_beds'}
                      labelPlural={bedTypeData.itemName + '_beds'}
                      minValue={0}
                      incrementBy={1}
                      maxValue={beds.endValue}
                    />
                  </Col>
                ))
              }
              </Row>
            </Form.Group>

            <div className='mb-5'>
              <div className={cx(s.landingLabel, 'textWhite')}>
                {formatMessage(messages.howManyBathrooms)}
              </div>
              <div className="bathroom-inputs-wrapper">
                <Form.Group className={cx(s.roomDetailsFormGroup, 'd-flex justify-content-between mb-3')}>
                  <Form.Label>{formatMessage(messages.privateBathroomsCount)}</Form.Label>
                  <Field
                    name="privateBathrooms"
                    type="text"
                    component={this.renderCircleButtonCounter}
                    labelSingular={privateBathrooms.itemName}
                    labelPlural={privateBathrooms.otherItemName}
                    minValue={0}
                    maxValue={(roomTypeSelected === '113') ? 0 : privateBathrooms.endValue}
                    incrementBy={0.5}
                  />
                </Form.Group>
                <Form.Group className={cx(s.roomDetailsFormGroup, 'd-flex justify-content-between')}>
                  <Form.Label>{formatMessage(messages.sharedBathroomsCount)}</Form.Label>
                  <Field
                    name="sharedBathrooms"
                    type="text"
                    component={this.renderCircleButtonCounter}
                    labelSingular={sharedBathrooms.itemName}
                    labelPlural={sharedBathrooms.otherItemName}
                    maxValue={sharedBathrooms.endValue}
                    minValue={0}
                    incrementBy={0.5}
                  />
                </Form.Group>
              </div>
            </div>
          </div>

          <FooterButton
            isDisabled={nextButtonIsDisabled}
            nextPage={nextPage}
            previousPage={previousPage}
            nextPagePath={path}
            previousPagePath={"room"}
            formPage={formPage}
            step={step}
          />
        </form>
      </div>
    )
  }
}

RoomDetailsPage = reduxForm({
  form: 'ListPlaceStep1',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: update
})(RoomDetailsPage);

const selector = formValueSelector('ListPlaceStep1');
const mapState = (state) => ({
  isExistingList: state.location.isExistingList,
  listingFields: state.listingFields.data,
  beds: selector(state, 'beds'),
  bedCount: selector(state, 'beds'),
  roomTypeSelected: selector(state, 'roomType'),
  formValues: getFormValues("ListPlaceStep1")(state),
});

export default injectIntl(withStyles(s, bt)(connect(mapState)(RoomDetailsPage)));