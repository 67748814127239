import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { graphql, gql, compose } from 'react-apollo';

import {
  Row,
  Col,
  Card 
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ProfilePhoto.css';
import bt from '../../components/commonStyle.css';
import { FaTrash } from "react-icons/fa";

// Redux
import { connect } from 'react-redux';
import { doRemoveProfilePicture } from '../../actions/manageUserProfilePicture';

// Component
import DropZone from './DropZone';
import Avatar from '../Avatar';
import Loader from '../Loader';

// Locale
import messages from '../../locale/messages';

class ProfilePhotoUploadForm extends React.Component {

  static propTypes = {
    profilePhotoLoading: PropTypes.bool,
    formatMessage: PropTypes.any,
    doRemoveProfilePicture: PropTypes.any.isRequired,
    profilePictureData: PropTypes.shape({
      loading: PropTypes.bool,
      userAccount: PropTypes.shape({
        picture: PropTypes.string
      })
    })
  };

  static defaultProps = {
    profilePictureData: {
      loading: true
    },
    profilePhotoLoading: false
  };

  render() {

    const title = (
      <span><FormattedMessage {...messages.profilePhoto} /></span>
    );
    const { profilePictureData: { loading, userAccount }, doRemoveProfilePicture } = this.props;
    const { profilePhotoLoading } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={'commonListingBg'}>
        <Card className={cx(s.panelHeader, 'bgBlack')}>
          <Card.Body>
            <div>
              <h3 className={bt.listingTitleText}>{title}</h3>
            </div>
            <Row>
              <Col xs={12} sm={6} md={4} lg={4} className={s.textAlignCenter}>
                <Loader
                  show={profilePhotoLoading}
                  type={"page"}
                >
                  <div className={cx(s.picContainer, 'bgBlack')}>
                    <div className={cx(s.profilePic, s.picRound)}>
                      <Avatar
                        isUser
                        height={200}
                        width={200}
                        className={s.profileAvatar}
                      />
                    </div>
                    {
                      !loading && userAccount.picture != null && <a href="#" onClick={() => doRemoveProfilePicture(userAccount.picture)}>
                        <FaTrash className={s.trashIcon} />
                      </a>
                    }
                  </div>
                </Loader>
              </Col>
              <Col xs={12} sm={6} md={8} lg={8}>
                <p className={cx('d-block d-sm-none d-lg-block')}>&nbsp;</p>
                <p className={s.textMuted}>
                  <FormattedMessage {...messages.profilePhotoInfo} />
                </p>
                <div className={s.centerFlex}>
                  <Col xs={12} sm={4} md={4} lg={4} className={cx(s.spaceTop2, s.fullWidth, s.button, bt.btnPrimaryBorder, s.noPadding)}>
                    <DropZone data={userAccount}
                      defaultMessage={formatMessage(messages.dropzoneUpload)}
                    />
                  </Col>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapState = (state) => ({
  profilePhotoLoading: state.account.profilePhotoLoading
});

const mapDispatch = {
  doRemoveProfilePicture
};

export default compose(
  injectIntl,
  withStyles(s, bt),
  connect(mapState, mapDispatch),
  graphql(gql`
      query {
          userAccount {
              picture
          }
      }
    `, {
    name: 'profilePictureData',
    options: {
      ssr: false
    }
  }),
)(ProfilePhotoUploadForm);