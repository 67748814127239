import React from 'react';
import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './AVSectionItem.css';

class AVSectionItem extends React.Component {
	static propTypes = {
	};

	static defaultProps = {
	};

	render() {
		const { section, onSectionClick } = this.props;

		return (
			<div onClick={() => onSectionClick(section.id)}>
				<img src={'/images/sections/' + section.image} width={100} />
				<h5>{section.name}</h5>
			</div>
		)
	}
}

export default compose(withStyles(s))(AVSectionItem);