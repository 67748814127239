exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RoomsBeds-roomsBedGap-2MFiJ {\n    gap: 11px;\n}\n\n.RoomsBeds-bedIcon-26oVz {\n    width: 100%;\n    max-width: 22px;\n    min-width: 22px;\n}\n\n.RoomsBeds-bedsWidth-17uDf {\n    width: 70%;\n}\n\n@media screen and (max-width: 767px) {\n    .RoomsBeds-roomsBedGapSection-3eiwN {\n        -webkit-box-orient: vertical;\n        -webkit-box-direction: normal;\n            -ms-flex-direction: column;\n                flex-direction: column;\n        -webkit-box-align: start;\n            -ms-flex-align: start;\n                align-items: flex-start;\n        gap: 10px;\n    }\n\n    .RoomsBeds-bedsWidth-17uDf {\n        width: 100%;\n    }\n}", ""]);

// exports
exports.locals = {
	"roomsBedGap": "RoomsBeds-roomsBedGap-2MFiJ",
	"bedIcon": "RoomsBeds-bedIcon-26oVz",
	"bedsWidth": "RoomsBeds-bedsWidth-17uDf",
	"roomsBedGapSection": "RoomsBeds-roomsBedGapSection-3eiwN"
};