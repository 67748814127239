import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// Components
import ManageLanguagesModal from '../ManageLanguagesModal/ManageLanguagesModal';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';

// Style
import s from './LanguageManagement.css';
import bt from '../../commonStyle.css';
import { openAddLanguageModal, openEditLanguageModal } from '../../../actions/siteadmin/modalActions';

class LanguageManagement extends React.Component {

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      langCode: PropTypes.string.isRequired,
      langName: PropTypes.string.isRequired,
      isEnabled: PropTypes.bool.isRequired,
    })),
  };

  static defaultProps = {
    data: []
  };

  render() {
    const { data, openAddLanguageModal, openEditLanguageModal } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <div>
          <h1 className={s.headerTitle}><FormattedMessage {...messages.manageLanguages} /></h1>
          <ManageLanguagesModal />
          <div className={s.space4}>
            <Button
              className={cx(bt.btnPrimary, bt.btnLarge, 'mb-3')}
              onClick={() => openAddLanguageModal()}
            >
              <FormattedMessage {...messages.addNewLabel} />
            </Button>
          </div>
          <div className={cx('table-responsive', 'tableBorderRadiusAdmin', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{formatMessage(messages.idLabel)}</th>
                  <th scope="col">{formatMessage(messages.langCodeLabel)}</th>
                  <th scope="col">{formatMessage(messages.langNameLabel)}</th>
                  <th scope="col">{formatMessage(messages.status)}</th>
                  <th scope="col">{formatMessage(messages.operationLabel)}</th>
                </tr>
              </thead>
              <tbody>
                {
                  data && data.length > 0 ?
                  data.map((value, key) => {
                    let status = value.isEnabled == 1 ? formatMessage(messages.enabledLabel) : formatMessage(messages.disabledLabel);
                    return (
                      <tr key={key}>
                        <td>{value.id}</td>
                        <td>{value.langCode}</td>
                        <td>{value.langName}</td>
                        <td>{status}</td>
                        <td>
                          <Button 
                            className={cx(bt.btnPrimaryBorder, 'textCenterAdmin', 'bgBlack')} 
                            onClick={() => openEditLanguageModal(value)}
                          >
                            <FormattedMessage {...messages.manageLabel} />
                          </Button>
                        </td>
                      </tr>
                    )
                  })
                  : (
                    <tr><td colSpan="6">{formatMessage(messages.noRecordFound)}</td></tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

}

const mapState = (state) => ({});

const mapDispatch = {
  openAddLanguageModal,
  openEditLanguageModal,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(LanguageManagement)));