import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { graphql, compose } from 'react-apollo';
import { change, getFormValues } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import Scroll from 'react-scroll';
import AutoAffix from 'react-overlays/lib/AutoAffix';
import { FaBolt } from "react-icons/fa";
import {
  Button,
  Row,
  Col,
} from 'react-bootstrap';

// Components
import Photos from '../../components/ViewListing/Photos';
import ListingIntro from '../../components/ViewListing/ListingIntro';
import Calendar from '../../components/ViewListing/Calendar';
import ListingDetails from '../../components/ViewListing/ListingDetails';
import Reviews from '../../components/ViewListing/Reviews';
import HostDetail from '../../components/ViewListing/HostDetail';
import LocationMap from '../../components/ViewListing/LocationMap';
import Loader from '../../components/Loader';
import NotFound from '../notFound/NotFound';
import AvailabilityCalendar from '../../components/ViewListing/AvailabilityCalendar';
import StarRating from '../../components/StarRating';
import CurrencyConverter from '../../components/CurrencyConverter';
import BookingModal from '../../components/ViewListing/BookingModal';
import SimilarListings from '../../components/ViewListing/SimilarListings';
import HostDetails from '../../components/ViewListing/HostDetails';

import { openBookingModal } from '../../actions/BookingModal/modalActions';
import { getSpecialPricingData } from '../../actions/Listing/getSpecialPricingData';
import { checkAvailability } from '../../actions/checkAvailability';
import { getAvailableMonthsForMonthlyRental } from '../../actions/getMonthsForRental';

import BlockedDatesQuery from './BlockedDates.graphql';
import ListingDataQuery from './getListingData.graphql';
import MoreReviewsQuery from './MoreReviews.graphql';
import SimilarListsQuery from './getSimilarListing.graphql';

import messages from '../../locale/messages';

import s from './ViewListing.css';
import bt from '../../components/commonStyle.css';

// Or Access Link,Element,etc as follows
let Link = Scroll.Link;
let Element = Scroll.Element;
let Events = Scroll.Events;
let scroll = Scroll.animateScroll;
let scrollSpy = Scroll.scrollSpy;
var durationFn = function (deltaTop) {
  return deltaTop;
};

class ViewListing extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    listId: PropTypes.number.isRequired,
    preview: PropTypes.bool,
    isAdmin: PropTypes.bool,
    ListingBlockedDates: PropTypes.shape({
      loading: PropTypes.bool,
      UserListing: PropTypes.shape({
        blockedDates: PropTypes.array
      })
    }),
    getListingData: PropTypes.shape({
      loading: PropTypes.bool,
      UserListing: PropTypes.object
    }),
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    similarListsData: PropTypes.shape({
      loading: PropTypes.bool,
      getSimilarListing: PropTypes.array
    }),
    openBookingModal: PropTypes.func,
  };
  static defaultProps = {
    getListingData: {
      loading: false,
      UserListing: {
        userId: null
      }
    },
    ListingBlockedDates: {
      loading: true,
      UserListing: {
        blockedDates: []
      }
    },
    isAdmin: false
  }

  constructor(props) {
    super(props);
    this.state = {
      smallDevice: true,
    };
  }

  componentDidMount() {
    let isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
    }
  }

  componentWillUnmount() {
    let isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      window.removeEventListener('resize', this.handleResize);
    }
  }

  handleResize = (e) => {
    const { smallDevice } = this.state;

    let smallDeviceValue = smallDevice;
    if (typeof window !== 'undefined') { // is browser
      smallDeviceValue = window.matchMedia('(max-width: 920px)').matches;

      if (smallDevice !== smallDeviceValue) {
        this.setState({ smallDevice: smallDeviceValue });
      }
    }
  }

  async onCalendarDatesChange({ type, startDate, endDate, maxNight, minNight }) {
    const { listId, change, getAvailableMonthsForMonthlyRental, bookingFormValues } = this.props;

    if ('range' === type) {

      let startDateFormatted = moment(startDate).format('YYYY-MM-DD');
      let endDateFormatted = null;
      if (endDate) {
        endDateFormatted = moment(endDate).format('YYYY-MM-DD');
      }

      if ((bookingFormValues.startDate !== startDateFormatted) || (bookingFormValues.endDate !== endDateFormatted)) {
        await change('BookingForm', 'startDate', startDateFormatted);
        await change('BookingForm', 'endDate', endDateFormatted);

        if (startDateFormatted) {
          await getAvailableMonthsForMonthlyRental(listId, startDateFormatted);
          if (endDateFormatted) {
            await this.props.getSpecialPricingData(listId, startDateFormatted, endDateFormatted);
            await this.props.checkAvailability(listId, startDateFormatted, endDateFormatted, maxNight, minNight);
          }
        }
      }
    }
    else if ('single' === type) {

      let startDateFormatted = moment(startDate).format('YYYY-MM-DD');
      if (bookingFormValues.startDate !== startDateFormatted) {
        await change('BookingForm', 'startDate', startDateFormatted);
        if (startDateFormatted) {
          await getAvailableMonthsForMonthlyRental(listId, startDateFormatted);
        }
      }

      if (bookingFormValues.endDate !== null) {
        await change('BookingForm', 'endDate', null);
      }
    }
  }

  render() {
    const {
      listId, title, preview, bookingFormValues, ListingBlockedDates, listReviewsData, openBookingModal,
      baseCurrency, isAdmin, loggedinUserId, similarListsData, getListingData: { loading, UserListing },
    } = this.props;
    const { smallDevice } = this.state;

    // console.log('ViewListing data', UserListing);

    if (loading && !UserListing) {
      return (
        <div className={s.loaderMobileView}>
          <Loader type="text" />
        </div>
      )
    }

    let isHost = false;
    if (isAdmin || (loggedinUserId && (loggedinUserId === UserListing.userId))) {
      isHost = true;
    }

    if ((preview && !isHost) || (!loading && !UserListing)) {
      return <NotFound title={title} />
    }

    let reviewsCount = UserListing.reviewsCount;
    let starRatingValue = 0;
    if (reviewsCount > 0 && UserListing.reviewsStarRating > 0) {
      starRatingValue = Math.round(UserListing.reviewsStarRating / reviewsCount)
    }

    let blockedDates = [];
    let loadingBlockedDates = ListingBlockedDates.loading;
    if (!loadingBlockedDates && ListingBlockedDates.UserListing?.blockedDates) {
      blockedDates = ListingBlockedDates.UserListing.blockedDates;
    }

    return (
      <div className={s.root}>
        <div className={s.container}>
          {
            UserListing &&
            <div className={s.pageContainer}>
              <div className={s.pageContent}>
                <HostDetails data={UserListing}
                  reviewsCount={UserListing.reviewsCount}
                  reviewsStarRating={UserListing.reviewsStarRating}
                  loading={loading}
                />
              </div>
              <div className={(s.pageContent)}>
                <Photos data={UserListing} loading={loading} />
              </div>
              <div className={cx(s.horizontalLineThrough)}>
                <Row className={cx(s.pageContent, s.gridTop, s.safariTop)}>
                  <Col xs={12} sm={12} md={8} lg={8} >
                    <Row>
                      <div className={s.stickyContainer}>
                        <AutoAffix viewportOffsetTop={0} container={this} affixClassName={s.showAffix}>
                          <div className={cx(s.zPanel, s.panelDefault, 'bgBlack')}>
                            <div className={cx("stickHeader", s.viewHeader)}>
                              <div className={s.headerNav}>
                                <div className={s.textColor}>
                                  <ul className={cx('list-inline d-flex', s.stickyMenu)}>
                                    <li>
                                      <Link className={cx(s.textList)} activeClass={cx(s.active, 'textWhite')} to="test1" spy={true} smooth={true} offset={-50} duration={800} onSetActive={this.handleSetActive}>
                                        <FormattedMessage {...messages.overview} />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link className={cx(s.textList)} activeClass={cx(s.active, 'textWhite')} to="test2" spy={true} smooth={true} offset={-80} duration={800} onSetActive={this.handleSetActive}>
                                        <FormattedMessage {...messages.reviews} />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link className={cx(s.textList)} activeClass={cx(s.active, 'textWhite')} to="test3" spy={true} smooth={true} offset={-70} duration={800} onSetActive={this.handleSetActive}>
                                        <FormattedMessage {...messages.theHost} />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link className={cx(s.textList)} activeClass={cx(s.active, 'textWhite')} to="test4" spy={true} smooth={true} offset={-70} duration={800} onSetActive={this.handleSetActive}>
                                        <FormattedMessage {...messages.location} />
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AutoAffix>
                      </div>
                      <Element name="test1" className="element">
                        <div className={(s.listingIntroSection)}>
                          <ListingIntro data={UserListing}
                            reviewsCount={UserListing.reviewsCount}
                            reviewsStarRating={UserListing.reviewsStarRating} />
                        </div>
                        <ListingDetails
                          data={UserListing}
                          isHost={isHost}
                        />
                      </Element>
                      {
                        !loadingBlockedDates &&
                        <div className={'availabilityMobile'}>
                          <AvailabilityCalendar
                            listId={listId}
                            blockedDates={blockedDates}
                            listingData={UserListing.listingData}
                            country={UserListing.country}
                            calendarMonthsToDisplay={(smallDevice) ? 1 : 2}
                            bookingEditModal={false}

                            onCalendarDatesChange={(values) => this.onCalendarDatesChange(values)}
                            startDate={bookingFormValues.startDate}
                            endDate={bookingFormValues.endDate}
                            monthlyRentalToggled={UserListing.listingData.isMonthlyRentalOnly || bookingFormValues.showMonthlyRental}
                          />
                        </div>
                      }
                      <Element name="test2" className="element">

                        <Reviews
                          reviewsCount={UserListing.reviewsCount}
                          reviewsStarRating={UserListing.reviewsStarRating}
                          data={listReviewsData}
                          listId={listId}
                        />
                      </Element>
                      <Element name="test4" className="element">
                        <LocationMap data={UserListing} />
                      </Element>
                      <Element name="test3" className="element">
                        <HostDetail
                          id={UserListing.id}
                          userId={UserListing.userId}
                          hostEmail={UserListing.user.email}
                          personCapacity={UserListing.personCapacity}
                          city={UserListing.city}
                          listingData={UserListing.listingData || undefined}
                          profile={UserListing.user.profile || undefined}
                          blockedDates={blockedDates}
                          isHost={isHost}
                          country={UserListing.country}
                        // urlParameters={{ listTitle: UserListing.title, startDate, endDate, guests }}
                        />
                      </Element>
                    </Row>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} className={s.positionSticky} style={{ height: 'max-content' }}>
                    {
                      !smallDevice &&
                      <div className={cx(s.responsiveNopadding)}>
                        <Calendar
                          UserListing={UserListing}
                          isHost={isHost}
                          loading={loadingBlockedDates}
                          blockedDates={blockedDates}
                        />
                      </div>
                    }
                  </Col>
                </Row>
                {
                  (similarListsData.getSimilarListing?.length > 0) &&
                  <div className={cx(s.space2, s.noPaddingMobile, s.pageContent, s.similarPadding)}>
                    <div className={cx(s.sliderMargin, 'similarListing', s.similarPadding)}>
                      <div className={cx(s.similarPaddingText)}>
                        <h2 className={cx(s.sectionTitleText)}><FormattedMessage {...messages.similarListings} /></h2>
                      </div>
                      <SimilarListings
                        data={similarListsData.getSimilarListing}
                        hideHeading={true}
                        viewListingSimilarItem={'viewListingSimilarItem'}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          }
          {
            smallDevice &&
            <div className={cx(s.stickyBookButton)}>
              <div className={cx(s.filtersFooter)}>
                <div className={cx(s.filtersContainer, 'bgBlackTwo')}>
                  <Row className={s.footerContainer}>
                    <div className={cx("d-flex justify-content-between")}>
                      <div className={cx(s.smSpace)}>
                        <div className={cx(s.bookItPriceAmount, 'd-flex')}>
                          <div className={s.bookItPrice}>
                            {
                              !UserListing.listingData.isMonthlyRentalOnly &&
                              <>
                                <CurrencyConverter
                                  from={UserListing.listingData.currency || baseCurrency}
                                  amount={UserListing.listingData.basePrice}
                                  className={s.bookItPrice}
                                />
                                <span className={s.bookItNight}>{' '}/ <FormattedMessage {...messages.perNight} /></span>
                              </>
                            }

                            {
                              UserListing.bookingType === "instant" &&
                              <span>
                                <FaBolt className={s.instantIcon} />
                              </span>
                            }

                            {
                              (UserListing.listingData.monthlyRentalPrice > 0) &&
                              <>
                                <CurrencyConverter
                                  from={UserListing.listingData.currency || baseCurrency}
                                  amount={UserListing.listingData.monthlyRentalPrice}
                                  className={s.bookItPrice}
                                />
                                <span className={s.bookItNight}>{' '}/ <FormattedMessage {...messages.perMonth} /></span>
                              </>
                            }
                          </div>
                        </div>
                        {
                          (starRatingValue > 0) &&
                          <div>
                            <div className={cx(s.reviewSection, 'reviewSectionRTL')}>
                              <StarRating name={'review'} value={starRatingValue} />
                            </div>
                            <div>
                              <span>{starRatingValue}</span>
                            </div>
                          </div>
                        }
                      </div>
                      <div style={{ width: 'fit-content' }}>
                        <BookingModal
                          UserListing={UserListing}
                          isHost={isHost}
                          loading={loadingBlockedDates}
                          blockedDates={blockedDates}
                        />
                        <Button
                          className={cx(s.btn, bt.btnPrimary, s.fullWidth)}
                          onClick={openBookingModal}
                        >
                          <FormattedMessage {...messages.bookNow} />
                        </Button>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  loggedinUserId: state.account.data?.userId || null,
  isAdmin: state.runtime.isAdminAuthenticated,
  baseCurrency: state.currency.base,
  bookingFormValues: getFormValues('BookingForm')(state),
});

const mapDispatch = {
  openBookingModal,
  change,
  checkAvailability,
  getSpecialPricingData,
  getAvailableMonthsForMonthlyRental,
};

export default compose(
  injectIntl,
  withStyles(s, bt),
  connect(mapState, mapDispatch),
  graphql(ListingDataQuery,
    {
      name: 'getListingData',
      options: (props) => ({
        variables: {
          listId: props.listId,
          preview: props.preview,
        },
        fetchPolicy: 'network-only',
        ssr: true
      })
    }
  ),
  graphql(BlockedDatesQuery,
    {
      name: 'ListingBlockedDates',
      options: (props) => ({
        variables: {
          listId: props.listId,
          preview: props.preview,
        },
        fetchPolicy: 'network-only',
        ssr: false
      })
    }
  ),
  graphql(MoreReviewsQuery,
    {
      name: 'listReviewsData',
      options: (props) => ({
        variables: {
          listId: props.listId,
        },
        fetchPolicy: 'network-only',
        ssr: false
      })
    }
  ),
  graphql(SimilarListsQuery,
    {
      name: 'similarListsData',
      options: (props) => ({
        variables: {
          listId: props.listId,
          lat: props.lat,
          lng: props.lng
        },
        fetchPolicy: 'network-only',
        ssr: false
      })
    }
  )
)(ViewListing);
