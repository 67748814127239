import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { flowRight as compose } from 'lodash';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

// components
import Link from '../Link';

//helpers
import history from '../../core/history';

//styles
import s from './Logo.css';

class Logo extends Component {
    static propTypes = {
        siteName: PropTypes.string.isRequired,
        logoImage: PropTypes.string,
        link: PropTypes.string,
        className: PropTypes.string,
        logoHeight: PropTypes.string,
        logoWidth: PropTypes.string,
        showMenu: PropTypes.bool,
    };

    static defaultProps = {
        siteName: null,
        logoImage: null,
        link: '/',
        logoHeight: '34',
        logoWidth: '34',
        showMenu: false
    }

    render() {
        const { siteName, logoImage, link, className, showMenu} = this.props;
        return (
            <Link to={link} className={className}>
                {
                    logoImage ?
                    <img src={"/images/logo/" + logoImage} className={cx(!showMenu ? 'displayBlock' : 'displayNone')} />
                    : (
                        siteName ?
                        <span className={cx(s.logoColor)}>{siteName}</span>
                        : <span className={cx(s.logoColor)}>Site Name</span>

                    )
                }
            </Link>
        );
    }
}

const mapState = (state) => ({
    siteName: state.siteSettings.data.siteName,
    logoImage: state.siteSettings.data.Logo,
    logoHeight: state.siteSettings.data.logoHeight,
    logoWidth: state.siteSettings.data.logoWidth,
    homeLogo: state.siteSettings.data.homeLogo,
    showMenu: state.toggle.showMenu,
    homeLogoHeight: state.siteSettings.data.homeLogoHeight,
    homeLogoWidth: state.siteSettings.data.homeLogoWidth,
    layoutType: state.siteSettings.data.homePageType
});

const mapDispatch = {};

export default compose(
    connect(mapState, mapDispatch),
    withStyles(s)
)(Logo);
