import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Link from '../../../components/Link';
import Confirm from 'react-confirm-bootstrap';
import {
  Button
} from 'react-bootstrap';
// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './PopularLocationManagement.css';
import bt from '../../../components/commonStyle.css';
import { deletePopularLocation, updateLocationStatus } from '../../../actions/siteadmin/deletePopularLocation';
import history from '../../../core/history';

// Translation
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';

class PopularLocationManagement extends React.Component {

  static propTypes = {
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      location: PropTypes.string,
      locationAddress: PropTypes.string,
      isEnable: PropTypes.string,
      images: PropTypes.string,
    })),
    deletePopularLocation: PropTypes.any,
    updateLocationStatus: PropTypes.any,
  };

  static defaultProps = {
    data: []
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    history.push('/siteadmin/popularlocation/add')
  }

  render() {
    const { data, deletePopularLocation, updateLocationStatus } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <div>
          <h1 className={s.headerTitle}><FormattedMessage {...messages.managePopularLocation} /></h1>
          <div className={s.space4}>
            <Button onClick={this.handleClick} className={cx(bt.btnPrimary, bt.btnLarge)}>
              <FormattedMessage {...messages.addPopularLocation} />
            </Button>
          </div>
          <div className={cx('table-responsive', 'popularlocationlist', 'tableBorderRadiusAdmin', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{formatMessage(messages.idLabel)}</th>
                  <th scope="col">{formatMessage(messages.location)}</th>
                  <th scope="col">{formatMessage(messages.locationAddress)}</th>
                  <th scope="col">{formatMessage(messages.status)}</th>
                  <th scope="col">{formatMessage(messages.setEnableDisable)}</th>
                  <th scope="col">{formatMessage(messages.editLabel)}</th>
                  <th scope="col">{formatMessage(messages.delete)}</th>
                </tr>
              </thead>
              <tbody>
                {
                  data && data.length > 0 ?
                  data.map(function (value, key) {
                    let isStatus;
                    return (
                      <tr key={key}>
                        <td>{value.id}</td>
                        <td>{value.location}</td>
                        <td className={s.imageurl}>{value.locationAddress}</td>
                        <td>
                          {value.isEnable == 'true' ? formatMessage(messages.enabledLabel) : formatMessage(messages.disabledLabel)}
                        </td>
                        <td>
                          <a href="#" onClick={() => updateLocationStatus(value.id, value.isEnable)}>
                            {value.isEnable == 'true' ? formatMessage(messages.disableLabel) : formatMessage(messages.enableLabel)}
                          </a>
                        </td>
                        <td>
                          <Link to={"/siteadmin/edit/popularlocation/" + value.id}>
                            <FormattedMessage {...messages.editLabel} />
                          </Link>
                        </td>
                        <td>
                          <div>
                            <Confirm
                              onConfirm={() => deletePopularLocation(value.id)}
                              body={formatMessage(messages.areYouSureDeleteWishList)}
                              confirmText={formatMessage(messages.confirmDelete)}
                              cancelText={formatMessage(messages.cancel)}
                              title={formatMessage(messages.deletePopularLocationLabel)}
                            >
                              <a href="#"><FormattedMessage {...messages.delete} /></a>
                            </Confirm>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                  : (
                    <tr><td colSpan="7">{formatMessage(messages.noRecordFound)}</td></tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

}

const mapState = (state) => ({
});

const mapDispatch = {
  deletePopularLocation,
  updateLocationStatus
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(PopularLocationManagement)));