import React, { Component } from "react";
import PropTypes from "prop-types";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

// Redux Form
import { Field, formValueSelector, change, submit } from "redux-form";
import { connect } from "react-redux";

import IncrementBtnCircle from "../../../IncrementBtnCircle/IncrementBtnCircle";

import messages from "../../../../locale/messages";

import s from "./Guests.css";

class Guests extends Component {
  static propTypes = {
    className: PropTypes.any,
    handleTabToggle: PropTypes.any,
    isExpand: PropTypes.bool,
  };

  static defaultProps = {
    isExpand: false,
    fieldsSettingsData: {
      personCapacity: [],
    },
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleSubmit = async () => {
    const { className, handleTabToggle, isExpand } = this.props;
    const { change, submit } = this.props;
    await change('SearchForm', "currentPage", 1);
    submit("SearchForm");
    handleTabToggle("guests", !isExpand);
  };

  handleReset = () => {
    const {
      fieldsSettingsData: { personCapacity },
    } = this.props;
    const { change } = this.props;
    if (personCapacity?.length > 0 && personCapacity[0]?.startValue)
      change('SearchForm', "personCapacity", personCapacity[0]?.startValue);
    else change('SearchForm', "personCapacity", null);
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  setBtnWrapperRef = (node) => {
    this.btnWrapperRef = node;
  };

  handleClickOutside = (event) => {
    const { className, handleTabToggle, isExpand } = this.props;
    const { change, submit } = this.props;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      change('SearchForm', "currentPage", 1);
      submit("SearchForm");
      if (this.btnWrapperRef && !this.btnWrapperRef.contains(event.target)) {
        handleTabToggle("guests", !isExpand);
      }
    }
  };

  renderIncrementButton = (field) => <IncrementBtnCircle {...field} />;

  render() {
    const { fieldsSettingsData: { personCapacity }, guests } = this.props;
    const { formatMessage } = this.props.intl;

    let buttonLabel = formatMessage(messages.guest);

    if (guests && Number(guests) > 0 && personCapacity?.length > 0) {
      buttonLabel = guests + " ";
      buttonLabel =
        buttonLabel +
        (Number(guests) > 1
          ? personCapacity[0]?.otherItemName
          : personCapacity[0]?.itemName);
    }

    return (
      <div>
        {
          <div style={{minWidth: '160px'}}>
            <div
              className={cx(
                s.displayTableCell,
                s.captionTitle,
                s.fullWidth,
                'text-nowrap'
              )}
            >
              <FormattedMessage {...messages.header_search_form_guests_label} />
            </div>
            <div
              className={cx(
                s.displayTableCell,
                s.fullWidth,
                "paymentGuestFilter"
              )}
            >
              <Field
                name="personCapacity"
                type="text"
                component={this.renderIncrementButton}
                maxValue={personCapacity[0]?.endValue}
                minValue={personCapacity[0]?.startValue}
                incrementBy={1}
              />
            </div>
          </div>
        }
      </div>
    );
  }
}

const selector = formValueSelector("SearchForm");
const mapState = (state) => ({
  fieldsSettingsData: state?.listingFields?.data,
  guests: selector(state, "personCapacity"),
});

const mapDispatch = {
  change,
  submit,
};

export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(Guests))
);
