import {
  BOOKING_PAYMENT_START,
  BOOKING_PAYMENT_SUCCESS,
  BOOKING_PAYMENT_ERROR,
} from '../../constants';

import { sendPayment } from '../../core/payment/sendPayment';
import { convert } from '../../helpers/currencyConvertion';
import { processStripePayment } from '../../core/payment/stripe/processStripePayment';
import showToaster from '../../helpers/showToaster';

import createReservationQuery from './createReservation.graphql';

export function makePayment(
  listId,
  listTitle,
  reservationType,
  checkIn,
  checkOut,
  checkoutMonth,
  guests,
  message,
  bookingType,
  paymentCurrency,
  paymentType,
  guestEmail,
  paymentMethodId,
) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: BOOKING_PAYMENT_START,
      payload: {
        paymentLoading: true
      }
    });

    try {

      let bookingTypeData = bookingType;
      if (getState().book.bookDetails.preApprove) {
        bookingTypeData = 'instant';
      }

      const { data } = await client.mutate({
        mutation: createReservationQuery,
        variables: {
          listId,
          reservationType,
          checkIn,
          checkOut,
          checkoutMonth,
          guests,
          message,
          bookingType: bookingTypeData,
          paymentType,
        },
      });

      // return {
      //   status: 'testing status',
      //   paymentIntentSecret: 'testing paymentIntentSecret',
      //   reservationId: 'testing reservationId'
      // }

      if (data?.createReservation) {
        let reservationData = data.createReservation;
        console.log('reservationData', reservationData);

        let rates = getState().currency.rates;
        let baseCurrency = getState().currency.base;
        let convertedAmount = 0;
        if (paymentType == 1) {

          let isAmount = reservationData.total;
          
          let overAllAmount = isAmount.toString().split(".");
          if (overAllAmount && overAllAmount[1] == "00") {
            isAmount = overAllAmount && overAllAmount[0];
          }
          convertedAmount = convert(baseCurrency, rates, isAmount, reservationData.currency, paymentCurrency);
          const { status, errorMessage } = await sendPayment(reservationData.id, convertedAmount.toFixed(2), paymentCurrency, listTitle);

          if (status === 200) {
            await dispatch({
              type: BOOKING_PAYMENT_SUCCESS,
              payload: { paymentLoading: true }
            });
          }
          else {
            if (status === 422) {
              showToaster({ messageId: 'paypalError', toasterType: 'error' });
            }
            else if (errorMessage) {
              showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: errorMessage });
            }

            await dispatch({
              type: BOOKING_PAYMENT_ERROR,
              payload: { paymentLoading: false }
            });
          }
        }
        else {
          let currentCurrency = getState().currency.to ?? getState().currency.base;
          convertedAmount = convert(baseCurrency, rates, reservationData.total, reservationData.currency, currentCurrency)
          let cardDetails = {};
          let reservationDetails = {
            reservationId: reservationData.id,
            listId,
            hostId: reservationData.hostId,
            guestId: reservationData.guestId,
            guestEmail,
            title: listTitle,
            amount: convertedAmount.toFixed(2),
            currency: currentCurrency
          };
          
          console.log('before processStripePayment type: reservation', { cardDetails, reservationDetails, paymentMethodId });
          const { status, errorMessage, paymentIntentSecret } = await processStripePayment('reservation',
            cardDetails,
            reservationDetails,
            paymentMethodId
          );
          console.log('after processStripePayment type: reservation', { status, errorMessage, paymentIntentSecret });

          if (status === 200) {
            await dispatch({
              type: BOOKING_PAYMENT_SUCCESS,
              payload: { paymentLoading: true }
            });

            return {
              status
            }

          }
          else {
            if (errorMessage) {
              showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: errorMessage });
            }
            await dispatch({
              type: BOOKING_PAYMENT_ERROR,
              payload: { paymentLoading: false }
            });

            return {
              status,
              paymentIntentSecret,
              reservationId: reservationData.id,
              total: reservationData.total,
              currency: reservationData.currency,
            }
          }
        }
      }
    } catch (error) {
      dispatch({
        type: BOOKING_PAYMENT_ERROR,
        payload: {
          error,
          paymentLoading: false
        }
      });
      return false;
    }

    return true;
  };
}

