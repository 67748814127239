import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
  Form,
} from 'react-bootstrap';

// Component
import updateStep3 from './updateStep3';
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';

import messages from '../../locale/messages';
import validateStep3 from './validateStep3';

//Image
import toolTipIcon from '../../../public/SiteIcons/listCommonToolTip.svg';

import bt from '../../components/commonStyle.css';
import s from './ListPlaceStep1.css';
class PricingPage extends Component {

  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
    };
  }

  UNSAFE_componentWillMount() {
    const { valid } = this.props;
    this.setState({ isDisabled: valid ? false : true })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { valid } = nextProps;
    this.setState({ isDisabled: valid ? false : true })
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <Form.Control
          {...input}
          placeholder={label}
          type={type}
          className={className}
          maxLength={12}
        />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    )
  }

  renderFormControlTax = ({ input, label, type, meta: { touched, error }, className, prefixLabel }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <InputGroup>
          <Form.Control {...input} placeholder={label} type={type} className={className} maxLength={12} />
          <InputGroup.Addon className={s.prefixIcon}>{prefixLabel}</InputGroup.Addon>
        </InputGroup>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    )
  }

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <Form.Select {...input} className={className} >
          {children}
        </Form.Select>
      </div>
    )
  }

  render() {
    const { handleSubmit, nextPage, previousPage, formPage, step, availableCurrencies } = this.props;
    const { isDisabled } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <div className={s.grid}>
        <SidePanel
          title={formatMessage(messages.stepThreeCommonHeading)}
          landingContent={formatMessage(messages.tabPricingPage)}
        />
        <form onSubmit={handleSubmit}>
          <div className={cx(s.landingMainContent, 'disCountAddon')}>
            <Form.Group className={s.formGroup}>
              <Form.Label className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.currency} />
              </Form.Label>

              <Field name="currency" component={this.renderFormControlSelect} className={cx(s.formControlSelect, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')} >
                {
                  availableCurrencies?.length > 0 && availableCurrencies.map((currency, key) => {
                    if (currency.isEnable === true) {
                      return <option key={key} value={currency.symbol}>{currency.symbol}</option>
                    }
                  })
                }
              </Field>
            </Form.Group>
            <Form.Group className={cx(s.formGroup, s.space4, s.spaceTop4)}>
              <Form.Label className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.basePrice} />
              </Form.Label>
              <Field
                name="basePrice"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.basePriceLabel)}
                className={cx(s.formControlInput, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
              />
            </Form.Group>
            <div className={s.tipCommonCss}>
              <img src={toolTipIcon} />
              <span className={cx(s.commonTipCsss, 'textWhite')}><FormattedMessage {...messages.basePriceTip} /></span>
            </div>
            <Form.Group className={cx(s.formGroup, s.space4)}>
              <Form.Label className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.cleaningPrice} />
              </Form.Label>
              <Field
                name="cleaningPrice"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.cleaningPrice)}
                className={cx(s.formControlInput, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
              />
            </Form.Group>
          </div>
          <FooterButton
            nextPage={nextPage}
            previousPage={previousPage}
            nextPagePath={"discount"}
            previousPagePath={"advance-notice"}
            formPage={formPage}
            step={step}
            isDisabled={isDisabled}
          />
        </form>
      </div>
    );
  }
}

PricingPage = reduxForm({
  form: 'ListPlaceStep3', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep3,
  onSubmit: updateStep3
  // onSubmit: updateStep4

})(PricingPage);

const mapState = (state) => ({
  listingFields: state.listingFields.data,
  availableCurrencies: state.currency.availableCurrencies,
  base: state.currency.base,
  mapUpdateLoading: state.location.mapUpdateLoading
});

const mapDispatch = {};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(PricingPage)));