import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Button
} from 'react-bootstrap';
import { FaGlobe, FaWindowClose } from "react-icons/fa";
import Footer from '../Footer/Footer';
import ReactDrawer from 'react-drawer';

import s from '!isomorphic-style-loader/!css-loader!react-drawer/lib/react-drawer.css';
import S from './toggleableFooter.css';

// Locale
import messages from '../../locale/messages';

class toggleableFooter extends Component {

  constructor() {
    super();
    this.state = {
      open: false,
      position: 'bottom',
      noOverlay: true
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.setNoOverlay = this.setNoOverlay.bind(this);
  }

  setNoOverlay(e) {
    this.setState({ noOverlay: e.target.checked });
  }
  toggleDrawer() {
    this.setState({ open: !this.state.open });
  }
  closeDrawer() {
    this.setState({ open: false });
  }
  onDrawerClose() {
    this.setState({ open: false });
  }
  render() {
    return (
      <div className={'footerBannerToggleSection'}>
        <Button
          onClick={this.toggleDrawer}
          disabled={this.state.open && !this.state.noOverlay}
          className={cx(
            S.buttonStyle,
            S.buttonPosition,
            S.displayFlex,
            S.toggleBtnGap,
            S.buttonPositionMobile,
            'buttonPositionRTL bgBlack bgBlackHover toggleButtonColor',
          )}
        >
          {
            !this.state.open ?
              <span className={S.displayFlex}>
                <span><FaGlobe className={cx(S.iconStyle, 'textWhite', 'iconStyleRTL')} /> </span>
                <span className="d-none d-md-block"><FormattedMessage {...messages.footerTerms} /></span>
              </span>
              :
              <span className={S.displayFlex}>
                <span><FaWindowClose className={cx(S.iconStyle, 'textWhite', 'iconStyleRTL')} /></span>
                <span className="d-none d-md-block"><FormattedMessage {...messages.footerClose} /></span>
              </span>
          }
        </Button>
        <ReactDrawer
          open={this.state.open}
          position={this.state.position}
          onClose={this.onDrawerClose}
          noOverlay={this.state.noOverlay}>
          <Footer />
        </ReactDrawer>
      </div>
    );
  }
}
export default injectIntl(withStyles(s, S)(toggleableFooter));