import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { connect } from 'react-redux';
import { formValueSelector, getFormValues } from 'redux-form';
import {
  Form,
} from 'react-bootstrap';

// Component
import Loader from '../../Loader';
import { storeBookingDetails } from '../../../actions/booking/storeBookingDetails';

// Locale
import messages from '../../../locale/messages';

// Style
import s from './Calendar.css';
import bt from '../../../components/commonStyle.css';

class BookingButton extends Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { listingId, listingTitle, storeBookingDetails, bookingFormValues } = this.props;

    storeBookingDetails({
      listingId,
      listingTitle,
      showMonthlyRental: bookingFormValues.showMonthlyRental ?? false,
      startDate: bookingFormValues.startDate,
      endDate: bookingFormValues.endDate,
      guests: bookingFormValues.guests ? Number(bookingFormValues.guests) : 1,
      monthlyRentalCheckoutMonth: bookingFormValues.monthlyRentalCheckoutMonth,
    });
  }

  render() {
    const { bookingLoading, disabled, buttonLabel } = this.props;

    return (
      <Form.Group className={s.formGroup}>
        <Loader
          type={"button"}
          className={cx(s.btn, s.btnBlock, bt.btnLarge, bt.btnPrimary, 'arButtonLoader')}
          handleClick={this.handleClick}
          disabled={disabled}
          show={bookingLoading}
          label={buttonLabel}
        />
      </Form.Group>
    );
  }
}

const selector = formValueSelector('BookingForm');
const mapState = state => ({
  bookingLoading: state.book.bookingLoading,
	bookingFormValues: getFormValues('BookingForm')(state),
});

const mapDispatch = {
  storeBookingDetails,
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(BookingButton)));
