import React from 'react';
import PropTypes from 'prop-types';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, injectIntl } from 'react-intl';

import CurrencyConverter from '../../CurrencyConverter';
import Link from '../../Link';

import messages from '../../../locale/messages';
import { isValidJSON } from '../../../helpers/isValidJSON';

import bt from '../../../components/commonStyle.css';
import s from './ShowFailedPayout.css';
class ShowFailedPayout extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,

    }

    constructor(props) {
        super(props);
    }

    render() {
        const { title, data } = this.props;
        const { formatMessage } = this.props.intl;
        const gobackcss = { padding: '10px' };

        let payoutAmount = data?.total - data?.hostServiceFee, checkJSON;
        let reason = data?.hostFailedTransaction && data?.hostFailedTransaction?.reason;

        checkJSON = isValidJSON(reason);
        reason = checkJSON ? JSON.parse(reason) : reason;

        return (
            <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
                <div>
                    <h1 className={s.headerTitle}>{title}</h1>
                    <div className={cx(s.space4, bt.textAlignRight)}>
                        <Link to={'/siteadmin/payout'} className={cx(bt.btnPrimaryBorder, bt.btnLarge, bt.noTextDecoration, 'bgBlack')}>
                            <FormattedMessage {...messages.goBack} />
                        </Link>
                    </div>
                    <div className={'table-responsive'}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">{formatMessage(messages.reservationId)}</th>
                                    <th scope="col">{formatMessage(messages.codeLabel)}</th>
                                    <th scope="col">{formatMessage(messages.adminListTitle)}</th>
                                    <th scope="col">{formatMessage(messages.hostNameLabel)}</th>
                                    <th scope="col">{formatMessage(messages.payoutLabel)}</th>
                                    <th scope="col">{formatMessage(messages.reason)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data &&
                                    <tr>
                                        <td>{data.id}</td>
                                        <td>{data.confirmationCode}</td>
                                        <td>
                                            {
                                                data.listData ?
                                                <a href={"/rooms/" + data.listData.id} target='_blank'>{data.listData.title}</a>
                                                : formatMessage(messages.dataMissing)
                                            }
                                        </td>
                                        <td>{data.hostData && data.hostData.firstName}</td>
                                        <td>
                                            <CurrencyConverter amount={payoutAmount} from={data.currency}/>
                                        </td>
                                        <td>{reason && reason.raw && reason.raw.message}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

}

export default injectIntl(withStyles(s, bt)(ShowFailedPayout));