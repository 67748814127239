import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// Components
import Loader from '../../components/Loader/Loader';
import AddedValues from '../../components/AddedValues/AddedValues';

import getAddedValuesQuery from '../../components/AddedValues/getAddedValues.graphql';

import s from './AddedValues.css';

class AddedValuesContainer extends React.Component {
	static propTypes = {
		region: PropTypes.string,
	};

	static defaultProps = {
		region: null,
	};

	render() {
		const { region, addedValuesData: { getAddedValues, loading } } = this.props;
		// console.log('getAddedValues', getAddedValues);

		return (
			<div className={s.root}>
				{
					loading ?
					<Loader />
					: (
						<AddedValues 
							region={region}
							addedValues={getAddedValues} 
						/>
					)
				}
			</div>
		);
	}
}

export default compose(
	withStyles(s),
	graphql(getAddedValuesQuery,
		{
			name: 'addedValuesData',
      options: (props) => ({
        variables: {
          filterByRegion: props.region || '',
        },
        fetchPolicy: 'network-only',
				ssr: false
      })
    }
	),
)(AddedValuesContainer);