import { convert } from "./currencyConvertion";

export function calculateReservationServiceFee(amount, base, rates, listingCurrency, serviceFeesInfo) {
  let serviceFee = 0;

  if (serviceFeesInfo) {
    if ('percentage' === serviceFeesInfo.type) {
      serviceFee = amount * (Number(serviceFeesInfo.value) / 100);
    }
    else {
      serviceFee = convert(base, rates, serviceFeesInfo.value, serviceFeesInfo.currency, listingCurrency);
    }
  }

  return Number(serviceFee.toFixed(2));
}