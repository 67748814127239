import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { graphql, compose } from 'react-apollo';
import Confirm from 'react-confirm-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, injectIntl } from 'react-intl';

import Link from '../../../../components/Link';
import CustomPagination from '../../../CustomPagination';

import { deleteWhyHostReview } from '../../../../actions/siteadmin/WhyHostReview/deleteWhyHostReview';
import { debounce } from '../../../../helpers/debounce';
import messages from '../../../../locale/messages';
import reviewsManagement from './reviewsManagement.graphql';

import s from './AdminReviewsManagement.css';
import bt from '../../../../components/commonStyle.css';

class AdminReviewsManagement extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      currentPage: 1,
      searchList: '',
    }

  }

  paginationData = (currentPage) => {
    const { reviewsManagement: { refetch }, setStateVariable } = this.props;
    let variables = { currentPage };
    setStateVariable({ currentPage });
    refetch(variables);
  }

  handleSearchChange(searchList) {
    const { reviewsManagement: { refetch }, setStateVariable } = this.props;
    let variables = {
      currentPage: 1,
      searchList,
    };
    setStateVariable(variables);
    refetch(variables);
  }

  deleteReview = async ({ reviewId }) => {
    const { deleteWhyHostReview, setStateVariable } = this.props;
    const { reviewsManagement: { refetch } } = this.props;
    let variables = { currentPage: 1 };
    await deleteWhyHostReview({ reviewId });
    await setStateVariable({ currentPage: 1 });
    await refetch(variables);
  }


  render() {
    const { currentPage } = this.props;
    const { reviewsManagement: { getWhyHostAllReviews } } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <div>
          <h1 className={s.headerTitle}> <FormattedMessage {...messages.whyBecomeHostBlock2} /></h1>
          <div className={cx(s.exportSection, s.exportSectionGridSub, 'bgBlack')}>
            <div>
              <Form.Control
                type="text"
                placeholder={formatMessage(messages.search)}
                onChange={(e) => this.handleSearchChange(e.target && e.target.value)}
                className={cx('searchInputControl', 'searchInputControlWidth', 'searchInputControlAR')}
              />
            </div>
            <div className={cx(s.textRight, 'textLeftAr')}>
              <Link to={"/siteadmin/whyHost/review/add"} className={cx(bt.btnPrimary, bt.btnLarge)}>
                <FormattedMessage {...messages.addNewLabel} />
              </Link>
            </div>
          </div>
          <div className={cx('table-responsive', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{formatMessage(messages.idLabel)}</th>
                  <th scope="col">{formatMessage(messages.userNameLabel)}</th>
                  <th scope="col">{formatMessage(messages.reviewContentLabel)}</th>
                  <th scope="col">{formatMessage(messages.editLabel)}</th>
                  <th scope="col">{formatMessage(messages.delete)}</th>
                </tr>
              </thead>
              <tbody>
              {
                getWhyHostAllReviews && getWhyHostAllReviews.results.length > 0 ?
                getWhyHostAllReviews.results.map((review, key) => {
                  return (
                    <tr key={key}>
                      <td>{review.id}</td>
                      <td>{review.userName}</td>
                      <td>{review.reviewContent}</td>
                      <td>
                        <Link to={"/siteadmin/whyHost/review/edit/" + review.id}>
                          <FormattedMessage {...messages.editLabel} />
                        </Link>
                      </td>
                      <td>
                        <div>
                          <Confirm
                            onConfirm={() => this.deleteReview({ reviewId: review.id })}
                            body={formatMessage(messages.areYouSureDeleteWishList)}
                            confirmText={formatMessage(messages.confirmDelete)}
                            cancelText={formatMessage(messages.cancel)}
                            title={formatMessage(messages.deleteReviewLabel)}
                          >
                            <a href="#">
                              <FormattedMessage {...messages.delete} />
                            </a>
                          </Confirm>
                        </div>
                      </td>
                    </tr>
                  )
                })
                : (
                  <tr><td colSpan="5">{formatMessage(messages.noRecordFound)}</td></tr>
                )
              }
              </tbody>
            </table>
          </div>
          {
            getWhyHostAllReviews && getWhyHostAllReviews.results && getWhyHostAllReviews.results.length > 0
            && <div>
              <CustomPagination
                total={getWhyHostAllReviews.count}
                currentPage={currentPage}
                defaultCurrent={1}
                defaultPageSize={10}
                change={this.paginationData}
                paginationLabel={formatMessage(messages.reviews)}
                isScroll
              />
            </div>
          }
        </div>
      </div>
    );
  }

}

const mapState = (state) => ({
});

const mapDispatch = {
  deleteWhyHostReview,
};

export default compose(injectIntl,
  withStyles(s, bt),
  connect(mapState, mapDispatch),
  graphql(reviewsManagement, {
    name: 'reviewsManagement',
    options: (props) => ({
      variables: {
        currentPage: props.currentPage,
        searchList: props.searchList,
      },
      fetchPolicy: 'network-only',
    })
  })
)(AdminReviewsManagement);