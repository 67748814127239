import React from 'react';
import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
	Row,
	Col
} from 'react-bootstrap';

import AVOfferProviderItem from './AVOfferProviderItem/AVOfferProviderItem';
import Loader from '../../Loader/Loader';

import s from './AVOfferProviders.css';

class AVOfferProviders extends React.Component {
	static propTypes = {
	};

	static defaultProps = {
	};

	render() {
		const { offerProviders, onOfferProviderClick, loading } = this.props;

		const logosPerRow = 6;

		const sliderSettings = {
			centerMode: false,
			infinite: true,
			slidesToShow: logosPerRow,
			rows: 1,
			slidesPerRow: 1,
			slidesToScroll: 1,
			autoplay: false,
			cssEase: "linear",
			adaptiveHeight: true,
			arrows: false,
			// rtl: true, // For arabic
		};

		return (
			<Loader type={"page"} show={loading}>
				<Row>
					<Col md={12}>
						<h3>Our Partners</h3>
						<p>Each partner has an offer in each city. Guests can benefit from the offers during the trip.</p>
						{
							(offerProviders.length > 0) ?
								<Row style={{ textAlign: '-webkit-center' }}>
									{
										(offerProviders.length > logosPerRow) ?
											<Slider {...sliderSettings}>
												{
													offerProviders.map((offerProvider, index) => (
														<AVOfferProviderItem
															key={index}
															offerProvider={offerProvider}
															onOfferProviderClick={onOfferProviderClick}
														/>
													))
												}
											</Slider>
											: (
												offerProviders.map((offerProvider, index) => (
													<Col md={2} key={index}>
														<AVOfferProviderItem
															offerProvider={offerProvider}
															onOfferProviderClick={onOfferProviderClick}
														/>
													</Col>
												))
											)
									}
								</Row>
								: (
									<p>We do not have partners in this city</p>
								)
						}
					</Col>
				</Row>
			</Loader>
		);
	}
}

export default compose(withStyles(s))(AVOfferProviders);