import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Row,
  Col,
  Form,
} from 'react-bootstrap';
import cx from 'classnames';

// Component
import CurrencyConverter from '../../CurrencyConverter';

// Helper
import { calculateDayDifference } from '../../../helpers/calculateDayDifference';
import { handleReservationDiscount } from '../../../helpers/handleReservationDiscount';
import { handleReservationSpecialPricing } from '../../../helpers/handleReservationSpecialPricing';
import { calculateReservationServiceFee } from '../../../helpers/calculateReservationServiceFee';

// Locale
import messages from '../../../locale/messages';

//Images
import Faq from '../../../../public/SiteIcons/question.svg';

import s from './Calendar.css';

class BillDetails extends Component {
  static propTypes = {
    serviceFees: PropTypes.shape({
      guest: PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    base: PropTypes.string.isRequired,
    rates: PropTypes.object.isRequired,
    formatMessage: PropTypes.any,
    specialPricingDays: PropTypes.array,
  };

  render() {
    const { serviceFees, base, rates, specialPricingDays, bookingFormValues, listingData } = this.props;
    const { formatMessage } = this.props.intl;

    let dayDifference, subtotal, dayAveragePrice, specialPricingApplied, discountValue, discountType, guestServiceFee, total;
    
    let monthlyRentalToggled = listingData.isMonthlyRentalOnly || bookingFormValues.showMonthlyRental;
    if (monthlyRentalToggled) {
      subtotal = listingData.monthlyRentalPrice;
      guestServiceFee = calculateReservationServiceFee(subtotal, base, rates, listingData.currency, {
        type: serviceFees.monthlyRentalGuestType, value: serviceFees.monthlyRentalGuestValue, currency: serviceFees.currency });
      total = subtotal + guestServiceFee + listingData.monthlyRentalCleaningPrice;
    }
    else {
      dayDifference = calculateDayDifference(bookingFormValues.startDate, bookingFormValues.endDate);
      if (dayDifference <= 0) {
        return null;
      }

      ({ subtotal, dayAveragePrice, specialPricingApplied } = handleReservationSpecialPricing(bookingFormValues.startDate, listingData.basePrice, dayDifference, specialPricingDays));
      ({ discountValue, discountType } = handleReservationDiscount(subtotal, dayDifference, listingData.weeklyDiscount, listingData.monthlyDiscount));

      subtotal = subtotal - discountValue;
      guestServiceFee = calculateReservationServiceFee(subtotal, base, rates, listingData.currency, {
        type: serviceFees.guestType, value: serviceFees.guestValue, currency: serviceFees.currency });
      total = subtotal + guestServiceFee + listingData.cleaningPrice;
    }

    return (
      <Form.Group className={s.noMargin}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className={'viewListingCalendar'}>
            <table className="table">
              <tbody>
                <tr className={cx(s.positionR)}>
                  <td className={cx(s.noBorder)}>
                    {
                      monthlyRentalToggled ?
                      <div className={cx(s.specialPriceText, 'directionLtr')}>
                        Monthly Rent
                      </div>
                      : (
                      <>
                      {
                        specialPricingApplied ?
                        <>
                          <div className={cx(s.specialPriceIcon, 'specialpriceRtl')}>
                            <span>
                              <img src={Faq} className={cx(s.faqImage, 'specialpriceRtl')} />
                            </span>
                            <div className={cx(s.tltip, s.relativeSection, 'tltipDarkMode', 'relativeSectionRTL')}>
                              <FormattedMessage {...messages.averagePricePerNight} />
                            </div>
                          </div>
                          <div className={cx(s.specialPriceText, s.paddingLeft, 'paddingLeftRtl', 'directionLtr')}>
                            <CurrencyConverter
                              amount={dayAveragePrice}
                              from={listingData.currency}
                            />
                            {' x'} {dayDifference} {dayDifference > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}
                          </div>
                        </>
                        : (
                          <div className={cx(s.specialPriceText, 'directionLtr')}>
                            <CurrencyConverter
                              amount={dayAveragePrice}
                              from={listingData.currency}
                            />
                            {' x'} {dayDifference} {dayDifference > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}
                          </div>
                        )
                      }
                    </>
                    )
                  }
                  </td>
                  <td className={cx(s.noBorder, 'text-right', 'textAlignLeftRtl')}>
                    <CurrencyConverter
                      amount={subtotal}
                      from={listingData.currency}
                    />
                  </td>
                </tr>
                {
                  (!monthlyRentalToggled && discountValue > 0) &&
                  <tr>
                    <td className={s.noBorder}>
                      {('weekly' === discountType) ? `${listingData.weeklyDiscount}% ${formatMessage(messages.weeklyDiscount)}` : ''}
                      {('monthly' === discountType) ? `${listingData.monthlyDiscount}% ${formatMessage(messages.monthlyDiscount)}` : ''}
                    </td>
                    <td className={cx('text-right', s.discountText, s.noBorder, 'textAlignLeftRtl')}>
                      <span>-&nbsp;</span>
                      <span className={cx(s.displayCell, s.priceCss)}>
                        <CurrencyConverter
                          amount={discountValue}
                          from={listingData.currency}
                        />
                      </span>
                    </td>
                  </tr>
                }
                {
                  monthlyRentalToggled && (listingData.monthlyRentalCleaningPrice > 0) &&
                  <tr>
                    <td className={s.noBorder}><FormattedMessage {...messages.cleaningFee} /></td>
                    <td className={cx('text-right', s.noBorder, 'textAlignLeftRtl')}>
                      <CurrencyConverter
                        amount={listingData.monthlyRentalCleaningPrice}
                        from={listingData.currency}
                      />
                    </td>
                  </tr>
                }
                {
                  !monthlyRentalToggled && (listingData.cleaningPrice > 0) && <tr>
                    <td className={s.noBorder}><FormattedMessage {...messages.cleaningFee} /></td>
                    <td className={cx('text-right', s.noBorder, 'textAlignLeftRtl')}>
                      <CurrencyConverter
                        amount={listingData.cleaningPrice}
                        from={listingData.currency}
                      />
                    </td>
                  </tr>
                }
                {
                  (guestServiceFee > 0) &&
                  <tr>
                    <td className={s.noBorder}><FormattedMessage {...messages.serviceFee} /></td>
                    <td className={cx('text-right', s.noBorder, 'textAlignLeftRtl')}>
                      <CurrencyConverter
                        amount={guestServiceFee}
                        from={listingData.currency}
                      />
                    </td>
                  </tr>
                }
                {
                  monthlyRentalToggled && (listingData.deposit > 0) &&
                  <tr>
                    {/* <td className={s.noBorder}><FormattedMessage {...messages.deposit} /></td> */}
                    <td className={s.noBorder}>Deposit</td>
                    <td className={cx('text-right', s.noBorder, 'textAlignLeftRtl')}>
                      <CurrencyConverter
                        amount={listingData.deposit}
                        from={listingData.currency}
                      />
                    </td>
                  </tr>
                }
                <tr>
                  <td className={s.totalText}>
                    <FormattedMessage {...messages.total} />
                    <br/>
                    {
                      monthlyRentalToggled &&
                      <span style={{fontSize: '12px'}}>&nbsp;{'(first month + deposit)'}</span>
                    }
                  </td>
                  <td className={cx('text-right', s.totalText, 'textAlignLeftRtl')}>
                    <CurrencyConverter
                      amount={total + listingData.deposit}
                      from={listingData.currency}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </Form.Group>
    );
  }
}

const mapState = (state) => ({
  specialPricingDays: state.viewListing.specialPricing,
  rates: state.currency.rates,
  base: state.currency.base,
  serviceFees: state.book.serviceFees,
  bookingFormValues: getFormValues('BookingForm')(state),
});

const mapDispatch = {
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(BillDetails)));