import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { flowRight as compose } from 'lodash';
import { injectIntl } from 'react-intl';

import s from './ManagePaymentGateway.css';
import { updatePaymentGatewayStatus } from '../../../actions/siteadmin/updatePayemntGatewayStatus';

// Translation
import messages from '../../../locale/messages';

class ManagePaymentGateway extends React.Component {
    static propTypes = {
        getAllPayments: PropTypes.shape({
            loading: PropTypes.bool,
            refetch: PropTypes.any.isRequired,
            getAllPaymentMethods: PropTypes.any
        }),
        title: PropTypes.string.isRequired,
    };

    handleUpdate(id, e) {
        const { updatePaymentGatewayStatus, getAllPayments: { refetch } } = this.props;
        let isEnable = e.target.value;
        isEnable = isEnable == 'true' ? true : false;
        updatePaymentGatewayStatus(id, isEnable);
        refetch();
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { getAllPayments: { getAllPaymentMethods }, title } = this.props;
        return (
            <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
                <div>
                    <h1 className={s.headerTitle}>{formatMessage(messages.paymentGatewaySection)}</h1>
                    <div className={cx('table-responsive', 'tableOne', 'tableBorderRadiusAdmin', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">{formatMessage(messages.idLabel)}</th>
                                    <th scope="col">{formatMessage(messages.paymentGateway)}</th>
                                    <th scope="col">{formatMessage(messages.status)}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                getAllPaymentMethods && getAllPaymentMethods.results && getAllPaymentMethods.results.length > 0 ?
                                getAllPaymentMethods.results.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.paymentName}</td>
                                            <td>
                                                <select value={item.isEnable} onChange={(e) => this.handleUpdate(item.id, e)}>
                                                    <option value={true}>{formatMessage(messages.activeLabel)}</option>
                                                    <option value={false}>{formatMessage(messages.inActiveLabel)}</option>
                                                </select>
                                            </td>
                                        </tr>
                                    )
                                })
                                : (
                                    <tr><td colSpan="3">{formatMessage(messages.noRecordFound)}</td></tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

}

const mapState = (state) => ({
});

const mapDispatch = {
    updatePaymentGatewayStatus
};

export default compose(injectIntl,
    withStyles(s),
    connect(mapState, mapDispatch),

)(ManagePaymentGateway);