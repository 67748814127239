import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { print } from 'graphql/language/printer';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { 
	Container, 
} from 'react-bootstrap';

// Components
import AVFilters from './AVFilters/AVFilters';
import AVSections from './AVSections/AVSections';
import AVOfferProviders from './AVOfferProviders/AVOfferProviders';
import AVOffersModal from './AVOffersModal/AVOffersModal';

import getAddedValuesQuery from './getAddedValues.graphql';
import getAVSectionActivitiesQuery from './getAVSectionActivities.graphql';
import getAVProviderOffersQuery from './getAVProviderOffers.graphql';

import s from './AddedValues.css';

class AddedValues extends React.Component {
	static propTypes = {
		region: PropTypes.string,
	};

	static defaultProps = {
		region: null,
	};

  constructor(props) {
    super(props);

    this.state = {
			region: props.region,
			sections: props.addedValues?.sections,
			offerProviders: props.addedValues?.offerProviders,

			selectedSection: {},
			selectedOfferProvider: {},

			isOpenOffersModal: false,

			loadingAddedValues: false,
			loadingSectionActivities: false,
			loadingProviderOffers: false,
    };

    this.getAddedValues = this.getAddedValues.bind(this);
    this.onRegionSelectChange = this.onRegionSelectChange.bind(this);
    this.onSectionClick = this.onSectionClick.bind(this);
    this.onOfferProviderClick = this.onOfferProviderClick.bind(this);
    this.closeOffersModal = this.closeOffersModal.bind(this);
  }

	closeOffersModal() {
    this.setState({ 
			isOpenOffersModal: false,
		});
	}

	onSectionClick(clickedSectionId) {
		const { selectedSection } = this.state;

		if (selectedSection.id !== clickedSectionId) {
			this.getSectionActivities(clickedSectionId);
		}
	}

	onOfferProviderClick(clickedOfferProviderId) {
		const { selectedOfferProvider } = this.state;

    this.setState({ isOpenOffersModal: true });
		if (selectedOfferProvider.id !== clickedOfferProviderId) {
    	this.setState({ selectedOfferProvider: {} });
			this.getProviderOffers(clickedOfferProviderId);
		}
	}

	onRegionSelectChange(event) {
    this.setState({ region: event.target.value });
		this.getAddedValues(event.target.value);
	}

	async getAddedValues(region) {
		this.setState({ loadingAddedValues: true });

		const resp = await fetch('/graphql', {
			method: 'post',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: print(getAddedValuesQuery),
				variables: {
					filterByRegion: region
				},
			}),
		});

		const { data } = await resp.json();
		if (data && data.getAddedValues) {
			this.setState({ 
				sections: data.getAddedValues.sections,
				offerProviders: data.getAddedValues.offerProviders,
				selectedSection: {},
				selectedOfferProvider: {},
			});
		}

		this.setState({ loadingAddedValues: false });
	}

	async getSectionActivities(sectionId) {
		const { region } = this.state;
		this.setState({ loadingSectionActivities: true });

		const resp = await fetch('/graphql', {
			method: 'post',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: print(getAVSectionActivitiesQuery),
				variables: {
					sectionId,
					filterByRegion: region ?? ''
				},
			}),
		});

		const { data } = await resp.json();
		if (data && data.getAVSectionActivities) {
			this.setState({ selectedSection: data.getAVSectionActivities });
		}
		
		this.setState({ loadingSectionActivities: false });
	}

	async getProviderOffers(offerProviderId) {
		const { region } = this.state;
		this.setState({ loadingProviderOffers: true });

		const resp = await fetch('/graphql', {
			method: 'post',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: print(getAVProviderOffersQuery),
				variables: {
					offerProviderId,
					filterByRegion: region ?? ''
				},
			}),
		});

		const { data } = await resp.json();
		if (data && data.getAVProviderOffers) {
			this.setState({ selectedOfferProvider: data.getAVProviderOffers });
		}

		this.setState({ loadingProviderOffers: false });
	}

	render() {
		const { regionsToShow } = this.props;
		const { 
			region, 
			sections, 
			offerProviders, 
			selectedSection, 
			isOpenOffersModal, 
			selectedOfferProvider,
			loadingAddedValues,
			loadingSectionActivities,
			loadingProviderOffers,
		} = this.state;

		return (
			<Container>
				<h1 className="text-center">EYGAR Added Values</h1>
				<AVFilters 
					region={region}
					regionsToShow={JSON.parse(regionsToShow)}
					onRegionSelectChange={this.onRegionSelectChange}
				/>
				<hr />
				<AVSections
					sections={sections}
					onSectionClick={this.onSectionClick}
					loading={loadingAddedValues || loadingSectionActivities}
					selectedSection={selectedSection}
					region={region}
				/>
				<hr />
				<AVOfferProviders
					offerProviders={offerProviders}
					onOfferProviderClick={this.onOfferProviderClick}
					loading={loadingAddedValues}
				/>
				<AVOffersModal
					isOpen={isOpenOffersModal}
					closeOffersModal={this.closeOffersModal}
					offerProvider={selectedOfferProvider}
					loading={loadingAddedValues || loadingProviderOffers}
					region={region}
				/>
			</Container>
		);
	}
}

const mapState = (state) => ({
  regionsToShow: state.siteSettings.data.addedValuesPageRegions,
});

export default injectIntl(withStyles(s)(connect(mapState)(AddedValues)));