import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { Field, formValueSelector, change, submit } from 'redux-form';
import { connect } from 'react-redux';

import Switch from '../../../Switch';

// Locale
import messages from '../../../../locale/messages';

import s from './MonthlyRentalToggle.css';
import s2 from '../SearchForm.css';

class MonthlyRentalToggle extends Component {

  static propTypes = {
    className: PropTypes.any,
    handleTabToggle: PropTypes.any,
    isExpand: PropTypes.bool
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);
  }

  renderInput = ({ input }) => {
    const { isMonthlyRental, change, submit } = this.props;

    return (
      <div>
        <Switch
          {...input}
          checked={isMonthlyRental}
          onToggle={() => {
            change('SearchForm', 'isMonthlyRental', !isMonthlyRental)
            submit('SearchForm');
          }}
        />
      </div>
    )
  }

  render() {
    const { className } = this.props;
    return (
      <div className={className}>
        <div className={cx(s.displayFlex, 'justify-content-center')}>
          <div>
            <p className={cx(s2.homepageFilterCard)}>
              <FormattedMessage {...messages.homepage_monthly_rental_search_toggle} />
            </p>
          </div>
          <div className={cx('searchSwitch', 'directionLtr', 'textAlignLeftRtl')}>
            <Field
              name="isMonthlyRental"
              component={this.renderInput}
            />
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector('SearchForm');
const mapState = (state) => ({
  fieldsSettingsData: state.listingFields.data,
  isMonthlyRental: selector(state, 'isMonthlyRental')
});

const mapDispatch = {
  change,
  submit,
};

export default injectIntl(withStyles(s, s2)(connect(mapState, mapDispatch)(MonthlyRentalToggle)));