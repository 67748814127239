// General
import React from 'react';
import PropTypes from 'prop-types';

import { flowRight as compose } from 'lodash';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingBar from 'react-redux-loading-bar';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
  Navbar,
  Form,
  Container,
  Row,
} from 'react-bootstrap';

// Locale
import messages from '../../locale/messages';

import {
  showResults,
  showFilter,
} from "../../actions/mobileSearchNavigation";

import SearchForm from './SearchForm';
import NavigationAfterLogin from './Navigation/NavigationAfterLogin';
import NavigationBeforeLogin from './Navigation/NavigationBeforeLogin';
import DropDownMenu from './DropDownMenu';

import Logo from '../Logo';
import Toaster from '../Toaster';
import Link from '../Link';

import closeIcon from '../../../public/SiteIcons/loginClose.svg';
import s from './Header.css';

class Header extends React.Component {
  static propTypes = {
    searchSettings: PropTypes.object,
  };

  static defaultProps = {
    searchSettings: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpen: 0,
      headerAtTop: true,
      smallDevice: false,
      tabletDevice: false,
      load: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);

    let isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
    }

    this.setState({
      load: true,
    });
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);

    let isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      window.removeEventListener('resize', this.handleResize);
    }
  }

  toggleMenu(newState) {
    const { isOpen } = this.state;

    if (newState) {
      if (('open' === newState) && (isOpen == 0)) {
        this.setState({ isOpen: 1 })
      }
      if (('close' === newState) && (isOpen == 1)) {
        this.setState({ isOpen: 0 })
      }
    }
    else {
      if ((isOpen == 1)) {
        this.setState({ isOpen: 0 })
      }

      if ((isOpen == 0)) {
        this.setState({ isOpen: 1 })
      }
    }
  }

  handleScroll() {
    let top = document.documentElement.scrollTop || document.body.scrollTop;
    let headerAtTop = true;
    if (top >= 50) {
      headerAtTop = false;
    }
    this.setState({
      headerAtTop
    });
  }

  handleResize() {
    const { tabs } = this.state;
    let isBrowser = typeof window !== 'undefined';
    let smallDevice = isBrowser ? window.matchMedia('(max-width: 767px)').matches : true;
    let tabletDevice = isBrowser ? window.matchMedia("(max-width: 1024px)").matches : false;

    for (let key in tabs) {
      tabs[key] = false;
    }

    this.setState({
      smallDevice,
      tabletDevice,
      tabs,
    });
  }

  render() {
    const { searchSettings, page, guests, isAuthenticated } = this.props;
    const { isOpen, headerAtTop, smallDevice, tabletDevice, load } = this.state;

    return (
      <div className={s.root}>
        <Toaster />
        <LoadingBar />
        <Container 
          className={cx(
            s.container, 
            'bgBlack dashboardBottomLayout', 
            ('search' == page) ? 'searchFixedHeader' : '',
        )}
        >
          <Row className='px-2'>
            <Navbar
              className={cx(
                s.commonHeader,
                ('whyHost' == page) ? 'whyHostHeader' : '',
                ('viewListing' == page) ? 'viewListingHeader' : '',
                'commonHeader darkModeInnerMenu',
              )}
            >
              <div className={s.headerFlexBox}>
                <div className='logoPadding innerMenuLogo'>
                  <Navbar.Brand  className={(s.homeLogo)}>
                    <Logo link={"/"} className={cx(s.brand, s.brandImg)} />
                  </Navbar.Brand>
                </div>

                {
                  ('whyHost' != page) && (
                    (headerAtTop && ('search' == page)) ?
                    <div className={cx(s.tabViewHidden, s.paddingTop, 'centerMenu scrollOff d-none d-md-block')}>
                      <Link to="/#" className={cx(s.centerLink, s.dot, 'centerLinkRTL dotRTL text-decoration-none')}>
                        <FormattedMessage {...messages.header_centerlinks_homepage} />
                      </Link>
                      <Link to="/whyhost" className={cx(s.centerLink, 'centerLinkRTL text-decoration-none')}>
                        <FormattedMessage {...messages.header_centerlinks_become_a_host} />
                      </Link>
                    </div>
                    : ((!smallDevice && !tabletDevice) &&
                      <Form className={cx(s.breakPoint, 'searchHeaderAR')}>
                        <SearchForm
                          page={page} 
                          guests={guests} 
                          smallDevice={smallDevice}
                          searchSettings={searchSettings} 
                          showFilter={showFilter}
                          showResults={showResults}
                        />
                      </Form>
                    )
                  )
                }

                <div onClick={() => this.toggleMenu()}>
                  <div className={cx('hamburgerButton hamburgerInnerButton d-block d-xl-none')} style={{ cursor: 'pointer'}}>
                    <DropDownMenu />
                  </div>
                </div>
  
                <div 
                  className={cx(
                    s.mobileMenu, 
                    (isOpen && s.menuOpen),
                    'homeMobileMenu mobileMenuDark', (isOpen && 'menu-open'), 
                  )}
                >
                  <div className={cx({ [s.menuClose]: isOpen == 0 }, s.rightMenuClose, 'd-block d-xl-none')}>
                    <div className={cx(s.closeButtonPosition, 'closeButtonPositionDark')}>
                      <div 
                        onClick={() => this.toggleMenu('close')}
                        className={cx(s.closeColor, 'textWhite closeColorRTL svgImg')}
                        style={{ cursor: 'pointer' }}
                      >
                        <img src={closeIcon} />
                      </div>
                    </div>
                  </div>

                  <div onClick={() => this.toggleMenu()}>
                    {
                      isAuthenticated ?
                      <NavigationAfterLogin page={page} isOpen={isOpen} toggleMenu={this.toggleMenu} />
                      : (
                        <NavigationBeforeLogin page={page} isOpen={isOpen} toggleMenu={this.toggleMenu} />
                      )
                    }
                  </div>
                </div>

              </div>
            </Navbar>
          </Row>

          {
            load && ((headerAtTop || (smallDevice || tabletDevice)) && ('search' == page)) &&
            <Row className='justify-content-center px-2'>
              <Form className={cx(s.breakPoint, 'searchHeaderAR text-center')}>
                <SearchForm
                  page={page} 
                  guests={guests} 
                  smallDevice={smallDevice}
                  searchSettings={searchSettings} 
                  showFilter={showFilter}
                  showResults={showResults}
                />
              </Form>
            </Row>
          }
        </Container>
      </div>
    );
  }
}

const mapState = (state) => ({
  account : state.account.data,
  isAuthenticated: state.runtime.isAuthenticated,
});

const mapDispatch = {
  showResults,
  showFilter,
};

export default compose(
  injectIntl,
  withStyles(s),
  connect(mapState, mapDispatch),
)(Header);