import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { formValueSelector, change } from 'redux-form';
import { FaBolt } from "react-icons/fa";
import {
  Form,
} from 'react-bootstrap';

import CurrencyConverter from '../../CurrencyConverter';
import ViewCount from '../ViewCount';
import BookingForm from './BookingForm';
import Loader from '../../Loader';
import ReservationTypeToggle from '../ReservationTypeToggle/ReservationTypeToggle';

import messages from '../../../locale/messages';

import s from './Calendar.css';

class Calendar extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    loading: PropTypes.bool,
    blockedDates: PropTypes.array,
    isHost: PropTypes.bool.isRequired,
    formatMessage: PropTypes.any,
  };
  static defaultProps = {
    isLoading: false,
    loading: false,
    blockedDates: [],
    isHost: false,
  };

  render() {
    const { 
      isLoading, isHost, loading, blockedDates, showMonthlyRental, change,
      UserListing, UserListing: { listingData, reviewsCount },
    } = this.props;
    
    let starRatingValue = 0;
    if (reviewsCount > 0 && UserListing.reviewsStarRating > 0) {
      starRatingValue = Math.round(UserListing.reviewsStarRating / reviewsCount)
    }

    let isMonthlyRental = false;
    if (listingData.monthlyRentalPrice > 0) {
      isMonthlyRental = true;
    }

    return (
      <div className={cx(s.bookItContainer, 'bookItContentCommon', 'modalMarginTop')}>
        <div className={cx(s.bookItContentBox, 'bgBlack')} data-sticky-section>
          {
            isMonthlyRental &&
            <ReservationTypeToggle 
              isMonthlyRentalOnly={listingData.isMonthlyRentalOnly}
              showMonthlyRental={showMonthlyRental}
              onReservationTypeToggle={() => 
                change('BookingForm', 'showMonthlyRental', !showMonthlyRental)
              }
            />
          }
          <div className={cx(s.bootItPriceSection, 'borderRadiusNone', 'textWhite')}>
            <div className={cx(s.noPadding, s.mobileBg, 'd-flex flex-column')}>
              {
                listingData.isMonthlyRentalOnly || showMonthlyRental ?
                  <div className="d-flex flex-column">
                    <div className={cx(s.bookItPriceAmount, s.currenyMarginR, 'currentMarginRtl d-flex align-items-center')}>
                      <CurrencyConverter
                        amount={listingData.monthlyRentalPrice}
                        className={s.bookItPrice}
                        from={listingData.currency}
                      />
                      <span>&nbsp;/&nbsp;<FormattedMessage {...messages.perMonth} /></span>
                      {
                        UserListing.bookingType === "instant" &&
                        <span><FaBolt className={s.instantIcon} /></span>
                      }
                    </div>
                    <div className="d-flex">
                      {
                        (listingData.minMonthlyRental > 1) ?
                          <div style={{ fontSize: '11px' }}>(<FormattedMessage {...messages.calendar_min_monthly_rental_notice} />: {listingData.minMonthlyRental})</div>
                          : <div style={{ fontSize: '11px' }}>(<FormattedMessage {...messages.calendar_min_monthly_rental_notice_no_minimum} />)</div>
                      }
                    </div>
                  </div>
                  : (
                    <div className={cx(s.bookItPriceAmount, s.currenyMarginR, 'currentMarginRtl d-flex align-items-center')}>
                      <CurrencyConverter
                        amount={listingData.basePrice}
                        className={s.bookItPrice}
                        from={listingData.currency}
                      />
                      <span>&nbsp;/&nbsp;<FormattedMessage {...messages.perNight} /></span>
                      {
                        UserListing.bookingType === "instant" &&
                        <span><FaBolt className={s.instantIcon} /></span>
                      }
                    </div>
                  )}
            </div>

            {/* <div className={cx(s.space2)}>
              <div className={cx(s.reviewSection, 'reviewSectionRTL')}><StarRating name={'review'} value={starRatingValue} /></div>
              <div>{reviewsCount > 0 && <span>{reviewsCount}{' '}{reviewsCount > 1 ? <FormattedMessage {...messages.reviews} /> : <FormattedMessage {...messages.review} />}</span>}</div>
            </div> */}
          </div>
          <div className={cx('bookItFormSection')}>
            <Loader
              show={loading || isLoading || false}
              type={"page"}
            >
              <div className={cx(s.bookItPanel, 'borderRadiusNone', 'bookItPanelRtl')}>
                <BookingForm
                  UserListing={UserListing}
                  isHost={isHost}
                  blockedDates={blockedDates}
                />
                <div>
                  <Form.Group className={cx(s.noMargin, s.textMuted, 'text-center', s.smallText, 'textWhite')}>
                    <FormattedMessage {...messages.bookingInfo} />
                  </Form.Group>
                </div>
              </div>

            </Loader>
          </div>
        </div>
        <ViewCount
          listId={UserListing.id}
          isHost={isHost}
        />
      </div>
    );
  }
}

const selector = formValueSelector('BookingForm');
const mapState = (state) => ({
  isLoading: state.viewListing.isLoading,
  showMonthlyRental: selector(state, 'showMonthlyRental'),
});

const mapDispatch = {
  change,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Calendar)))