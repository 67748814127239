import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { flowRight as compose } from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  Form,
  Container,
  Row,
  Col,
  Card,
} from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

// Locale
import messages from '../../locale/messages';

// Components
import SidePanel from './SidePanel';
import FooterButton from './FooterButton';
import update from './update';

// Style
import s from './ListPlaceStep1.css';
import bt from '../../components/commonStyle.css';

//Images
import DefaultIcon from '../../../public/SiteIcons/defaultIcon.png';

class SharedSpacesPage extends Component {

  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  constructor(props) {
    super(props);
  }

  handleCardClick = (input, optionId) => {
    let newValues = [...input.value];
    if (newValues.includes(optionId)) {
      newValues = newValues.filter(id => id !== optionId);
    }
    else {
      newValues = [...newValues, optionId];
    }
    
    input.onChange(newValues)
  }

  renderSharedSpacesCards = ({ input }) => {
    const { sharedSpacesList } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <Container className={cx(s.listContainer)}>
        <Row className="align-items-center">
          {
            sharedSpacesList.map((option, index) => {
              if (option.isEnable === "1") {
                return (
                  <Col xs={6} sm={4} md={6} xl={4} xxl={3} key={index} className="text-center">
                    <Card
                      className={cx(s.checkboxCard)}
                      border={(input.value.includes(option.id)) ? 'secondary' : 'light'}
                    >
                      <Card.Body 
                        className={cx(s.checkboxCardBody)}
                        style={{
                          backgroundColor: (input.value.includes(option.id)) ? 'var(--background-gray-color)' : 'var(--white-color)',
                        }}
                        onClick={(e) => this.handleCardClick(input, option.id)}
                      >
                        <div className={cx('svgImg')}>
                          <img 
                            src={ option.image ? ('/images/amenities/' + option.image) : DefaultIcon } 
                            className={cx(s.imgSection, 'imgSectionRtl', 'darkModeFilterNone')}
                          />
                          <div className={s.checkoutText}>
                            { messages[option.itemName] ? formatMessage(messages[option.itemName]) : messages[option.itemName] }
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                )
              }
            })
          }
        </Row>
      </Container>
    );
  }

  render() {
    const { handleSubmit, previousPage, nextPage, formPage, step } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={s.grid}>
        <SidePanel
          title={formatMessage(messages.stepOneCommonHeading)}
          landingContent={formatMessage(messages.whatSpace)}
        />
        <div className={s.landingMainContent}>
          <form onSubmit={handleSubmit}>
            <Form.Group className={s.formGroup}>
              <Form.Label className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.sharedSpaces} />
              </Form.Label>
              <Field
                name="spaces"
                component={this.renderSharedSpacesCards}
              />
            </Form.Group>

            <FooterButton
              previousPage={previousPage}
              previousPagePath={"amenities"}
              type={"submit"}
              formPage={formPage}
              step={step}
            />

          </form>
        </div>
      </div>
    )
  }
}

SharedSpacesPage = reduxForm({
  form: 'ListPlaceStep1',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: update
})(SharedSpacesPage);

const mapState = (state) => ({
  sharedSpacesList: state.listingFields.data.spaces,
});

export default compose(
  injectIntl,
  withStyles(s, bt),
  connect(mapState),
)(SharedSpacesPage);