import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// Component
import Meetup from './Meetup';
import Verification from './Verification';
import Payment from './Payment';
import AvatarUpload from './AvatarUpload';

class Booking extends Component {

	constructor(props) {
		super(props);
		this.state = {
			page: 'meetup'
		};
	}

	nextPage = (page) => {
		this.setState({ page });
	}

	render() {
		const { account, UserListing } = this.props;
		const { page } = this.state;

		return (
			<div>
				{
					page === 'meetup' && <Meetup
						nextPage={this.nextPage}

						guestDisplayName={account?.firstName}
						guestPicture={account?.picture}
						emailVerified={account?.verification?.isEmailConfirmed}

						hostDisplayName={UserListing?.user?.profile?.firstName}
						hostPicture={UserListing?.user?.profile?.picture}
					/>
				}

				{
					page === 'verification' && <Verification
						nextPage={this.nextPage}

						guestEmail={account?.email}
					/>
				}

				{
					page === 'avatar' && <AvatarUpload
						nextPage={this.nextPage}

						guestPicture={account?.picture}
						guestDisplayName={account?.firstName}
						emailVerified={account?.verification?.isEmailConfirmed}
					/>
				}

				{
					page === 'payment' && UserListing && <Payment
						UserListing={UserListing}
					/>
				}

			</div>
		);
	}
}

const mapState = (state) => ({
	account: state?.account?.data,
});

export default injectIntl(connect(mapState)(Booking));
