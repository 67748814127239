import React, { Component } from 'react';
import cx from 'classnames';
import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Modal } from 'react-bootstrap';

import Loader from '../../Loader/Loader';

import s from './AVOffersModal.css';

class AVOffersModal extends Component {

  handleModalClose = async () => {
    closeMoreFiltersModal();
  };

  render() {
    const { isOpen, closeOffersModal, offerProvider, loading, region } = this.props;

    return (
      <div>
        <Modal
          show={isOpen}
          onHide={closeOffersModal}
          dialogClassName={cx(s.logInModalContainer, 'moreFilterModal', 'moreFilter')}
          contentClassName={cx(s.modalContent)}
        >
          <Modal.Header closeButton className={cx(s.panelHeader, 'd-flex justify-content-between')}>
            <Modal.Title>
              {
                offerProvider?.name ?
                  <span>{region ? `${offerProvider.name} Offer for ${region}` : `${offerProvider.name} Offers for all cities`}</span>
                  : (
                    <span>Partner Offers</span>
                  )
              }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={cx(s.logInModalBody, `${loading ? 'd-flex align-items-center justify-content-center' : ''}`)}>
            <Loader type={"page"} show={loading}>
              {
                offerProvider?.id &&
                <div className="p-3">
                  {
                    region ?
                      <p>Offer covering {region}:</p>
                      : (
                        <p>List of Offers:</p>
                      )
                  }

                  <ul>
                    {
                      offerProvider.offers.map(offer => {
                        return (
                          <div key={offer.id}>
                            <li>
                              <p>{`${offer.name}: ${offer.description}`}</p>
                              <p>
                                Supported Cities:&nbsp;
                                {
                                  offer.parsedSupportedRegions.map((supportedRegion, index) => {
                                    return (
                                      <span key={index}>
                                        {supportedRegion}
                                        {
                                          ((offer.parsedSupportedRegions.length - 1) === index) ?
                                            <span>.</span>
                                            : (
                                              <span>&nbsp;-&nbsp;</span>
                                            )
                                        }
                                      </span>
                                    )
                                  })
                                }
                              </p>
                            </li>
                          </div>
                        )
                      })
                    }
                  </ul>
                </div>
              }
            </Loader>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default compose(withStyles(s))(AVOffersModal);