import fetch from '../../fetch';

export async function processStripePayment(type, cardDetails, reservationDetails, paymentMethodId, confirmPaymentIntentId) {
  console.log('from processStripePayment', type, cardDetails, reservationDetails, paymentMethodId, confirmPaymentIntentId);

  let URL;
  let variables = {
    cardDetails,
    reservationDetails,
    paymentMethodId
  };
  if (type === 'reservation') {
    URL = '/stripe-reservation';
  }
  else if (type === 'confirmReservation') {
    URL = '/stripe-reservation-confirm';
    variables = {
      confirmPaymentIntentId,
      reservationDetails
    }
  }
  else if (type === 'refund') {
    URL = '/stripe-refund';
  }
  else if (type === 'payout') {
    URL = '/stripe-payout';
  }
  else if (type === 'addPayout') {
    URL = '/stripe-add-payout';
    variables = {
      userDetails: cardDetails,
      bankDetails: reservationDetails
    };
  }
  else if (type === 'verifyPayout') {
    URL = '/stripe-verify-payout';
    variables = {
      userDetails: cardDetails
    };
  }

  console.log('processStripePayment before fetch', {URL, variables });
  const resp = await fetch(URL, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(variables),
    credentials: 'include'
  });
  console.log('processStripePayment resp', {URL, variables, resp });
  //return await resp.json();

  const { status, errorMessage, redirect, accountId, paymentIntentSecret } = await resp.json();
  console.log('resp of processStripePayment', { status, errorMessage, redirect, accountId, paymentIntentSecret });
  if (status === 200 && redirect) {
    window.location = redirect;
  }
  return {
    status,
    errorMessage,
    accountId,
    paymentIntentSecret
  }
}