import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import Link from '../../../components/Link';

import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './StaticPageManagement.css';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';

class StaticPageManagement extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <div>
          <h1 className={s.headerTitle}><FormattedMessage {...messages.staticPageManagement} /></h1>
          <div className={cx('table-responsive', 'tableBorderRadiusAdmin', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{formatMessage(messages.idLabel)}</th>
                  <th scope="col">{formatMessage(messages.pageName)}</th>
                  <th scope="col">{formatMessage(messages.preview)}</th>
                  <th scope="col">{formatMessage(messages.editLabel)}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td><FormattedMessage {...messages.aboutUsLabel} /></td>
                  <td><a href={"/about"} target={'_blank'}><FormattedMessage {...messages.preview} /></a></td>
                  <td><Link to={"/siteadmin/edit/staticpage/1"}><FormattedMessage {...messages.editLabel} /></Link></td>
                </tr>
                <tr>
                  <td>2</td>
                  <td><FormattedMessage {...messages.trustSafety} /></td>
                  <td><a href={"/safety"} target={'_blank'}><FormattedMessage {...messages.preview} /></a></td>
                  <td><Link to={"/siteadmin/edit/staticpage/2"}><FormattedMessage {...messages.editLabel} /></Link></td>
                </tr>

                <tr>
                  <td>3</td>
                  <td><FormattedMessage {...messages.travelCredit} /></td>
                  <td><a href={"/travel"} target={'_blank'}><FormattedMessage {...messages.preview} /></a></td>
                  <td><Link to={"/siteadmin/edit/staticpage/3"}><FormattedMessage {...messages.editLabel} /></Link></td>
                </tr>

                <tr>
                  <td>4</td>
                  <td><FormattedMessage {...messages.termsPrivacy} /></td>
                  <td><a href={"/privacy"} target={'_blank'}><FormattedMessage {...messages.preview} /></a></td>
                  <td><Link to={"/siteadmin/edit/staticpage/4"}><FormattedMessage {...messages.editLabel} /></Link></td>
                </tr>

                <tr>
                  <td>5</td>
                  <td><FormattedMessage {...messages.help} /></td>
                  <td><a href={"/help"} target={'_blank'}><FormattedMessage {...messages.preview} /></a></td>
                  <td><Link to={"/siteadmin/edit/staticpage/5"}><FormattedMessage {...messages.editLabel} /></Link></td>
                </tr>

                <tr>
                  <td>6</td>
                  <td><FormattedMessage {...messages.cookieStaticPolicy} /></td>
                  <td><a href={"/cookie-policy"} target={'_blank'}><FormattedMessage {...messages.preview} /></a></td>
                  <td><Link to={"/siteadmin/edit/staticpage/6"}><FormattedMessage {...messages.editLabel} /></Link></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

}

const mapState = (state) => ({
});

const mapDispatch = {
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(StaticPageManagement)));