import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { formValueSelector, getFormValues } from 'redux-form';
// Stripe
import { Elements } from 'react-stripe-elements';
// Components
import PayPal from './Paypal';
import Stripe from './Stripe';


class PayoutConfirm extends Component {
  static propTypes = {
    previousPage: PropTypes.any.isRequired,
    paymentMethodId: PropTypes.string.isRequired
  };

  render() {
    const { previousPage, paymentMethodId } = this.props;
    if (Number(paymentMethodId) == 2) {
      return (
        <Elements>
          <Stripe previousPage={previousPage} />
        </Elements>
      );
    }
    else {
      return (
        <PayPal previousPage={previousPage} />
      );
    }
  }
}

const selector = formValueSelector('PayoutForm');
const mapState = (state) => ({
  paymentMethodId: selector(state, 'methodId'),
});

export default connect(mapState)(PayoutConfirm);