import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { Field, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';

import Switch from '../../../Switch/Switch';

import { setPersonalizedValues } from '../../../../actions/personalized';

import messages from '../../../../locale/messages';

import s from './ShowOneTotalPrice.css';
import cs from '../../../../components/commonStyle.css';

class ShowOneTotalPrice extends Component {

  constructor(props) {
    super(props);
  }

  renderSwitch = ({ input }) => {
    const { totalPrice, change, setPersonalizedValues } = this.props;
    return (
      <Switch
        {...input}
        checked={totalPrice}
        onToggle={async () => {
          change('SearchForm', 'totalPrice', !totalPrice);
          await setPersonalizedValues({ name: 'totalPrice', value: !totalPrice });
        }}
      />
    )
  }

  render() {

    return (
      <div className={cs.space4}>
        <div className={cx(cs.displayFlex, cs.alignCenter, cs.justifyContentSpaceBetween, s.ShowPricecurvedbox, 'ShowPricecurvedboxRTL')}>
          <label className={cx(s.labelText, 'labelTextMapRtl')}>
            <FormattedMessage {...messages.totalPrice} />{' '}
            <span className={cx(s.totalDescriptiontext, 'typeNamedarkMode')}><FormattedMessage {...messages.totalPriceDesc} /></span>
          </label>
          <div className={'searchShowMapBtn'}>
            <Field
              name="totalPrice"
              component={this.renderSwitch}
            />
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector('SearchForm');
const mapState = (state) => ({
  totalPrice: selector(state, 'totalPrice')
});

const mapDispatch = {
    change,
    setPersonalizedValues
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ShowOneTotalPrice)));