import messages from '../../../locale/messages';

const validate = values => {

  const errors = {}

  if (!values.langCode) {
    errors.langCode = messages.Required;
  } else if (values.langCode && values.langCode.trim() == "") {
    errors.langCode = messages.blankSpace;
  } else if (values.langCode && values.langCode.length > 3) {
    errors.langCode = messages.exceedLimit;
  }

  if (!values.langName) {
    errors.langName = messages.Required;
  } else if (values.langName && values.langName.trim() == "") {
    errors.langName = messages.blankSpace;
  } else if (values.langCode && values.langCode.length > 255) {
    errors.langCode = messages.exceedLimit;
  }

  return errors
}

export default validate