import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '!isomorphic-style-loader!css-loader!react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/constants';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import CustomBookingFormDay from "./CustomBookingFormDay";

// Actions
import { checkAvailability } from '../../../actions/checkAvailability';
import { getAvailableMonthsForMonthlyRental } from '../../../actions/getMonthsForRental';
import { getSpecialPricingData } from '../../../actions/Listing/getSpecialPricingData';

// Locale
import messages from '../../../locale/messages';

// Helpers
import { isRTL } from '../../../helpers/formatLocale';
import { getDateUsingTimeZone } from '../../../helpers/dateRange';

class DateRange extends React.Component {
  static propTypes = {
    minimumNights: PropTypes.number.isRequired,
    maximumNights: PropTypes.number.isRequired,
    checkAvailability: PropTypes.any.isRequired,
    blockedDates: PropTypes.array.isRequired,
    listingId: PropTypes.number.isRequired,
    maxDaysNotice: PropTypes.string.isRequired,
    formatMessage: PropTypes.any,
    country: PropTypes.string.isRequired
  };

  static defaultProps = {
    blockedDates: [],
    maxDaysNotice: 'unavailable',
    country: ''
  }

  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      blockedDates: [],
      blockedDatesValues: [],
      blockedDatesSet: new Set(),
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.isDayBlocked = this.isDayBlocked.bind(this);
  }

  componentDidMount() {
    const { blockedDates, isFullDayBlock } = this.props;
    const blockedDatesSet = new Set();

    isFullDayBlock.forEach(day => {
      if (day.calendarStatus != 'available') {
        blockedDatesSet.add(moment(day.blockedDates).format('YYYY-MM-DD'));
      }
    });

    this.setState({ blockedDatesSet });
    this.setState({ blockedDatesValues: blockedDates });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { blockedDates, isFullDayBlock } = nextProps;
    const { blockedDatesSet } = this.state;

    isFullDayBlock.forEach(day => {
      if (day.calendarStatus != 'available') {
        blockedDatesSet.add(moment(day.blockedDates).format('YYYY-MM-DD'));
      }
    });

    this.setState({ blockedDatesSet });
    this.setState({ blockedDatesValues: blockedDates });
  }

  async onDatesChange(newValues) {
    const {
      listingId, change, maximumNights, minimumNights, getSpecialPricingData,
      checkAvailability, getAvailableMonthsForMonthlyRental, startDate, endDate,
    } = this.props;

    let startDateFormatted = moment(newValues.startDate).format('YYYY-MM-DD');
    let endDateFormatted = null;
    if (newValues.endDate) {
      endDateFormatted = moment(newValues.endDate).format('YYYY-MM-DD');
    }

    if ((startDate !== startDateFormatted) || (endDate !== endDateFormatted)) {
      await change('BookingForm', 'startDate', startDateFormatted);
      await change('BookingForm', 'endDate', endDateFormatted);

      if (startDateFormatted) {
        await getAvailableMonthsForMonthlyRental(listingId, startDateFormatted);
        if (endDateFormatted) {
          await getSpecialPricingData(listingId, startDateFormatted, endDateFormatted);
          await checkAvailability(listingId, startDateFormatted, endDateFormatted, maximumNights, minimumNights);
        }
      }
    }
  }

  isDayBlocked(day) {
    const { blockedDatesSet } = this.state;
    if (blockedDatesSet) {
      return blockedDatesSet.has(moment(day).format('YYYY-MM-DD'));
    } else {
      return null;
    }
  }

  render() {
    const { minimumNights, maxDaysNotice, locale, country, startDate, endDate } = this.props;
    const { formatMessage } = this.props.intl;
    const { focusedInput } = this.state;

    let today = getDateUsingTimeZone(country, false);
    let breakPoint = getDateUsingTimeZone(country, false);

    let condition;
    if (maxDaysNotice === 'unavailable') {
      condition = day =>
        !isInclusivelyAfterDay(day, today) ||
        isInclusivelyAfterDay(day, today)
    } else {
      if (maxDaysNotice === '3months') {
        breakPoint.add(3, 'months');
      } else if (maxDaysNotice === '6months') {
        breakPoint.add(6, 'months');
      } else if (maxDaysNotice === '9months') {
        breakPoint.add(9, 'months');
      } else if (maxDaysNotice === '12months') {
        breakPoint.add(12, 'months');
      }
      if (maxDaysNotice !== 'available') {
        condition = day =>
          !isInclusivelyAfterDay(day, today) ||
          isInclusivelyAfterDay(day, breakPoint)
      } else if (maxDaysNotice == 'available') {
        condition = day => !isInclusivelyAfterDay(day, today)
      }
    }

    return (
      <div>
        <DateRangePicker
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => this.setState({ focusedInput })}
          startDate={startDate ? moment(startDate) : null}
          endDate={endDate ? moment(endDate) : null}
          onDatesChange={this.onDatesChange}
          numberOfMonths={1}
          startDatePlaceholderText={formatMessage(messages.checkIn)}
          endDatePlaceholderText={formatMessage(messages.checkOut)}
          startDateId={'It is Start date id'}
          endDateId={'It is Start date id'}
          minimumNights={minimumNights > 0 ? minimumNights : 1}
          isOutsideRange={condition}
          hideKeyboardShortcutsPanel
          readOnly
          transitionDuration={0}
          anchorDirection={isRTL(locale) ? 'right' : 'left'}
          isRTL={isRTL(locale)}
          renderCalendarDay={props => <CustomBookingFormDay {...props} />}
          isDayBlocked={day => this.isDayBlocked(day)}
        />
      </div>
    );
  }
}

const selector = formValueSelector('BookingForm');
const mapState = state => ({
  isLoading: state.viewListing.isLoading,
  availability: state.viewListing.availability,
  locale: state.intl.locale,
  isFullDayBlock: state.viewListing.isFullDayBlock,
  startDate: selector(state, 'startDate'),
  endDate: selector(state, 'endDate'),
});

const mapDispatch = {
  checkAvailability,
  change,
  getSpecialPricingData,
  getAvailableMonthsForMonthlyRental,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(DateRange)));
