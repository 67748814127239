import { gql } from 'react-apollo';
import getPaymentMethodsQuery from '../../components/Payout/PayoutForm/getPaymentMethods.graphql';

import {
  GET_SERVICE_FEES_START,
  GET_SERVICE_FEES_SUCCESS,
  GET_SERVICE_FEES_ERROR,
} from '../../constants';

const query = gql`
  query getServiceFees{
    getServiceFees{
        id
        guestType
        guestValue
        hostType
        hostValue
        currency
        status
        monthlyRentalGuestType
        monthlyRentalGuestValue
        monthlyRentalHostType
        monthlyRentalHostValue
    }
  }
`;

export function getServiceFees() {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: GET_SERVICE_FEES_START,
    });

    try {
      const { data } = await client.query({ query });
      if (data && data.getServiceFees) {

        let payPalPayment = false, stripePayment = false;
        const getPaymentMethods = await client.query({
          query: getPaymentMethodsQuery,
          fetchPolicy: 'network-only',
        });

        if (getPaymentMethods?.data?.getPaymentMethods?.results?.length > 0) {
          getPaymentMethods.data.getPaymentMethods.results.map((item) => {
            if (item.isEnable) {
              if (item.id == 1) {
                payPalPayment = true;
              }
              if (item.id == 2) {
                stripePayment = true;
              }
            }
          });
        }

        dispatch({
          type: GET_SERVICE_FEES_SUCCESS,
          payload: {
            serviceFees: data.getServiceFees,
            payPalPayment,
            stripePayment,
          }
        });
      }
    } catch (error) {
      dispatch({
        type: GET_SERVICE_FEES_ERROR,
        payload: {
          error
        }
      });
      return false;
    }

    return true;
  };
}