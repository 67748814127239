// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux Form
import { formValueSelector } from 'redux-form';

// Translation
import { injectIntl } from 'react-intl';

// Redux
import { connect } from 'react-redux';

import AddLanguageForm from './AddLanguageForm';
import EditLanguageForm from './EditLanguageForm';

class ManageLanguagesForm extends Component {

  static propTypes = {
    id: PropTypes.number,
  };

  render() {
    const { id } = this.props;
    if(id != undefined) {
      return (
        <div className="empty">
          <EditLanguageForm />
        </div>
      )
    } else {
        return (
          <div className="empty">
            <AddLanguageForm />
          </div>
        )
    }
  }

}

const selector = formValueSelector("EditLanguageForm");
const mapState = (state) => ({
  id: selector(state, 'id'),
});

export default injectIntl(connect(mapState)(ManageLanguagesForm));