import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '!isomorphic-style-loader!css-loader!react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { SingleDatePicker, isInclusivelyAfterDay } from 'react-dates';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import CustomBookingFormDay from "./CustomBookingFormDay";

// Actions
import { getAvailableMonthsForMonthlyRental } from '../../../actions/getMonthsForRental';

// Locale
import messages from '../../../locale/messages';

//Helper
import { isRTL } from '../../../helpers/formatLocale';
import { getDateUsingTimeZone } from '../../../helpers/dateRange';

class CheckinSingleDate extends React.Component {
  static propTypes = {
    getAvailableMonthsForMonthlyRental: PropTypes.any.isRequired,
    blockedDates: PropTypes.array.isRequired,
    listingId: PropTypes.number,
    maxDaysNotice: PropTypes.string.isRequired,
    formatMessage: PropTypes.any,
    country: PropTypes.string.isRequired
  };

  static defaultProps = {
    blockedDates: [],
    maxDaysNotice: 'unavailable',
    country: ''
  }

  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      blockedDates: [],
      blockedDatesValues: []
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.isDayBlocked = this.isDayBlocked.bind(this);
  }
  componentDidMount() {
    const { blockedDates, isFullDayBlock } = this.props;
    const blockedDatesSet = new Set();

    isFullDayBlock.forEach(day => {
      if (day.calendarStatus != 'available') {
        blockedDatesSet.add(moment(day.blockedDates).format('YYYY-MM-DD'));
      }
    });

    this.setState({ blockedDatesSet });
    this.setState({ blockedDatesValues: blockedDates });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { blockedDates, isFullDayBlock } = nextProps;
    const { blockedDatesSet } = this.state;

    isFullDayBlock.forEach(day => {
      if (day.calendarStatus != 'available') {
        blockedDatesSet.add(moment(day.blockedDates).format('YYYY-MM-DD'));
      }
    });

    this.setState({ blockedDatesSet });
    this.setState({ blockedDatesValues: blockedDates });
  }

  async onDateChange(date) {
    const { listingId, change, getAvailableMonthsForMonthlyRental, startDate, endDate } = this.props;

    let startDateFormatted = moment(date).format('YYYY-MM-DD');
    if (startDate !== startDateFormatted) {
      await change('BookingForm', 'startDate', startDateFormatted);
      if (startDateFormatted) {
        await getAvailableMonthsForMonthlyRental(listingId, startDateFormatted);
      }
    }

    if (endDate !== null) {
      await change('BookingForm', 'endDate', null);
    }
  }

  isDayBlocked(day) {
    const { blockedDatesSet } = this.state;
    if (blockedDatesSet) {
      return blockedDatesSet.has(moment(day).format('YYYY-MM-DD'));
    }
    else {
      return null;
    }
  }

  render() {
    const { maxDaysNotice, locale, country, startDate } = this.props;
    const { formatMessage } = this.props.intl;
    const { focusedInput } = this.state;

    let today = getDateUsingTimeZone(country, false);
    let breakPoint = getDateUsingTimeZone(country, false);

    let condition;
    if (maxDaysNotice === 'unavailable') {
      condition = day =>
        !isInclusivelyAfterDay(day, today) ||
        isInclusivelyAfterDay(day, today)
    } else {
      if (maxDaysNotice === '3months') {
        breakPoint.add(3, 'months');
      } else if (maxDaysNotice === '6months') {
        breakPoint.add(6, 'months');
      } else if (maxDaysNotice === '9months') {
        breakPoint.add(9, 'months');
      } else if (maxDaysNotice === '12months') {
        breakPoint.add(12, 'months');
      }
      if (maxDaysNotice !== 'available') {
        condition = day =>
          !isInclusivelyAfterDay(day, today) ||
          isInclusivelyAfterDay(day, breakPoint)
      } else if (maxDaysNotice == 'available') {
        condition = day => !isInclusivelyAfterDay(day, today)
      }
    }

    return (
      <div>
        <SingleDatePicker
          focused={focusedInput}
          onFocusChange={({ focused }) => this.setState({ focusedInput: focused })}
          date={startDate ? moment(startDate) : null}
          onDateChange={this.onDateChange}
          numberOfMonths={1}
          placeholder={formatMessage(messages.checkIn)}
          id={'It is Checkin date id'}
          hideKeyboardShortcutsPanel
          readOnly
          transitionDuration={0}
          anchorDirection={isRTL(locale) ? 'right' : 'left'}
          isRTL={isRTL(locale)}
          isOutsideRange={condition}
          renderCalendarDay={props => <CustomBookingFormDay {...props} />}
          isDayBlocked={day => this.isDayBlocked(day)}
        />
      </div>
    );
  }
}

const selector = formValueSelector('BookingForm');
const mapState = state => ({
  isLoading: state.viewListing.isLoading,
  locale: state.intl.locale,
  isFullDayBlock: state.viewListing.isFullDayBlock,
  startDate: selector(state, 'startDate'),
  endDate: selector(state, 'endDate'),
});

const mapDispatch = {
  getAvailableMonthsForMonthlyRental,
  change,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(CheckinSingleDate)));
