import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './CancellationPolicies.css';

// Locale
import messages from '../../locale/messages';
import formatTextWithLineBreaks from '../../helpers/formatTextWithLineBreaks';

class Policy extends React.Component {

  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const { data } = this.props;

    return (
      <div key={data.id}>
        <div className={cx(s.textCss, s.lineBreak, s.topPadding, 'textWhite')}>
          <FormattedMessage {...messages[data.subTitle]} />
        </div>
        <div className={cx(s.boxOne, 'bgBlack')}>
          <h3 className={s.commonHeading}><FormattedMessage {...messages.before} /> {data.priorDays} <FormattedMessage {...messages.howManyday} />:</h3>
          <div className={cx(s.textCss, s.lineBreak, 'textWhite')}>
            <FormattedMessage {...messages[data.content1]} />
          </div>
        </div>
        <div className={cx(s.boxTwo, 'bgBlack')}>
          <h3 className={s.commonHeading}><FormattedMessage {...messages.checkIn} />:</h3>
          <div className={cx(s.textCss, s.lineBreak, 'textWhite')}>
            <FormattedMessage {...messages[data.content2]} />
          </div>
        </div>
        <div className={cx(s.boxThree, 'bgBlack')}>
          <h3 className={s.commonHeading}><FormattedMessage {...messages.checkOut} />:</h3>
          <div className={cx(s.textCss, s.lineBreak, 'textWhite')}>
            <FormattedMessage {...messages[data.content3]} />
          </div>
        </div>
        <div className={s.lineBorder} />
        <div className={cx(s.boxFour, 'bgBlack')}>
          <h3 className={s.commonHeading}><FormattedMessage {...messages.descriptionAdminLabel} />:</h3>
          <div className={cx(s.subText, s.lineBreak, 'descriptionPolicyRTL')}>
            <FormattedMessage {...messages[data.subContent]} />
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(withStyles(s)(Policy));
