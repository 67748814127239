import React from 'react';
import Layout from '../../components/Layout';
import messages from '../../locale/messages';
import WhyHostNew from './WhyHostNew';

const title = 'Become a host';

export default function action({ intl }) {
  const title = intl?.formatMessage(messages.becomeAHost);
  return {
    title,
    component: <Layout page='whyHost'><WhyHostNew title={title} /></Layout>,
  };
}
