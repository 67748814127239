import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import messages from '../../../locale/messages';
import s from './ReportManagement.css';
class ReportManagementInformation extends React.Component {

    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            userId: PropTypes.number.isRequired,
            reporterId: PropTypes.string.isRequired,
            reporterType: PropTypes.string.isRequired,
        })),
        formatMessage: PropTypes.func.isRequired,
    };

    static defaultProps = {
        data: []
    };

    render() {
        const { reportUserManagement, formatMessage } = this.props;

        return (
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">{formatMessage(messages.idLabel)}</th>
                            <th scope="col">{formatMessage(messages.reporterName)}</th>
                            <th scope="col">{formatMessage(messages.reporterEmail)}</th>
                            <th scope="col">{formatMessage(messages.userNameLabel)}</th>
                            <th scope="col">{formatMessage(messages.userEmailLabel)}</th>
                            <th scope="col">{formatMessage(messages.reportType)}</th>
                            <th scope="col">{formatMessage(messages.transferDate)}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        reportUserManagement && reportUserManagement.reportsData.length > 0 ?
                        reportUserManagement.reportsData.map(function (value, index) {
                            let date = moment(value.createdAt).format('MM/DD/YYYY');
                            return (
                                <tr key={index}>
                                    <td>{value.id}</td>
                                    <td>{value.reporterData ? value.reporterData.displayName : <FormattedMessage {...messages.userDeletedLabel} />}</td>
                                    <td>
                                        {
                                            value.reporterData && value.reporterEmail.email ?
                                            <a href={"/users/show/" + value.userProfileId.profileId} target="_blank">{value.reporterEmail.email}</a>
                                            : <FormattedMessage {...messages.userDeletedLabel} />
                                        }
                                    </td>
                                    <td>{value.userData ? value.userData.displayName : <FormattedMessage {...messages.userDeletedLabel} />}</td>
                                    <td>
                                        {
                                            value.userData && value.userEmail.email ?
                                            <a href={"/users/show/" + value.userData.profileId} target="_blank">{value.userEmail.email}</a>
                                            : <FormattedMessage {...messages.userDeletedLabel} />
                                        }
                                    </td>
                                    <td>{value.reportType}</td>
                                    <td>{date}</td>
                                </tr>
                            )
                        })
                        : (
                            <tr><td colSpan="7">{formatMessage(messages.noRecordFound)}</td></tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ReportManagementInformation;