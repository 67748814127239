import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'react-apollo';

// Redux
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './UserCouponInfo.css';

// Locale
import messages from '../../locale/messages';

class UserCouponInfo extends Component {

  static propTypes = {
    formatMessage: PropTypes.any,
  };

  static defaultProps = {
  };

  render() {
    const { coupon } = this.props;

    return (
      <div>
        {/* <h3 className={s.titleText}>{formatMessage(messages.)}</h3> */}
        <h3 className={s.titleText}>Coupon</h3>
        <p>{coupon.couponCode}</p>
      </div>
    );
  }
}

const mapState = (state) => ({
});

const mapDispatch = {
};

export default compose(
  injectIntl,
  withStyles(s),
  connect(mapState, mapDispatch),
)(UserCouponInfo);