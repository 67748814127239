import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import moment from 'moment';

import CustomPagination from '../../CustomPagination/CustomPagination';
import Link from '../../Link/Link';

import showToaster from '../../../helpers/showToaster';
import messages from '../../../locale/messages';

import ExportImage from '../../../../public/adminIcons/export.png';

import s from './InquiryManagement.css';
class InquiryManagement extends Component {

    handleSearchChange = (e) => {
        const { getAllInquiryQuery: { refetch }, changeStateValues } = this.props;
        let variables = {
            currentPage: 1,
            searchList: e.target.value,
        };
        changeStateValues({
            currentPage: 1,
            searchList: e.target.value,
        });
        refetch(variables);
    }

    paginationData = (currentPage) => {
        const { getAllInquiryQuery: { refetch }, changeStateValues, searchList } = this.props;
        let variables = { currentPage, searchList };
        changeStateValues({ currentPage });
        refetch(variables);
    }

    takeAction = async (threadId) => {
        const { refetch } = this.props;
        let query = `query checkThreadData($threadId:Int){
            checkThreadData(threadId:$threadId){
                status
            }
        }`;

        const resp = await fetch('/graphql', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query,
                variables: { threadId },
            }),
            credentials: 'include',
        });

        const { data } = await resp.json();
        if (data?.checkThreadData?.status == '200') {
            return true
        } else {
            showToaster({ messageId: 'alreadyApprove', toasterType: 'error' })
            let variables = {
                searchKey: '',
                currentPage: 1
            };
            await refetch(variables)
            return false
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { getAllInquiryQuery, currentPage, searchList, toCurrency } = this.props;
        let userType = 'host';
        return (
            <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
                <h1 className={s.headerTitle}><FormattedMessage {...messages.manageInquiry} /></h1>
                <div className={cx(s.exportSection, s.exportSectionGridSub, 'bgBlack')}>
                    <div>
                        <Form.Control
                            type="text"
                            placeholder={formatMessage(messages.search)}
                            onChange={(e) => this.handleSearchChange(e)}
                            className={cx('searchInputControl', 'searchInputControlWidth', 'searchInputControlAR')}
                        />
                    </div>
                    <div>
                        {
                            getAllInquiryQuery?.getAllInquiryAdmin?.inquiryData?.length > 0 && <a
                                href={`/export-admin-data?type=inquiry&keyword=${searchList}&toCurrency=${toCurrency}`}
                                className={cx(s.exportText, 'commonFloatLeft', 'textWhite')}>
                                <span className={s.vtrMiddle}><FormattedMessage {...messages.exportDataIntoCSV} /></span>
                                <span className={cx(s.exportLinkImg, 'exportLinkImgCommon')}>
                                    <img src={ExportImage} className={s.exportImg} />
                                </span>
                            </a>
                        }
                    </div>
                </div>
                <div className={cx('table-responsive', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">{formatMessage(messages.id)}</th>
                                <th scope="col">{formatMessage(messages.adminListTitle)}</th>
                                <th scope="col">{formatMessage(messages.checkInAndCheckOut)}</th>
                                <th scope="col">{formatMessage(messages.bookingStatus)}</th>
                                <th scope="col">{formatMessage(messages.bookingAction)}</th>
                                <th scope="col">{formatMessage(messages.guestEmail)}</th>
                                <th scope="col">{formatMessage(messages.hostEMailLabel)}</th>
                                <th scope="col">{formatMessage(messages.details)}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            getAllInquiryQuery && getAllInquiryQuery.getAllInquiryAdmin && getAllInquiryQuery.getAllInquiryAdmin.inquiryData && getAllInquiryQuery.getAllInquiryAdmin.inquiryData.length > 0 ?
                            getAllInquiryQuery.getAllInquiryAdmin.inquiryData.map((value, index) => {
                                let checkIn = moment(value.startDate).format('DD MMMM YYYY')
                                let checkOut = moment(value.endDate).format('DD MMMM YYYY')
                                return (
                                    <tr key={index}>
                                        <td>{value.id}</td>
                                        <td>
                                            {
                                                value?.threadsData?.listData?.title &&
                                                <a href={"/rooms/" + value.threadsData.listId} target='_blank'>{value?.threadsData?.listData?.title}</a>
                                            }
                                        </td>
                                        <td>{`${checkIn} - ${checkOut}`}</td>
                                        <td>
                                            <p>{value?.data ?
                                            <FormattedMessage {...messages.messageStatus2} /> 
                                            : <FormattedMessage {...messages.panelInquiry} />}
                                            </p>
                                        </td>
                                        <td>
                                            {
                                                value?.data == "preApproved" ?
                                                    <p><span>-</span></p>
                                                    :
                                                    <a
                                                        onClick={() => this.takeAction(value.threadId)}
                                                        target="_blank" href={"/message/" + value.threadId + "/" + userType}
                                                        className={cx(s.previewLink)}>
                                                        <FormattedMessage {...messages.manageLabel} />
                                                    </a>
                                            }
                                        </td>
                                        <td>{value?.threadsData?.guestUserData?.email}</td>
                                        <td>{value?.threadsData?.hostUserData?.email}</td>
                                        <td>
                                            <Link to={"/siteadmin/viewInquiry/" + value.id + "/inquiry"}><FormattedMessage {...messages.viewLabel} /></Link>
                                        </td>
                                    </tr>
                                )
                            })
                            : (
                                <tr><td colSpan="8">{formatMessage(messages.noRecordFound)}</td></tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
                {
                    getAllInquiryQuery?.getAllInquiryAdmin?.inquiryData?.length > 0
                    && <div>
                        <CustomPagination
                            total={getAllInquiryQuery.getAllInquiryAdmin.count}
                            currentPage={currentPage}
                            defaultCurrent={1}
                            defaultPageSize={10}
                            change={this.paginationData}
                            paginationLabel={formatMessage(messages.panelInquiry)}
                            isScorll
                        />
                    </div>
                }
            </div >
        )
    }
};

const mapState = (state) => ({
    toCurrency: state.currency.to || state.currency.base,
});

const mapDispatch = {
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(InquiryManagement)));