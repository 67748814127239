// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux Form
import { Field, reduxForm } from 'redux-form';

// Redux
import { connect } from 'react-redux';

// Translation
import { injectIntl, FormattedMessage } from 'react-intl';

// Locale
import messages from '../../locale/messages';

// Helpers
import validateStep3 from './validateStep3';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
  Form,
  InputGroup
} from 'react-bootstrap';
import s from './ListPlaceStep1.css';
import bt from '../../components/commonStyle.css';

// Component
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';
import updateStep3 from './updateStep3';

//Image
import toolTipIcon from '../../../public/SiteIcons/listCommonToolTip.svg';

class MonthlyRentalPage extends Component {

  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
    };
  }

  UNSAFE_componentWillMount() {
    const { valid, listingFields } = this.props;

    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { valid, listingFields } = nextProps;

    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, min, max }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <InputGroup>
          <Form.Control {...input} placeholder={label} type={type} className={className} maxLength={2} min={min} max={max} />
        </InputGroup>

        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    )
  }

  render() {
    const { handleSubmit, nextPage, previousPage, formPage, step } = this.props;
    const { isDisabled } = this.state;
    const { formatMessage } = this.props.intl;
    return (
      <div className={s.grid}>
        <SidePanel
          title={formatMessage(messages.stepThreeCommonHeading)}
          landingContent={formatMessage(messages.monthly_rental_sidePanel_title)}
        />
        <form onSubmit={handleSubmit}>
          <div className={cx(s.landingMainContent, 'disCountAddon')}>
            <Form.Group className={s.formGroup}>
              <Form.Label className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.monthly_rental_price_input_title} />
              </Form.Label>
              <Field
                name="monthlyRentalPrice"
                type="text"
                component={this.renderFormControl}
                className={cx(s.formControlInput, s.jumboSelect, s.formControlInputMaxWidth, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
              />
              <div className={s.tipCommonCss}>
                <img src={toolTipIcon} />
                <span className={cx(s.commonTipCsss, 'textWhite')}><FormattedMessage {...messages.monthly_rental_price_input_tip} /></span>
              </div>
            </Form.Group>
            <Form.Group className={s.formGroup}>
              <Form.Label className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.min_monthly_rental_input_title} />
              </Form.Label>
              <Field
                name="minMonthlyRental"
                type="number"
                component={this.renderFormControl}
                className={cx(s.formControlInput, s.jumboSelect, s.formControlInputMaxWidth, s.listCommonJumboSelect, 'listCommonJumboSelectRTL numberInputRTL')}
                min={1}
                max={12}
              />
            </Form.Group>
            <Form.Group className={s.formGroup}>
              <Form.Label className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.max_monthly_rental_input_title} />
              </Form.Label>
              <Field
                name="maxMonthlyRental"
                type="number"
                component={this.renderFormControl}
                className={cx(s.formControlInput, s.jumboSelect, s.formControlInputMaxWidth, s.listCommonJumboSelect, 'listCommonJumboSelectRTL numberInputRTL')}
                min={1}
                max={24}
              />
            </Form.Group>
            <Form.Group className={s.formGroup}>
              <Form.Label className={cx(s.landingLabel, 'textWhite')}>
                <FormattedMessage {...messages.is_monthly_rental_only_input_title} />
              </Form.Label>
              <Field
                name="isMonthlyRentalOnly"
                type="checkbox"
                component="input"
                className={cx(s.BookingradioInput)}
              />
            </Form.Group>
          </div>
          <FooterButton
            nextPage={nextPage}
            previousPage={previousPage}
            nextPagePath={"min-max-nights"}
            previousPagePath={"discount"}
            formPage={formPage}
            step={step}
            isDisabled={isDisabled}
          />
        </form>
      </div>
    );
  }
}

MonthlyRentalPage = reduxForm({
  form: 'ListPlaceStep3', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep3,
  onSubmit: updateStep3
})(MonthlyRentalPage);

const mapState = (state) => ({
  listingFields: state.listingFields.data
});

const mapDispatch = {};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(MonthlyRentalPage)));
