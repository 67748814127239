export function removeTypename(obj) {
  if (Array.isArray(obj)) {
    return obj.map(removeTypename);
  } else if (typeof obj === 'object' && obj !== null) {
    const { __typename, ...rest } = obj;
    const cleanedObj = {};

    for (const key in rest) {
      cleanedObj[key] = removeTypename(rest[key]);
    }

    return cleanedObj;
  }

  return obj;
}