import React from 'react';
import Layout from '../../components/Layout';
import Book from './Book';
import NotFound from '../notFound/NotFound';

import { getServiceFees } from '../../actions/ServiceFees/getServiceFees';

import { getRedirectURL } from '../../helpers/formatURL';
import messages from '../../locale/messages';

const title = 'Booking';

export default async function action({ store, params, intl }) {

  const title = intl?.formatMessage(messages.tabBooking);

  // From Redux Store
  let isAuthenticated = store.getState().runtime.isAuthenticated;
  let listingId = params.listingId;
  let bookDetails = store.getState().book.bookDetails;

  let isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload()
      }
    }
  };

  // Check authentication
  if (!isAuthenticated) {
    let urlParameters = {};
    if (bookDetails) {
      urlParameters = { 
        listingTitle: bookDetails.listingTitle,
        startDate: bookDetails.startDate, 
        guests: bookDetails.guests, 
        monthly: bookDetails.showMonthlyRental,
      };
      if (bookDetails.showMonthlyRental) {
        urlParameters = { 
          ...urlParameters,
          checkoutMonth: bookDetails.monthlyRentalCheckoutMonth,
        };
      }
      else {
        urlParameters = { 
          ...urlParameters,
          endDate: bookDetails.endDate,
        };
      }
    }
    
    let redirect = getRedirectURL(listingId, urlParameters)
    return { redirect };
  }

  // Check listingId is provided
  if (!listingId) {
    return {
      title,
      component: <Layout><NotFound title={title} /></Layout>,
      status: 404
    };
  }

  // Check redux store for booking data
  if (!bookDetails || (bookDetails.listingId != listingId)) {
    return { redirect: '/rooms/' + listingId };
  }
  
  await store.dispatch(getServiceFees());

  return {
    title,
    component:
      <Layout>
        <Book 
          listingId={listingId}
        />
      </Layout>,
  };
}