import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import StepsHomePage from './StepsHomePage';
import GeneralInfoPage from './GeneralInfoPage';
import RoomDetailsPage from './RoomDetailsPage';
import LocationDetailsPage from './LocationDetailsPage';
import ExactLocationPage from './ExactLocationPage';
import AmenitiesPage from './AmenitiesPage';
import SharedSpacesPage from './SharedSpacesPage';
import PhotosUploadPage from './PhotosUploadPage';
import TitlePage from './TitlePage';
import GuestRequirementsPage from './GuestRequirementsPage';
import HouseRulesPage from './HouseRulesPage';
import ReviewGuestBookPage from './ReviewGuestBookPage';
import AdvanceNoticePage from './AdvanceNoticePage';
import MinMaxNightsPage from './MinMaxNightsPage';
import CalendarPage from './CalendarPage';
import PricingPage from './PricingPage';
import DiscountPage from './DiscountPage';
import MonthlyRentalPage from './MonthlyRentalPage';
import BookingPage from './BookingPage';
import ActivitiesPage from './ActivitiesPage';
import LocalLawsPage from './LocalLawsPage';
import ListIntro from './ListIntro';

import history from '../../core/history';
import submit from './submit';
import update from './update';
import updateStep2 from './updateStep2';
import updateStep3 from './updateStep3';
import { updateLocationStatus } from '../../actions/getLocation';
class ListPlaceStep1 extends Component {

  static propTypes = {
    listData: PropTypes.object,
    existingList: PropTypes.bool,
    listingSteps: PropTypes.object,
    listId: PropTypes.number,
    formBaseURI: PropTypes.string,
    mode: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 'index',
    };
  }

  nextPage(formPage) {
    const { listId, existingList, formBaseURI, streetInput, updateLocationStatus } = this.props;
    if (('location' === formPage) && ((streetInput == null) || (streetInput == ''))) {
      updateLocationStatus(false);
    }

    let pathName = formBaseURI + formPage;
    if (existingList === true) {
      pathName = formBaseURI + listId + "/" + formPage;
    }
    history.push(pathName);
    this.setState({ page: formPage })
  }

  previousPage(formPage) {
    const { listId, existingList, formBaseURI } = this.props;
    let pathName = formBaseURI + formPage;
    if (existingList === true) {
      pathName = formBaseURI + listId + "/" + formPage;
    }
    history.push(pathName);
    this.setState({ page: formPage })
  }

  render() {
    const { formPage, photosCount, mode, existingList, listId, baseCurrency, step } = this.props;
    const { page } = this.state;

    let currentPage = page;
    if (mode != undefined && mode === "new") {
      currentPage = 'index';
    } else if (formPage != undefined) {
      currentPage = formPage;
    }

    return (
      <div className={'inputFocusColor'}>
        {currentPage === "index" && <ListIntro nextPage={this.nextPage} formPage={formPage} step={step} />}
        {currentPage === "home" && <StepsHomePage
          nextPage={this.nextPage}
          photosCount={photosCount}
        />}
        {currentPage === "room" && <GeneralInfoPage
          nextPage={this.nextPage}
          previousPage={this.previousPage}
          formPage={formPage} step={step}
        />}
        {currentPage === "bedrooms" && <RoomDetailsPage
          nextPage={this.nextPage}
          previousPage={this.previousPage}
          formPage={formPage} step={step}
        />}
        {currentPage === "location" && <LocationDetailsPage
          nextPage={this.nextPage}
          previousPage={this.previousPage}
          onSubmit={existingList ? update : submit}
          formPage={formPage} step={step}
        />}
        {currentPage === "map" && <ExactLocationPage
          nextPage={this.nextPage}
          previousPage={this.previousPage}
          formPage={formPage} step={step}
        />}
        {currentPage === "amenities" && <AmenitiesPage
          nextPage={this.nextPage}
          previousPage={this.previousPage}
          formPage={formPage} step={step}
        />}
        {currentPage === "spaces" && <SharedSpacesPage
          previousPage={this.previousPage}
          onSubmit={update}
          formPage={formPage} step={step}
        />}
        {currentPage === "photos" && <PhotosUploadPage
          previousPage={this.previousPage}
          listId={listId}
          nextPage={this.nextPage}
          formPage={formPage} step={step}
        />}
        {currentPage === "description" && <TitlePage
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          onSubmit={updateStep2}
          formPage={formPage} step={step}
        />}
        {currentPage === "guest-requirements" && <GuestRequirementsPage
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          formPage={formPage} step={step}
        />}
        {currentPage === "house-rules" && <HouseRulesPage
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          formPage={formPage} step={step}
        />}
        {currentPage === "review-how-guests-book" && <ReviewGuestBookPage
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          formPage={formPage} step={step}
        />}
        {currentPage === "advance-notice" && <AdvanceNoticePage
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          formPage={formPage} step={step}
        />}
        {currentPage === "min-max-nights" && <MinMaxNightsPage
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          listId={listId}
          formPage={formPage} step={step}
        />}
        {currentPage === "pricing" && <PricingPage
          previousPage={this.previousPage}
          nextPage={this.nextPage} 
          formPage={formPage} step={step}
          />}
        {currentPage === "calendar" && <CalendarPage
          listId={listId}
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          baseCurrency={baseCurrency}
          formPage={formPage} step={step}
        />}
        {currentPage === "discount" && <DiscountPage
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          formPage={formPage} step={step}
        />}
        {currentPage === "monthly-rental" && <MonthlyRentalPage
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          formPage={formPage} step={step}
        />}
        {currentPage === "booking-scenarios" && <BookingPage
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          formPage={formPage} step={step}
        />}
        {currentPage === "activities" && <ActivitiesPage
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          formPage={formPage} step={step}
        />}
        {currentPage === "local-laws" && <LocalLawsPage
          previousPage={this.previousPage}
          onSubmit={updateStep3}
          formPage={formPage} step={step}
        />}
      </div>
    );
  }
}

const selector = formValueSelector('ListPlaceStep1');
const mapState = (state) => ({
  existingList: state.location.isExistingList,
  listingSteps: state.location.listingSteps,
  photosCount: state.location.photosCount,
  streetInput: selector(state, 'street'),
});

const mapDispatch = {
  updateLocationStatus,
};

export default injectIntl(connect(mapState, mapDispatch)(ListPlaceStep1));