import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Form,
  Row,
  Col
} from 'react-bootstrap';

import messages from '../../../locale/messages';

import s from './Calendar.css';
import bt from '../../../components/commonStyle.css';

class MonthlyRentalCheckoutMonthSelector extends React.Component {
  static propTypes = {
    availableMonths: PropTypes.number,
    startDate: PropTypes.any,
    minMonthlyRental: PropTypes.number.isRequired,
  };

  renderFormControlSelect({ input, children, className }) {
    return (
      <div>
        <Form.Select {...input} className={className}>
          {children}
        </Form.Select>
      </div>
    );
  }

  renderMonths() {
    const { availableMonths, startDate, minMonthlyRental } = this.props;
    const { formatMessage } = this.props.intl;

    let numberOfMonthsMessage = formatMessage(messages.monthly_rental_checkout_months_select_option_number_of_months);
    let tillDateMessage = formatMessage(messages.monthly_rental_checkout_months_select_option_till_date);

    let nextMonthDayDate;
    const rows = [];
    rows.push(<option key="hidden-option" value="" disabled hidden>Choose checkout date</option>);
    if (availableMonths > 0) {
      for (let i = minMonthlyRental; i <= availableMonths; i++) {
        nextMonthDayDate = moment(startDate).add(30 * i, 'days').format('YYYY-MM-DD');
        rows.push(<option key={i} value={i}>{`${numberOfMonthsMessage}: ${i} (${tillDateMessage} ${nextMonthDayDate})`}</option>);
      }
    }

    return rows;
  }

  render() {
    const { bookingEditModal, onSelectChange } = this.props;

    return (
      <Form.Group>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Label className={cx({ [s.guestText]: !bookingEditModal, [s.guestTextEditModal]: bookingEditModal }, 'guestTextRTL')}>
              <FormattedMessage {...messages.monthly_rental_checkout_months_select_title} />
            </Form.Label>
            <Field
              name="monthlyRentalCheckoutMonth"
              component={this.renderFormControlSelect}
              className={cx(s.formControlSelect, bt.commonControlSelect, s.guestFormControlSelect, 'viewGuestCount')}
              onChange={bookingEditModal ? (e) => onSelectChange(e.target.value) : undefined}
            >
              {this.renderMonths()}
            </Field>
          </Col>
        </Row>
      </Form.Group>
    );
  }
}

const mapState = (state) => ({
  availableMonths: state.viewListing.availableMonths,
});

export default injectIntl(withStyles(s, bt)(connect(mapState)(MonthlyRentalCheckoutMonthSelector)));