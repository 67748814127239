import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import { 
    Form, 
} from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';

import StarRating from '../../StarRating';
import Link from '../../../components/Link';
import CustomPagination from '../../CustomPagination';

import userReviewsQuery from './userReviewsQuery.graphql';
import { debounce } from '../../../helpers/debounce';
import messages from '../../../locale/messages';
import { updateReviewStatus } from '../../../actions/siteadmin/UserReview/manageReviews';

import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './UserReviewsManagement.css';

class UserReviewsManagement extends React.Component {

    static propTypes = {
        data: PropTypes.array,
        editUser: PropTypes.func,
        title: PropTypes.string,
        updateReviewStatus: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            typing: false,
            typingTimeout: 0
        };
        this.handleSearchChange = debounce(this.handleSearchChange.bind(this));
    }

    paginationData = (currentPage) => {
        const { userReviews: { refetch }, setStateVariable } = this.props;
        let variables = { currentPage };
        setStateVariable({ currentPage });
        refetch(variables);
    }

    handleSearchChange(searchList) {
        const { userReviews: { refetch }, setStateVariable } = this.props;
        let variables = {
            currentPage: 1,
            searchList,
        };
        setStateVariable(variables);
        refetch(variables);
    }

    render() {
        const { userReviews: { getReviewsDetails } } = this.props;
        const { currentPage, searchList } = this.props;
        const { updateReviewStatus } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
                <div>
                    <h1 className={s.headerTitle}><FormattedMessage {...messages.usersReviews} /></h1>

                    <div className={cx(s.exportSection, 'bgBlack')}>
                        <Form.Group className={s.noMargin}>
                            <Form.Control
                                type="text"
                                placeholder={formatMessage(messages.search)}
                                onChange={(e) => this.handleSearchChange(e.target && e.target.value)}
                                className={cx('searchInputControl', 'searchInputControlAR')}
                            />
                        </Form.Group>
                    </div>

                    <div className={cx('table-responsive', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin', 'topScrollbarTable')}>
                        <table className={cx("table", 'topScrollbarTableContent')}>
                            <thead>
                                <tr>
                                    <th scope="col">{formatMessage(messages.listId)}</th>
                                    <th scope="col">{formatMessage(messages.reviewContentLabel)}</th>
                                    <th scope="col">{formatMessage(messages.adminListTitle)}</th>
                                    <th scope="col">{formatMessage(messages.reservationConfirmCode)}</th>
                                    <th scope="col">{formatMessage(messages.CheckInDate)}</th>
                                    <th scope="col">{formatMessage(messages.CheckOutDate)}</th>
                                    <th scope="col">{formatMessage(messages.SenderLabel)}</th>
                                    <th scope="col">{formatMessage(messages.receiverLabel)}</th>
                                    <th scope="col">{formatMessage(messages.ratingReviewLabel)}</th>
                                    <th scope="col">{formatMessage(messages.reviewStatusLabel)}</th>
                                    <th scope="col">{formatMessage(messages.actionLabel)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    getReviewsDetails && getReviewsDetails.results && getReviewsDetails.results.length > 0 ?
                                    getReviewsDetails.results.map(function (value, key) {

                                        let reviewContent = value.reviewContent;
                                        if (reviewContent.length > 10) {
                                            reviewContent = reviewContent.slice(0, 10).concat('...');
                                        }

                                        let hostName = value.userData && value.userData.firstName;
                                        let guestName = value.authorData && value.authorData.firstName;
                                        let hostProfileId = value.userData && value.userData.profileId;
                                        let guestProfileId = value.authorData && value.authorData.profileId;
                                        let title = value.listData && value.listData.title ? value.listData.title : 'List is missing';
                                        let confirmationCode = value.singleReservationData && value.singleReservationData.confirmationCode ? value.singleReservationData.confirmationCode : '';
                                        let checkInDate = value.singleReservationData && value.singleReservationData.checkIn ? moment(value.singleReservationData.checkIn).format('DD-MM-YYYY') : '';
                                        let checkOutDate = value.singleReservationData && value.singleReservationData.checkOut ? moment(value.singleReservationData.checkOut).format('DD-MM-YYYY') : '';

                                        return (
                                            <tr key={key}>
                                                <td>{value.listId}</td>
                                                <td>{reviewContent}</td>
                                                <td><a href={"/rooms/" + value.listId} target="_blank">{title}</a></td>
                                                <td>{confirmationCode}</td>
                                                <td>{checkInDate}</td>
                                                <td>{checkOutDate}</td>
                                                <td><a href={"/users/show/" + guestProfileId} target="_blank">{guestName}</a></td>
                                                <td><a href={"/users/show/" + hostProfileId} target="_blank">{hostName}</a></td>
                                                <td>
                                                    <StarRating
                                                        className={s.reviewStar}
                                                        value={value.rating}
                                                        name={'review'}
                                                        starCount={5}
                                                    />
                                                </td>
                                                <td>
                                                    <select value={value.isAdminEnable ? 'enable' : 'disable'} onChange={(e) => updateReviewStatus(value.id, e.target.value)}>
                                                        <option value={'enable'}>{formatMessage(messages.enabledLabel)}</option>
                                                        <option value={'disable'}>{formatMessage(messages.disabledLabel)}</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <Link to={"/siteadmin/management-reviews/" + value.id}>
                                                        <FormattedMessage {...messages.editLabel} />
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : (
                                        <tr><td colSpan="11">{formatMessage(messages.noRecordFound)}</td></tr>
                                    )
                                }
                            </tbody>
                        </table>

                    </div>
                    {
                        getReviewsDetails && getReviewsDetails.results && getReviewsDetails.results.length > 0
                        && <div>
                            <CustomPagination
                                total={getReviewsDetails.count}
                                currentPage={currentPage}
                                defaultCurrent={1}
                                defaultPageSize={10}
                                change={this.paginationData}
                                paginationLabel={formatMessage(messages.reviews)}
                                isScroll
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapState = (state) => ({
});

const mapDispatch = {
    updateReviewStatus
};

export default compose(
    injectIntl,
    withStyles(s),
    connect(mapState, mapDispatch),
    graphql(userReviewsQuery, {
        name: 'userReviews',
        options: (props) => ({
            variables: {
                currentPage: props.currentPage,
                searchList: props.searchList,
            },
            fetchPolicy: 'network-only',
        })
    })
)(UserReviewsManagement);