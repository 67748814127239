import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import {
  Modal
} from 'react-bootstrap';

import Calendar from '../Calendar';
import { closeBookingModal } from '../../../actions/BookingModal/modalActions';

import s from './BookingModal.css';

class BookingModal extends Component {
  static propTypes = {
    closeLoginModal: PropTypes.func,
    loginModal: PropTypes.bool,
    openSignupModal: PropTypes.func,
    formatMessage: PropTypes.func
  };

  render() {
    const { closeBookingModal, isOpenBookingModal, loading, blockedDates, isHost, UserListing } = this.props;

    return (
      <div>
        <Modal 
          show={isOpenBookingModal}
          animation={false}
          onHide={closeBookingModal}
          style={{ opacity: 1 }}
          dialogClassName={cx(s.logInModalContainer, 'BookingModalNew', 'viewListModal')}
        >
          <Modal.Header closeButton className='d-flex justify-content-between'>
            Make a reservation
          </Modal.Header>
          <Modal.Body className={s.logInModalBody}>
            <Calendar
              UserListing={UserListing}
              isHost={isHost}
              loading={loading}
              blockedDates={blockedDates}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const selector = formValueSelector('BookingForm');
const mapState = state => ({
  isOpenBookingModal: state.modalStatus.bookingModal,
});

const mapDispatch = {
  closeBookingModal,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(BookingModal)));
