import moment from 'moment';

export function handleReservationSpecialPricing(startDate, basePrice, dayDifference, specialPricingDays) {

  let subtotalOriginal = 0, specialPricingApplied = false, daysArray = [], reservationSpecialPricingDays = [];
  for (let i = 0; i < dayDifference; i++) {
    let currentDate = moment(startDate).add(i, 'day');
    daysArray.push(currentDate);
  }

  if (!specialPricingDays || specialPricingDays.length === 0) {
    subtotalOriginal = Number(basePrice) * dayDifference;
  }
  else {

    let dayDateFormatted;
    daysArray.map((day) => {

      dayDateFormatted = moment(day).format('MM/DD/YYYY');
      specialPricingDays.find(o => {

        if (dayDateFormatted === moment(o.blockedDates).format('MM/DD/YYYY')) {
          specialPricingApplied = true;

          reservationSpecialPricingDays.push({
            blockedDates: day,
            isSpecialPrice: Number(o.isSpecialPrice),
          });
          
          return true; 
        }

        return false;
      });
    });

    if (specialPricingApplied) {
      let specialPricingDaysSum = reservationSpecialPricingDays.reduce((sum, item) => sum + Number(item.isSpecialPrice), 0);
      let regularPricingDaysCount = dayDifference - reservationSpecialPricingDays.length;
      subtotalOriginal = Number(specialPricingDaysSum) + (Number(basePrice) * regularPricingDaysCount);
    }
    else {
      subtotalOriginal = Number(basePrice) * dayDifference;
    }
  }

  let dayAveragePrice = Number(subtotalOriginal) / Number(dayDifference);
  return {
    subtotal: dayAveragePrice.toFixed(2) * dayDifference,
    dayAveragePrice,
    specialPricingApplied,
    reservationSpecialPricingDays
  };
}