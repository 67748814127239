import React, { Component } from 'react';
import { graphql, compose, gql } from 'react-apollo';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
  Form,
  Accordion,
  Button,
} from 'react-bootstrap';
import { FaTrash } from "react-icons/fa";

// Component
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';
import Loader from '../Loader/Loader';

import validateStep3 from './validateStep3';
import updateStep3 from './updateStep3';

// Locale
import messages from '../../locale/messages';

// Style
import s from './ListPlaceStep1.css';
import bt from '../commonStyle.css';

class ActivitiesPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedSection: {},
      accordionActiveKey: null,
    };

    this.onSectionSelectChange = this.onSectionSelectChange.bind(this);
    this.onActivityCheckboxClick = this.onActivityCheckboxClick.bind(this);
    this.onCustomActivityAdd = this.onCustomActivityAdd.bind(this);
    this.deleteActivity = this.deleteActivity.bind(this);
    this.deleteCustomActivity = this.deleteCustomActivity.bind(this);
    this.onAccordionItemSelect = this.onAccordionItemSelect.bind(this);
  }

  onAccordionItemSelect(eventKey) {
    const { accordionActiveKey } = this.state;

    if (eventKey === accordionActiveKey) {
      this.setState({ accordionActiveKey: null });
    }
    else {
      this.setState({ accordionActiveKey: eventKey });
    }
  }

  onSectionSelectChange(event) {
    const { getAllSectionsActivitiesResults: { getAllSectionsActivities }, sectionsForListingActivities } = this.props;
    let selectedSectionId = Number(event.target.value);

    let selectedSectionValues = {};
    if (selectedSectionId) {
      selectedSectionValues = getAllSectionsActivities.sections.find(item => item.id === selectedSectionId);
    }

    const currentSectionForListingActivities = sectionsForListingActivities.find(sectionItem => sectionItem.id === selectedSectionId);

    let selectedSection = {
      ...selectedSectionValues,
      listingActivitiesIds: [],
    };

    if (currentSectionForListingActivities) {
      selectedSection.listingActivitiesIds = currentSectionForListingActivities.activities.map(activityItem => activityItem.id);
    }

    this.setState({ selectedSection });
  }

  onActivityCheckboxClick(sectionId, activityId) {
    const { change, sectionsForListingActivities } = this.props;
    const { selectedSection } = this.state;

    let sectionIndexForListingActivities = null;
    const currentSectionForListingActivities = sectionsForListingActivities.find((sectionItem, index) => {
      if (sectionItem.id === sectionId) {
        sectionIndexForListingActivities = index;
        return true;
      }
      return false;
    });

    const activityToAdd = {
      id: activityId,
      listingActivities: [
        {
          id: null,
          details: '',
        }
      ],
    }

    let updatedSectionsForListingActivities = JSON.parse(JSON.stringify(sectionsForListingActivities));
    if (currentSectionForListingActivities) {
      const activityExists = currentSectionForListingActivities.activities.find(activityItem => activityItem.id === activityId);
      if (activityExists) {
        return;
      }

      updatedSectionsForListingActivities[sectionIndexForListingActivities].activities.push(activityToAdd);
    }
    else {
      const sectionToAdd = {
        id: sectionId,
        activities: [activityToAdd]
      }

      updatedSectionsForListingActivities.push(sectionToAdd);
    }

    if ((sectionId === selectedSection.id) && !selectedSection.listingActivitiesIds.includes(activityId)) {
      let updatedListingActivitiesIds = selectedSection.listingActivitiesIds;
      if (Array.isArray(updatedListingActivitiesIds) && updatedListingActivitiesIds.length > 0) {
        updatedListingActivitiesIds.push(activityId);
      }
      else {
        updatedListingActivitiesIds = [activityId];
      }
      this.setState({
        selectedSection: {
          ...selectedSection,
          listingActivitiesIds: updatedListingActivitiesIds
        },
        accordionActiveKey: sectionId,
      });
    }

    change('sectionsForListingActivities', updatedSectionsForListingActivities);
  }

  onCustomActivityAdd(event, sectionId) {
    const { change, sectionsForListingCustomActivities } = this.props;
    event.preventDefault();

    let sectionIndexForListingCustomActivities = null;
    const currentSectionForListingCustomActivities = sectionsForListingCustomActivities.find((sectionItem, index) => {
      if (sectionItem.id === sectionId) {
        sectionIndexForListingCustomActivities = index;
        return true;
      }
      return false;
    });

    const customActivityToAdd = {
      id: null,
      name: '',
      description: '',
    };

    let updatedSectionsForListingCustomActivities = JSON.parse(JSON.stringify(sectionsForListingCustomActivities));
    if (currentSectionForListingCustomActivities) {
      updatedSectionsForListingCustomActivities[sectionIndexForListingCustomActivities].listingCustomActivities.push(customActivityToAdd);
    }
    else {
      const sectionToAdd = {
        id: sectionId,
        listingCustomActivities: [customActivityToAdd]
      }

      updatedSectionsForListingCustomActivities.push(sectionToAdd);
    }

    change('sectionsForListingCustomActivities', updatedSectionsForListingCustomActivities);
    this.setState({ accordionActiveKey: sectionId });
  }

  deleteActivity(sectionId, activityId) {
    const { sectionsForListingActivities, change } = this.props;
    const { selectedSection } = this.state;

    const updatedSectionsForListingActivities = sectionsForListingActivities.map(section => {
      if (section.id === sectionId) {

        if (section.id === selectedSection.id) {
          this.setState({
            selectedSection: {
              ...selectedSection,
              listingActivitiesIds: selectedSection.listingActivitiesIds.filter(id => id !== activityId)
            }
          });
        }

        return {
          ...section,
          activities: section.activities.filter(activity => activity.id !== activityId)
        };
      }
      return section;
    }).filter(section => section.activities.length !== 0);

    change('sectionsForListingActivities', updatedSectionsForListingActivities);
  }

  deleteCustomActivity(sectionId, customActivityIndex) {
    const { sectionsForListingCustomActivities, change } = this.props;

    const updatedSectionsForListingCustomActivities = sectionsForListingCustomActivities.map(section => {
      if (section.id === sectionId) {
        return {
          ...section,
          listingCustomActivities: section.listingCustomActivities.filter((_, index) => index !== customActivityIndex)
        };
      }
      return section;
    }).filter(section => section.listingCustomActivities.length !== 0);

    change('sectionsForListingCustomActivities', updatedSectionsForListingCustomActivities);
  }

  render() {
    const { handleSubmit, nextPage, formPage, step, isDisabled, previousPage, getAllSectionsActivitiesResults: { getAllSectionsActivities, loading }, sectionsForListingActivities, sectionsForListingCustomActivities } = this.props;
    const { formatMessage } = this.props.intl;
    const { selectedSection, accordionActiveKey } = this.state;

    let listingActivitiesExists = false;
    if ((sectionsForListingActivities !== undefined) && (sectionsForListingActivities.length > 0)) {
      listingActivitiesExists = true;
    }
    let listingCustomActivitiesExists = false;
    if ((sectionsForListingCustomActivities !== undefined) && (sectionsForListingCustomActivities.length > 0)) {
      listingCustomActivitiesExists = true;
    }

    return (
      <div className={cx(s.grid)}>
        <SidePanel
          title={formatMessage(messages.stepThreeCommonHeading)}
          landingContent="What activities can you offer to the guests?"
        />
        <Loader type={"page"} show={(sectionsForListingActivities === undefined) || (sectionsForListingCustomActivities === undefined) || loading}>
          {
            (!loading && (sectionsForListingActivities !== undefined) && (sectionsForListingCustomActivities !== undefined)) &&
            <form onSubmit={handleSubmit}>
              <div className={s.landingMainContent}>
                <h3 className={s.landingContentTitle}>Added Activities</h3>

                {
                  (listingActivitiesExists || listingCustomActivitiesExists) ?
                    <div>
                      <p>Expand each section to see the related activities</p>
                      <Accordion
                        id="activities-sections-accordion"
                        activeKey={accordionActiveKey || undefined}
                        onSelect={this.onAccordionItemSelect}
                      >
                        {
                          getAllSectionsActivities.sections.map((section) => {
                            let addedActivities = [], addedCustomActivities = [];

                            let sectionIndexForListingActivities = null;
                            const currentSectionForListingActivities = sectionsForListingActivities.find((sectionItem, index) => {
                              if (sectionItem.id === section.id) {
                                sectionIndexForListingActivities = index;
                                return true;
                              }
                              return false;
                            });
                            if (currentSectionForListingActivities) {
                              addedActivities = currentSectionForListingActivities.activities.map(activityItem => activityItem.id);
                            }

                            let sectionIndexForListingCustomActivities = null;
                            const currentSectionForListingCustomActivities = sectionsForListingCustomActivities.find((sectionItem, index) => {
                              if (sectionItem.id === section.id) {
                                sectionIndexForListingCustomActivities = index;
                                return true;
                              }
                              return false;
                            });
                            if (currentSectionForListingCustomActivities) {
                              addedCustomActivities = currentSectionForListingCustomActivities.listingCustomActivities.map(listingCustomActivityItem => listingCustomActivityItem.id);
                            }

                            return (
                              ((addedActivities.length > 0) || addedCustomActivities.length > 0) &&
                              <Accordion.Item key={section.id} eventKey={section.id}>
                                <Accordion.Header>{section.name}</Accordion.Header>
                                <Accordion.Body>
                                  {
                                    (addedActivities.length > 0) &&
                                    section.activities.map(activity => {
                                      if (addedActivities.includes(activity.id)) {
                                        let activityIndex = null;
                                        const activityValues = currentSectionForListingActivities.activities.find((activityItem, index) => {
                                          if (activityItem.id === activity.id) {
                                            activityIndex = index;
                                            return true;
                                          }
                                          return false;
                                        });

                                        return (
                                          <div key={activity.id} className="mb-3">
                                            <div className="d-flex justify-content-between mb-2">
                                              <div>
                                                <span className={cx(s.bookText, 'rtlBookText')}>{activity.name}</span>
                                                <span className={cx(s.subText, 'textWhite')}>{activity.description}</span>
                                              </div>
                                              <Button
                                                variant="light"
                                                onClick={(event) => {
                                                  event.preventDefault();
                                                  if (activityValues.listingActivities[0].details === '') {
                                                    this.deleteActivity(section.id, activity.id);
                                                  }
                                                  else if (window.confirm('Are you sure you want to delete this activity?')) {
                                                    this.deleteActivity(section.id, activity.id);
                                                  }
                                                }}
                                              >
                                                <FaTrash />
                                              </Button>
                                            </div>
                                            <Field
                                              name={`sectionsForListingActivities[${sectionIndexForListingActivities}].activities[${activityIndex}].listingActivities[0].details`}
                                              component="textarea"
                                              className={cx(s.textarea, 'form-control')}
                                              placeholder="Provide details for the activity"
                                            />
                                          </div>
                                        );
                                      }
                                    })
                                  }
                                  {
                                    (addedCustomActivities.length > 0) &&
                                    <div>
                                      <p>Custom Activities for {section.name} section</p>
                                      {
                                        currentSectionForListingCustomActivities.listingCustomActivities.map((customActivity, index) => {
                                          return (
                                            <div key={index}>
                                              <div className="d-flex justify-content-between align-items-center mb-2">
                                                <span>Custom Activity {index + 1}</span>
                                                <Button
                                                  variant="light"
                                                  onClick={(event) => {
                                                    event.preventDefault();
                                                    if ((customActivity.name === '') && (customActivity.description === '')) {
                                                      this.deleteCustomActivity(section.id, index);
                                                    }
                                                    else if (window.confirm('Are you sure you want to delete this custom activity?')) {
                                                      this.deleteCustomActivity(section.id, index);
                                                    }
                                                  }}
                                                >
                                                  <FaTrash />
                                                </Button>
                                              </div>
                                              <Field
                                                name={`sectionsForListingCustomActivities[${sectionIndexForListingCustomActivities}].listingCustomActivities[${index}].name`}
                                                component="input"
                                                type="text"
                                                className={cx('form-control')}
                                                placeholder="Choose a name for your custom activity"
                                              />
                                              <Field
                                                name={`sectionsForListingCustomActivities[${sectionIndexForListingCustomActivities}].listingCustomActivities[${index}].description`}
                                                component="textarea"
                                                className={cx(s.textarea, 'form-control mt-2 mb-3')}
                                                placeholder="Describe your custom activity"
                                              />
                                            </div>
                                          );
                                        })
                                      }
                                    </div>
                                  }
                                  <Button variant="outline-dark" onClick={(event) => this.onCustomActivityAdd(event, section.id)}>Add custom activity for section {section.name}</Button>
                                </Accordion.Body>
                              </Accordion.Item>
                            )
                          })
                        }
                      </Accordion>
                    </div>
                    : (
                      <p>You still did not add any activity to offer to your guests</p>
                    )
                }

                <h3 className={cx(s.landingContentTitle, 'mt-3')}>Select new activities</h3>
                <select 
                  className="form-control" 
                  value={selectedSection?.id ?? ''} 
                  onChange={(event) => this.onSectionSelectChange(event)}
                  style={{ width: '250px' }}
                >
                  <option value="" disabled hidden>Select a specific section</option>
                  {
                    getAllSectionsActivities?.sections.map(section => (
                      <option key={section.id} value={section.id}>{section.name}</option>
                    ))
                  }
                </select>

                {
                  selectedSection?.id &&
                  <div className={s.spaceTop3}>
                    {
                      selectedSection.activities.map(activity => {
                        return (
                          <div key={activity.id}>
                            <div className="d-flex" style={{ gap: '10px' }}>
                              <Form.Check
                                type="checkbox"
                                checked={selectedSection.listingActivitiesIds ? selectedSection.listingActivitiesIds.includes(activity.id) : false}
                                disabled={selectedSection.listingActivitiesIds ? selectedSection.listingActivitiesIds.includes(activity.id) : false}
                                onChange={() => this.onActivityCheckboxClick(selectedSection.id, activity.id)}
                              />
                              <span>{activity.name}</span>
                            </div>
                            <span className={cx(s.subText, 'textWhite, mt-1 mb-3')}>{activity.description}</span>
                          </div>
                        );
                      })
                    }
                    <Button variant="outline-dark" onClick={(event) => this.onCustomActivityAdd(event, selectedSection.id)}>Add custom activity for section {selectedSection.name}</Button>
                  </div>
                }
                <FooterButton
                  isDisabled={isDisabled}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  previousPagePath={"booking-scenarios"}
                  nextPagePath={"local-laws"}
                  formPage={formPage}
                  step={step}
                />
              </div>
            </form>
          }
        </Loader>
      </div >
    );
  }
}

ActivitiesPage = reduxForm({
  form: 'ListPlaceStep3',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep3,
  onSubmit: updateStep3
})(ActivitiesPage);

const selector = formValueSelector("ListPlaceStep3");
const mapState = (state) => ({
  sectionsForListingActivities: selector(state, "sectionsForListingActivities"),
  sectionsForListingCustomActivities: selector(state, "sectionsForListingCustomActivities"),
});

const mapDispatch = {
  change,
};

export default compose(
  injectIntl,
  withStyles(s, bt),
  connect(mapState, mapDispatch),
  graphql(gql`
    query {
      getAllSectionsActivities {
        sections {
          id
          name
          image
          activities {
            id
            name
            description
          }
        }
      }
    }`,
    {
      name: 'getAllSectionsActivitiesResults',
      options: {
        fetchPolicy: 'network-only',
        ssr: false
      }
    }
  )
)(ActivitiesPage);