import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './CurrencyManagement.css';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';

// Redux Actions
import { updateCurrencyStatus, setBaseCurrency } from '../../../actions/siteadmin/CurrencyManagement/currencyManagement';
import { managePaymentCurrency } from '../../../actions/siteadmin/CurrencyManagement/managePaymentCurrency';

class CurrencyManagement extends React.Component {

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      symbol: PropTypes.string.isRequired,
      isEnable: PropTypes.bool.isRequired,
      isPayment: PropTypes.bool.isRequired,
      isBaseCurrency: PropTypes.bool.isRequired
    })),
    updateCurrencyStatus: PropTypes.any.isRequired,
    setBaseCurrency: PropTypes.any.isRequired,
    managePaymentCurrency: PropTypes.any.isRequired,
    title: PropTypes.string,
  };

  static defaultProps = {
    data: []
  };

  handleUpdateStatus(id, status) {
    const { updateCurrencyStatus } = this.props;
    updateCurrencyStatus(id, status);
  }

  handleBaseCurrency(id) {
    const { setBaseCurrency } = this.props;
    setBaseCurrency(id);
  }

  render() {
    const { data, managePaymentCurrency } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <div>
          <h1 className={s.headerTitle}><FormattedMessage {...messages.currencyManagement} /></h1>
          <div className={cx('table-responsive', 'tableBorderRadiusAdmin', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{formatMessage(messages.idLabel)}</th>
                  <th scope="col">{formatMessage(messages.symbolLabel)}</th>
                  <th scope="col">{formatMessage(messages.baseCurrencyLabel)}</th>
                  <th scope="col">{formatMessage(messages.status)}</th>
                  <th scope="col">{formatMessage(messages.setCurrencyLabel)}</th>
                  <th scope="col">{formatMessage(messages.allowedPaymentCurrency)}</th>
                </tr>
              </thead>
              <tbody>
                {
                  data && data.length > 0 ?
                  data.map((value, key) => {
                    return (
                      <tr key={key}>
                        <td>{value.id}</td>
                        <td>{value.symbol}</td>
                        <td>{value.isBaseCurrency === true ? formatMessage(messages.active) : ""}</td>
                        <td>
                          <select value={value.isEnable} onChange={(e) => this.handleUpdateStatus(value.id, value.isEnable)}>
                            <option value={true}>{formatMessage(messages.enabledLabel)}</option>
                            <option value={false}>{formatMessage(messages.disabledLabel)}</option>
                          </select>
                        </td>
                        <td>
                          <span>
                            {
                              !value.isBaseCurrency && value.isEnable && <a href="#" onClick={() => this.handleBaseCurrency(value.id)}>
                                <FormattedMessage {...messages.setBaseCurrency} />
                              </a>
                            }
                          </span>
                        </td>

                        <td>
                          <span>
                            {
                              !value.isPayment && value.isEnable && <a href="#" onClick={() => managePaymentCurrency(value.id, "add")}>
                                <FormattedMessage {...messages.addLabel} />
                              </a>
                            }
                            {
                              value.isPayment && value.isEnable && <a href="#" onClick={() => managePaymentCurrency(value.id, "remove")}>
                                <FormattedMessage {...messages.remove} />
                              </a>
                            }
                          </span>
                        </td>
                      </tr>
                    )
                  })
                  : (
                    <tr><td colSpan="6">{formatMessage(messages.noRecordFound)}</td></tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

}

const mapState = (state) => ({});

const mapDispatch = {
  updateCurrencyStatus,
  setBaseCurrency,
  managePaymentCurrency
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(CurrencyManagement)));