const errorMessage = async (errorCode, requestContent) => {

  const message = {
    success: 'Success!',
    error: 'Error!',
    warning: 'Warning!',
    authUser: 'The user is not authenticated.',
    bannerSetting: 'Banner caption changes are updated!',
    docVerify: 'Documents status has been changed!',
    cancelInfo: 'Cancellation info changes are updated!',
    addWishlist: 'Wishlist has been added successfully.',
    updateWishList: 'Wishlist has been updated successfully,',
    cancelInfoError: 'Cancellation info changes failed',
    bannerSettingError: 'Updating Banner Settings failed',
    verifyPhoneNumber: 'Phone number has been verified successfully.',
    validateNumber: 'We were unable to validate your phone number. Please try again',
    isListingAvailable: 'Listing is not available',
    cardError: 'Sorry, something went wrong with your card. Please try again.',
    requestError: 'Invalid request',
    birthDayField: 'Birth day field is missing',
    birthYearField: 'Birth year field is missing',
    birthMonthField: 'Birth month field is missing',
    noRecord: 'No records found.',
    createFailed: 'Failed to create',
    addPopularLocation: 'Location Successfully added!',
    invalidUrl: 'The page URL already exist!',
    addBlogDetails: 'Content page has been created successfully!',
    updateBlogDetails: 'Content page has been updated successfully!',
    isUpdated: 'Not updated',
    zipcode: 'Your Zip code is incomplete.',
    updateConfigSettings: 'Configurations has been updated successfully!',
    inActivePayment: "You can't deactivate this payment type as it's currently in use.",
    isAllow: 'At least one option must be active.',
    getUser: 'No account exists for the given email address.',
    userBanStatus: 'Oops! It looks like your account is disabled at the moment. Please contact our support.',
    isExistAdminRole: 'Sorry, unable to find this admin role.',
    adminUser: 'Oops! Unable to delete the admin role because it is currently in use by some admin user.',
    updateReview: "Oops! Something went wrong. The review couldn't be updated. Please try again later.",
    findActiveReservation: 'Oops! Unable to delete this user. It seems the user is having active/upcoming bookings and they have to cancel or complete the bookings in order to delete them.',
    adminLogin: 'Oops! Please login as an admin user and try again.',
    fetchRecord: "Something went wrong on fetching records.Please try again.",
    loginError: 'You are not logged in, please login and try again!',
    createListPhoto: 'Your list photo is uploaded successfully!',
    removeListPhoto: 'You cannot remove photos as you have upcoming bookings or enquiries for this listing.!',
    resendVerifyEmail: 'Confirmation link has been sent to your email.',
    resentEmailError: 'Failed to sent email. Please try again later',
    upcomingBooking: 'Sorry, you cannot delete this property, it has upcoming bookings or enquiries.',
    invalidAddress: 'Your address seems to be invalid, please go back to edit your address!',
    holdPayoutSuccess: 'Hold payout status has been updated successfully.',
    holdPayoutError: 'Failed to change hold payout status',
    commonError: requestContent || 'Something went wrong.Please try again later',
    paypalError: "The currency you've chosen is not supported by PayPal for payment. Please change the currency and try making the payment once more.",
    forgotPasswordLink: 'A link to reset your password has been sent to your email.',
    chageForgotPassword: 'Your password has been changed. You can now try logging in.',
    deleteCalendar: 'You calendar has been deleted succesfully!',
    deleteCalendarError: 'Your calendar is not deleted! Please try again!',
    importCalendar: 'Calendar added succesfully!.Calendar sync process has started!',
    existImportCalendar: 'Your calendar is already imported!',
    invalidCalendar: 'Invalid calendar! The URL is incorrect, and the calendar import has failed.',
    unPublish: 'You cannot unpublish as you have upcoming bookings or enquiries for this listing.',
    publishSuccess: `Your listing has been ${requestContent == 'Publish Listing' ? 'published' : 'unpublished'} successfully.`,
    createListing: 'Your Listing has been submitted for Approval',
    actionFailed: ' Failed action!',
    listApprovalStatus: 'The Listing review request has been declined successfully.',
    listingApprove: 'Listing is approved successfully!',
    contactHostError: 'You have already requested on this date',
    contactHost: 'You request has been sent!',
    sendMessageError1: 'Oops! you have already performed this action!',
    sendMessageError2: 'Oops! you have already approved this for other use',
    cancelReservation: 'Reservation cancelled successfully',
    cancelReservError: 'It looks like your reservation is already updated!',
    paymentToHost: 'Payment transferred to host successfully!',
    paymentToHostError: 'Payment to the host is failed, please try again with different currency',
    refundToGuest: 'Payment transferred to Guest successfully!',
    refundToGuestError: 'Payment to the guest is failed, please try again with different currency',
    updateServiceFee: 'Service fee has been updated successfully!',
    updateServiceFeeError: 'The service fee update has failed..',
    deleteSuccess: 'Deleted successfully.',
    deleteFailed: 'Deletion Failed!',
    updateStatus: 'Status has changed',
    updateFailed: 'Failed to change status',
    updatePopularLoc: 'Location status has changed',
    updateLocationError: 'Failed to change Location status',
    catchError: `Something went wrong! ${requestContent}`,
    updateWhyHost: 'Why host page changes are updated!',
    deleteListSetting: 'Setting is deleted successfully',
    deleteSettingError1: 'Unable to delete the list setting. At least, one list setting should be available for the properties.',
    deleteSettingError2: 'Unable to delete the list setting. The list setting is using on the property. Please remove the list setting on the properties and try again.',
    addBanner: 'Image Upload successfully!',
    updateBanner: 'Changes are updated successfully!',
    updateBanStatus: 'Ban Status updated successfully',
    updateBanError: 'Failed to update ban status.',
    updateBanWarning: 'You have to select either one option ban/unban',
    paymentGateway: 'Status has been updated successfully!',
    updateStaticPage: 'Static Content page has been updated successfully!',
    updateStaticPageError: 'Static Content page has been updating failed',
    deleteUser: 'User deleted successfully',
    deleteReview: 'Review deleted successfully',
    updateReview: 'Review has been updated successfully!',
    deleteReviewError: 'Review deletion Failed',
    addAdminRole: `Admin Role has been ${requestContent} successfully!`,
    deleteAdminRole: 'Admin Role has been deleted successfully',
    deleteAdminUser: 'Admin User has been deleted successfully',
    updatePolicy: 'Policy has been updated successfully!',
    disableBaseCurrecy: "Sorry, you can't disable the base currency. Try to set a different base currency and disable this one",
    currencyStatus: 'Currency status has been changed!',
    currencyStatusError: 'Failed to change Currency status',
    baseCurrency: 'Base currency has been set successfully.',
    baseCurrencyError: 'Failed to set Base Currency',
    PaymentCurrency: 'Payment Currency changes are done',
    PaymentCurrencyError: 'Failed to change Payment Currency status',
    addRecommend: 'Listing added to Recommended list',
    removeRecommend: 'Listing removed from Recommended list',
    fileTypeError: 'You are trying to upload invalid image file. Please upload PNG, JPG & JPEG format image file.',
    limitError: 'Maximum upload size Exceeded!.Try with smallest size image',
    isAdmin: "You are logged in as admin, you can't perform this action!",
    updateFaq: 'FAQ changes are updated!',
    updateFaqError: 'Failed to update FAQ changes.',
    updateWhyHostPage: 'Why host page quote section changes are updated!',
    updateWhyHostError: 'Updating Why Become Host Settings failed',
    updateHostPage: 'Why host page banner changes are updated!',
    updateHostPageError: 'Why host page banner changes failed!',
    updateBlock4: 'Why become host block 4 settings are updated!',
    updateBlockError: 'Why become host block 4 settings update failed',
    updateBlock3: 'Why become host block 3 settings are updated!',
    updateBlockError3: 'Why become host block 3 settings update failed!',
    uploadImage: 'Please upload the image!',
    updateBlock1: 'Why become host block 1 settings are updated!',
    updateBlockError1: 'Why become host block 1 settings update failed!',
    approved: 'You have already approved',
    writeUserReview: `User review details ${requestContent} successfully!`,
    userReviewError: 'Your changes to admin review is failed!',
    invalidId: 'List ID is not available',
    uploadBlockImage: 'Please upload block image!',
    updateStaticBlock: 'Static block settings changes are updated',
    updateStaticError: 'Static info block changes failed!',
    updateSiteSettings: 'Site settings changes are updated!',
    updateSettingsError: 'Site settings changes update failed!',
    updateSearchSettings: 'Search settings changes are updated!',
    searchSettingError: 'Updating serach settings is failed',
    updatePayment: 'Payment settings are updated!',
    paymentSettingError: 'Update payment setting is failed',
    updateListSettings: 'The list setting has been updated successfully!',
    disableListSettings: 'Unable to disable the list setting. At least, one list setting should be active.',
    listingUsed: 'Unable to disable the list setting. The list setting is using on the property. Please remove the list setting on the properties and try again.',
    addListSettings: 'List Setting is created successfully!',
    addListingError: 'Failed to create list setting.',
    commandReason: 'Please comment a valid reason.',
    alreadyApprove: 'You have already approved',
    footerSetting: 'Footer setting changes are updated!',
    footerSettingError: 'Footer settings changes failed.',
    updateUser: 'Profile changes are updated',
    addContent: 'Please add content',
    addTitle: 'Please add meta title',
    addDescription: 'Please add meta Description',
    uploadLocImage: 'Please upload location image',
    updateLocation: 'Location changes are updated!',
    updateLocationError: 'Updating location failed',
    adminRemoveListing: 'Listing removed successfully',
    sendEmail: 'Your email has been sent.',
    listSettingsData: 'Oops! Unable to find the list settings. Please try again.',
    cancelBooking: 'You have already canceled the booking.',
    checkAvailableDates: 'The dates are not available!',
    isListingAvailable: 'The listing had unpublished or deleted by Host/Admin. Please contact admin for more info.',
    checkUser: 'Oops! The user is Different',
    updateCancelPolicy: 'Something went wrong! Could not update!',
    existEmail: 'Oops! this email address is already exist.',
    invalidRoleId: 'Oops, it looks like the chosen role is not valid. Please try with different valid role.',
    passwordMismatch: 'Password mismatch.Please try again.',
    changePassword: 'Password has been successfully updated!',
    wrongPassword: 'Current password is wrong, try again!',
    wrongConfirmPassword: 'Confirm password is mismatch with new password!',
    invalidSpecPrice: 'Special price is invalid',
    removeDocList: 'Document has been removed successfully!',
    uploadDocument: 'Your document has been uploaded successfully!',
    missingField: 'It seems you have missed required fields. Please fill them.',
    ageLimit: 'Sorry, you must be 18 years old',
    invalidDob: 'Invalid date of birth, please fill the valid data',
    changeAdminUser: 'Admin access details changed successfully!',
    changeUserError: 'Your changes to admin access details has failed!',
    updateProfile: 'Your profile has been updated successfully',
    invalidEmail: 'Email already exist, please try another email address!',
    writeAdminReview: `Admin review details ${requestContent ? 'updated' : 'submitted'} successfully!`,
    isPublished: 'Oops! you could not recommend the unpublished list',
    sendSMTPEmail: 'Oops! Something went wrong. Unable to send the email.',
    stripeTokenError: 'Oops! Unable to proceed the requested action. Please try again.',
    otpError: 'Please try again after 2 minutes to receive a new OTP.',
    pageReload: 'Something went wrong, reload the page and try again',
    userExist: 'Sorry, user does not exist in the database!',
    removeFiles: 'SuccessFully files were removed',
    removeFilesError: 'Files not deleted',
    templateMissing: 'Receipt address or template type is required',
    existFileName: 'Filename not exist',
    paymentCompleted: 'For this booking, payment is already completed',
    addLanguageSuccess: 'The language is successfully added.',
    addLanguageError: 'Error in adding the language.',
    updateLanguageSuccess: 'The language is successfully updated.',
    updateLanguageError: 'Error in updating the language.',
    deleteLanguageSuccess: 'The language is successfully deleted.',
    deleteLanguageError: 'Error in deleting the language.',
    language_already_exists: 'Error, this language already exists.',
  }
  return message[errorCode]

}
export default errorMessage;