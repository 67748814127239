import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DayPickerRangeController, DayPickerSingleDateController, isInclusivelyAfterDay } from 'react-dates';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import 'react-dates/initialize';

import CustomizableCalendarDay from './CustomizableCalendarDay';

import messages from '../../../locale/messages';
import { checkAvailability } from '../../../actions/checkAvailability';
import { getBlockedDates } from '../../../actions/Listing/getBlockedDates';
import { getSpecialPricingData } from '../../../actions/Listing/getSpecialPricingData';
import { isRTL } from '../../../helpers/formatLocale';
import { getDateUsingTimeZone } from '../../../helpers/dateRange';

import { getAvailableMonthsForMonthlyRental } from '../../../actions/getMonthsForRental';

import S from '!isomorphic-style-loader!css-loader!react-dates/lib/css/_datepicker.css';
import s from './AvailabilityCalendar.css';

class AvailabilityCalendar extends React.Component {
  static propTypes = {
    listId: PropTypes.number.isRequired,
    blockedDates: PropTypes.array,
    country: PropTypes.string,
    getAvailableMonthsForMonthlyRental: PropTypes.any.isRequired,
  };

  static defaultProps = {
    blockedDates: [],
    listId: null,
    maxDaysNotice: 'unavailable',
    autoFocusEndDate: false,
    showInputs: false,
    keepOpenOnDateSelect: false,
    initialVisibleMonth: null,
    hideKeyboardShortcutsPanel: true,
    noBorder: true,
    startDateOffset: undefined,
    endDateOffset: undefined,
    renderCalendarDay: undefined,
    renderDayContents: null,
    minimumNights: 1,
    country: '',
    bookingEditModal: false
  }

  constructor(props) {
    super(props);
    this.state = {
      blockedDatesSet: new Set(),
    };
  }

  componentDidMount() {
    const { isFullDayBlock } = this.props;
    const blockedDatesSet = new Set();
    isFullDayBlock?.forEach(day => {
      if (day?.calendarStatus != 'available') {
        blockedDatesSet?.add(moment(day?.blockedDates).format('YYYY-MM-DD'));
      }
    });
    this.setState({ blockedDatesSet });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isFullDayBlock } = nextProps;
    const { blockedDatesSet } = this.state;
    isFullDayBlock?.forEach(day => {
      if (day.calendarStatus != 'available') {
        blockedDatesSet?.add(moment(day?.blockedDates).format('YYYY-MM-DD'));
      }
    });
    this.setState({ blockedDatesSet });
  }

  isDayBlocked = (day) => {
    const { blockedDatesSet } = this.state;
    if (blockedDatesSet) {
      return blockedDatesSet.has(moment(day).format('YYYY-MM-DD'));
    }
    else {
      return null;
    }
  }

  onNextMonthChange = async (e) => {
    const { getBlockedDates, listId, country } = this.props;
    const { blockedDatesSet } = this.state;
    let today, navigationDate, monthsDiff;
    today = getDateUsingTimeZone(country, true);
    navigationDate = moment(e).format('YYYY-MM-DD');
    monthsDiff = moment(navigationDate).diff(moment(today), 'months');
    if (monthsDiff && monthsDiff > 0) {
      let isRangedMonth = Number(monthsDiff) % 5;
      if (isRangedMonth === 0) {
        const { data } = await getBlockedDates(listId, moment(navigationDate).startOf('month').format('YYYY-MM-DD'));
        if (data?.getBlockedDatesCalendar?.length > 0) {
          data?.getBlockedDatesCalendar?.forEach(day => {
            blockedDatesSet?.add(moment(day.blockedDates).format('YYYY-MM-DD'));
          });
          this.setState({ blockedDatesSet });
        }
      }
    }
  }

  onPrevMonthChange = async (e) => {
    const { getBlockedDates, listId, country } = this.props;
    const { blockedDatesSet } = this.state;
    let today = getDateUsingTimeZone(country, true);
    let navigationDate = moment(e).format('YYYY-MM-DD');
    let monthsDiff = moment(navigationDate).diff(moment(today), 'months');
    let filterDate = moment(e).add(-5, 'months');

    if (monthsDiff && monthsDiff > 0) {
      let isRangedMonth = Number(monthsDiff) % 4;
      if (isRangedMonth === 0) {
        const { data } = await getBlockedDates(listId, moment(filterDate).startOf('month').format('YYYY-MM-DD'));
        if (data?.getBlockedDatesCalendar?.length > 0) {
          data?.getBlockedDatesCalendar?.forEach(day => {
            blockedDatesSet?.add(moment(day?.blockedDates).format('YYYY-MM-DD'));
          });
          this.setState({ blockedDatesSet });
        }
      }
    }
  }

  render() {
    const { 
      blockedDates, country, listingData, locale, bookingEditModal, monthlyRentalToggled, onCalendarDatesChange,
      startDate, endDate, calendarFocusedInput, calendarMonthsToDisplay, change,
     } = this.props;

    let today, breakPoint, minNight, maxNight, maxDaysNotice, condition, minMonthlyRental, maxMonthlyRental;
    today = getDateUsingTimeZone(country, false), condition;
    breakPoint = getDateUsingTimeZone(country, false);
    minNight = listingData?.minNight;
    maxNight = listingData?.maxNight;
    maxDaysNotice = listingData?.maxDaysNotice;
    minMonthlyRental = listingData?.minMonthlyRental;
    maxMonthlyRental = listingData?.maxMonthlyRental;

    if (maxDaysNotice === 'unavailable') {
      condition = day =>
        !isInclusivelyAfterDay(day, today) ||
        isInclusivelyAfterDay(day, today)
    } else {

      if (maxDaysNotice === '3months') {
        breakPoint.add(3, 'months');
      } else if (maxDaysNotice === '6months') {
        breakPoint.add(6, 'months');
      } else if (maxDaysNotice === '9months') {
        breakPoint.add(9, 'months');
      } else if (maxDaysNotice === '12months') {
        breakPoint.add(12, 'months');
      }

      if (maxDaysNotice !== 'available') {
        condition = day =>
          !isInclusivelyAfterDay(day, today) ||
          isInclusivelyAfterDay(day, breakPoint)
      } else if (maxDaysNotice == 'available') {
        condition = day => !isInclusivelyAfterDay(day, today)
      }
    }

    return (
      <div
        className={cx(s.pageContent, `${bookingEditModal ? 'paymentCalendarHeight' : ''}`)}
        style={{ textAlign: (bookingEditModal && (1 === calendarMonthsToDisplay)) ? '-webkit-center' : '' }}
      >
        <div className={cx(s.sectionContainer, s.boxContainer, s.listOneLeft, s.noPadddingLeftRight, { [s.borderTop]: bookingEditModal })}>
          {
            !monthlyRentalToggled ?
            <>
              {
                !bookingEditModal &&
                <h1 className={cx(s.titleText, s.space2, 'textWhite')}>
                  <FormattedMessage {...messages.nightly_rental} />
                </h1>
              }
              {
                minNight != null && minNight > 0 &&
                <p className={cx({ [s.subTitle]: bookingEditModal }, s.displayInline)}><span className={cx(s.text)}> <strong>{minNight} {minNight > 1 ? <FormattedMessage {...messages.nights} /> : <FormattedMessage {...messages.night} />}{' '}</strong>
                  <FormattedMessage {...messages.minimumStayNotReached} />
                </span>
                </p>
              }
              {
                maxNight != null && maxNight > 0 &&
                <div className={s.displayInline}>
                  <span className={s.dotsCss}></span>
                  <p className={cx({ [s.subTitle]: bookingEditModal }, s.displayInline)}><span className={cx(s.text)}> <strong>{maxNight} {maxNight > 1 ? <FormattedMessage {...messages.nights} /> : <FormattedMessage {...messages.night} />}{' '}</strong>
                    <FormattedMessage {...messages.maximumNightStay} />
                  </span>
                  </p>
                </div>
              }
            </>
            : (
            <>
              {
                !bookingEditModal &&
                <h1 className={cx(s.titleText, s.space2, 'textWhite')}>
                  <FormattedMessage {...messages.monthly_rental} />
                </h1>
              }
              {
                minMonthlyRental != null && minMonthlyRental > 0 &&
                <p className={cx({ [s.subTitle]: bookingEditModal }, s.displayInline)}><span className={cx(s.text)}> <strong>{minMonthlyRental} {minMonthlyRental > 1 ? <FormattedMessage {...messages.perMonth} /> : <FormattedMessage {...messages.perMonth} />}{' '}</strong>
                  <FormattedMessage {...messages.minimumStayNotReached} />
                </span>
                </p>
              }
              {
                maxMonthlyRental != null && maxMonthlyRental > 0 &&
                <div className={s.displayInline}>
                  <span className={s.dotsCss}></span>
                  <p className={cx({ [s.subTitle]: bookingEditModal }, s.displayInline)}><span className={cx(s.text)}> <strong>{maxMonthlyRental} {maxMonthlyRental > 1 ? <FormattedMessage {...messages.perMonth} /> : <FormattedMessage {...messages.perMonth} />}{' '}</strong>
                    <FormattedMessage {...messages.maximumNightStay} />
                  </span>
                  </p>
                </div>
              }
            </>
            )
          }
          <div className={cx(s.calendarContainer, 'availabilityCalendar', 'calendarContainerDark')}>
            {
              monthlyRentalToggled ?
                <DayPickerSingleDateController
                  focused={('startDate' === calendarFocusedInput)}
                  date={startDate ? moment(startDate) : null}

                  onDateChange={async (startDate) =>
                    await onCalendarDatesChange({ type: 'single', startDate })
                  }

                  onFocusChange={async (focusedInput) => 
                    await change('BookingForm', 'calendarFocusedInput', focusedInput ? 'startDate' : 'endDate')
                  }

                  numberOfMonths={calendarMonthsToDisplay}
                  isOutsideRange={condition}
                  daySize={40}
                  renderCalendarDay={props => <CustomizableCalendarDay {...props} blockedDates={blockedDates} />}
                  onPrevMonthClick={(props) => { this.onPrevMonthChange(props) }}
                  onNextMonthClick={(props) => { this.onNextMonthChange(props) }}
                  transitionDuration={0}
                  isRTL={isRTL(locale)}
                  isDayBlocked={this.isDayBlocked}
                />
                :
                <DayPickerRangeController
                  focusedInput={calendarFocusedInput ?? 'startDate'}
                  startDate={startDate ? moment(startDate) : null}
                  endDate={endDate ? moment(endDate) : null}

                  onDatesChange={async ({ startDate, endDate }) =>
                    await onCalendarDatesChange({
                      type: 'range', startDate, endDate, 
                      maxNight: listingData.maxNight, 
                      minNight: listingData.minNight 
                  })}

                  onFocusChange={async (focusedInput) =>
                    await change('BookingForm', 'calendarFocusedInput', focusedInput)
                  }

                  numberOfMonths={calendarMonthsToDisplay}
                  isOutsideRange={condition}
                  daySize={40}
                  minimumNights={minNight > 0 ? minNight : 1}
                  renderCalendarDay={props => <CustomizableCalendarDay {...props} blockedDates={blockedDates} />}
                  onPrevMonthClick={(props) => { this.onPrevMonthChange(props) }}
                  onNextMonthClick={(props) => { this.onNextMonthChange(props) }}
                  transitionDuration={0}
                  isRTL={isRTL(locale)}
                  isDayBlocked={this.isDayBlocked}
                />
            }
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector('BookingForm');
const mapState = state => ({
  calendarFocusedInput: selector(state, 'calendarFocusedInput'),
  locale: state?.intl?.locale,
  isFullDayBlock: state?.viewListing?.isFullDayBlock
});

const mapDispatch = {
  change,
  checkAvailability,
  getBlockedDates,
  getSpecialPricingData,
  getAvailableMonthsForMonthlyRental,
};

export default injectIntl(withStyles(s, S)(connect(mapState, mapDispatch)(AvailabilityCalendar)));
