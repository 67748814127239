import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { graphql, gql, compose } from 'react-apollo';
import moment from 'moment';
import Confirm from 'react-confirm-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Link from '../../../components/Link';
import messages from '../../../locale/messages';
import { FormattedMessage, injectIntl } from 'react-intl';
import { deleteUser } from '../../../actions/siteadmin/users';
import { updateBanServiceHistoryStatus } from '../../../actions/siteadmin/updateBanServiceHistoryStatus';
import s from './UserManagement.css';
import bt from '../../../components/commonStyle.css';
import CustomPagination from '../../CustomPagination';
import usersQuery from './usersQuery.graphql';
import { 
  Form, 
} from 'react-bootstrap';

//Images
import ExportImage from '../../../../public/adminIcons/export.png';
import { debounce } from '../../../helpers/debounce';

class UserManagement extends React.Component {
  static propTypes = {
    userManagement: PropTypes.any,
    editUser: PropTypes.any,
    deleteUser: PropTypes.any,
    title: PropTypes.string,
    updateBanServiceHistoryStatus: PropTypes.any.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      typing: false,
      typingTimeout: 0
    }
  }

  handleChange = async (e, userId, userMail, userName) => {
    const { updateBanServiceHistoryStatus } = this.props;
    const { currentPage, searchList } = this.props;
    let id = userId;
    let banStatus = e.target.value;
    await updateBanServiceHistoryStatus(id, banStatus, userMail, userName, currentPage, searchList);
  }

  handleDropDown = (e) => {
    const { userManagement: { refetch }, setStateVariable } = this.props
    let variables = {
      userType: e.target.value,
      currentPage: 1
    };
    setStateVariable({ userType: e.target.value, currentPage: 1 });
    refetch(variables)
  }

  paginationData = (currentPage) => {
    const { userManagement: { refetch }, setStateVariable } = this.props;
    let variables = { currentPage };
    setStateVariable({ currentPage });
    refetch(variables);
  }

  handleSearchChange = (searchList) => {
    const { userManagement: { refetch }, setStateVariable, userType } = this.props;
    let variables = {
      currentPage: 1,
      searchList,
      userType
    };
    setStateVariable(variables);
    refetch(variables);
  }

  deleteChange = async (id, profileId, userTypes) => {
    const { deleteUser, setStateVariable } = this.props;
    const { userManagement: { refetch } } = this.props;
    let variables = { currentPage: 1 };
    await deleteUser(id, profileId, userTypes);
    await setStateVariable({ currentPage: 1 });
    await refetch(variables);
  }

  render() {
    const { userManagement: { loading, userManagement } } = this.props;
    const { currentPage, searchList, userType } = this.props;
    let userTypes = 'admin';
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <div>
          <h1 className={s.headerTitle}><FormattedMessage {...messages.userManagement} /></h1>
          <div className={cx(s.exportSection, 'bgBlack')}>
            <div>
              <Form.Group className={s.noMargin}>
                <Form.Control
                  type="text"
                  placeholder={formatMessage(messages.search)}
                  onChange={(e) => debounce(this.handleSearchChange(e?.target?.value))}
                  className={cx('searchInputControl', 'searchInputControlAR')}
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group controlId="formControlsSelect" className={s.noMargin}>
                <Form.Select onChange={(e) => this.handleDropDown(e)} value={userType} placeholder={formatMessage(messages.selectLabel)} className={cx(bt.commonControlSelect, s.userSelect, 'rtlUserSelect')}>
                  <option value="">{formatMessage(messages.allLabel)}</option>
                  <option value="1">{formatMessage(messages.guestCapcity)}</option>
                  <option value="2">{formatMessage(messages.host)}</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div>
              {
                userManagement && userManagement.usersData && userManagement.usersData.length > 0 && <a
                  href={"/export-admin-data?type=users&usertype=" + userType + "&keyword=" + searchList}
                  className={cx(s.exportText, 'commonFloatLeft', 'textWhite')}>
                  <span className={s.vtrMiddle}><FormattedMessage {...messages.exportDataIntoCSV} /></span>
                  <span className={cx(s.exportLinkImg, 'exportLinkImgCommon')}>
                    <img src={ExportImage} className={s.exportImg} />
                  </span>
                </a>
              }
            </div>
          </div>
          <div className={cx('table-responsive', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{formatMessage(messages.profileID)}</th>
                  <th scope="col">{formatMessage(messages.firstName)}</th>
                  <th scope="col">{formatMessage(messages.lastName)}</th>
                  <th scope="col">{formatMessage(messages.email)}</th>
                  <th scope="col">{formatMessage(messages.phoneNumber)}</th>
                  <th scope="col">{formatMessage(messages.createdDate)}</th>
                  <th scope="col">{formatMessage(messages.viewLabel)}</th>
                  <th scope="col">{formatMessage(messages.actionLabel)}</th>
                  <th scope="col">{formatMessage(messages.delete)}</th>
                </tr>
              </thead>
              <tbody>
                {
                  userManagement && userManagement.usersData.length > 0 ?
                  userManagement.usersData.map((value, key) => {
                    let banStatus = value.userBanStatus;
                    let recordId = value.id;
                    let userMail = value.email;
                    let userName = value.profile && value.profile.firstName + ' ' + value.profile.lastName;
                    if (banStatus === 1) {
                      banStatus = "1";
                    } else if (banStatus === 0) {
                      banStatus = "0";
                    }
                    return (
                      <tr key={key}>
                        <td className={s.userVerticalAlign}>{value.profile && value.profile.profileId}</td>
                        <td className={s.userVerticalAlign}>{value.profile && value.profile.firstName}</td>
                        <td className={s.userVerticalAlign}>{value.profile && value.profile.lastName}</td>
                        <td className={s.userVerticalAlign}>{value.email}</td>
                        <td className={s.userVerticalAlign}>{value.profile && value.profile.phoneNumber}</td>
                        <td className={s.userVerticalAlign}>{moment(value.profile && value.profile.createdAt).format('MM/DD/YYYY')}</td>
                        <td className={s.userVerticalAlign}>
                          <Link to={"/siteadmin/profileView/" + ((value.profile) ? value.profile.profileId : '')}>
                            <FormattedMessage {...messages.viewLabel} />
                          </Link>
                        </td>
                        {
                          <td>
                            <select name="userBanStatus" className={cx(bt.commonControlSelect, s.userVerticalAlign, s.btnMarginBottom)} onChange={(e) => this.handleChange(e, recordId, userMail, userName)} value={banStatus}>
                              <option value="">{formatMessage(messages.selectLabel)}</option>
                              <option value="1">{formatMessage(messages.banLabel)}</option>
                              <option value="0">{formatMessage(messages.unBanLabel)}</option>
                            </select>
                          </td>
                        }
                        <td>
                          <div>
                            <Confirm
                              onConfirm={() => this.deleteChange(value.id, value.profile && value.profile.profileId, userTypes)}
                              body={formatMessage(messages.areYouSureDeleteWishList)}
                              confirmText={formatMessage(messages.confirmDelete)}
                              cancelText={formatMessage(messages.cancel)}
                              title={formatMessage(messages.deletingUser)}>
                              <a href="#"><FormattedMessage {...messages.delete} /></a>
                            </Confirm>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                  : (
                    <tr><td colSpan="9">{formatMessage(messages.noRecordFound)}</td></tr>
                  )
                }
              </tbody>
            </table>
          </div>
          {
            userManagement && userManagement.usersData && userManagement.usersData.length > 0
            && <div>
              <CustomPagination
                total={userManagement.count}
                currentPage={currentPage}
                defaultCurrent={1}
                defaultPageSize={10}
                change={this.paginationData}
                paginationLabel={formatMessage(messages.usersLabel)}
                isScroll
              />
            </div>
          }
        </div>
      </div>
    );
  }
}
const mapState = (state) => ({
});
const mapDispatch = {
  updateBanServiceHistoryStatus,
  deleteUser
};
export default compose(
  injectIntl,
  withStyles(s, bt),
  connect(mapState, mapDispatch),
  graphql(usersQuery, {
    name: 'userManagement',
    options: (props) => ({
      variables: {
        currentPage: props.currentPage,
        searchList: props.searchList,
        userType: props.userType
      },
      fetchPolicy: 'network-only',
    })
  })
)(UserManagement);