
import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import { formValueSelector, change } from "redux-form";
import { print } from 'graphql/language/printer';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './SectionsAndActivities.css';
import {
  Button,
  Collapse,
  FormCheck
} from 'react-bootstrap';
import { Field } from 'redux-form';
import cx from 'classnames';
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

import { setPersonalizedValues } from '../../../../../../actions/personalized';
import Loader from '../../../../../Loader/Loader';

import getAVSectionActivitiesQuery from '../../../../../AddedValues/getAVSectionActivities.graphql';

import messages from "../../../../../../locale/messages";

const sectionCollapsedDataCount = 2;

class SectionsAndActivities extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selectedSection: {},
    };

    this.renderSections = this.renderSections.bind(this);
    this.renderActivities = this.renderActivities.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { section, sections } = this.props;
    const { selectedSection } = this.state;

    if (section && !selectedSection.id) {
      if (sections.length > 0) {
        const selectedSectionObj = sections.find(sectionObj => sectionObj.name === section);
        if (selectedSectionObj) {
          this.getSectionActivities(selectedSectionObj.id);
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { section, sections} = nextProps;
    const { selectedSection } = this.state;

    if (!section && selectedSection.id) {
      this.setState({ selectedSection: {} });
    }

    if (section) {
      sections.map((sectionObj, index) => {
        if (sectionObj.name === section) {
          if (index >= sectionCollapsedDataCount) {
            this.setState({ isOpen: true });
            return true;
          }
        }
      });
    }
  }

  handleToggle() {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  renderActivities = ({ activities, input }) => {
    return (
      <div>
        {
          (activities && activities.length > 0) &&
          activities.map((activity) => this.renderRadioButtonItem('activity', activity, input))
        }
      </div>
    );
  }

  renderSections = ({ input, isOpen }) => {
    const { sections, section } = this.props;
    const { formatMessage } = this.props.intl;

    if (!sections || (sections.length <= 0)) {
      return null;
    }

    let collapsedArray = sections.slice(0, sectionCollapsedDataCount);
    let expandedArray = sections.slice(sectionCollapsedDataCount, sections.length);

    return (
      <div>
        {
          collapsedArray.map((section) => this.renderRadioButtonItem('section', section, input))
        }

        {
          (expandedArray && expandedArray.length > 0) &&
          <div className={cx(s.space2)}>

            <Collapse className={s.collapseContainer} in={isOpen}>
              <div>{expandedArray.map((section) => this.renderRadioButtonItem('section', section, input))}</div>
            </Collapse>

            <Button variant="link" type="button" onClick={() => this.handleToggle()} className={cx(s.btn, s.btnLink, s.btnLinkSecondary, s.toggleLink, s.captionTitle, s.filterToggleLink, s.dropdownOverflow, 'bgBlack', 'bgBlackHover')}>
              {
                isOpen ?
                  <span>{formatMessage(messages.header_more_filters_show_less_button_label)}<FaAngleUp /></span>
                  : <span>{formatMessage(messages.header_more_filters_show_more_button_label)}<FaAngleDown /></span>
              }
            </Button>
          </div>
        }
      </div>
    );
  }

  async getSectionActivities(sectionId) {
    const { setPersonalizedValues } = this.props;

    setPersonalizedValues({ name: 'loadingSectionActivities', value: true });

    const resp = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: print(getAVSectionActivitiesQuery),
        variables: {
          sectionId,
          filterByRegion: ''
        },
      }),
    });

    const { data } = await resp.json();
    if (data && data.getAVSectionActivities) {
      this.setState({ selectedSection: data.getAVSectionActivities });
    }

    setPersonalizedValues({ name: 'loadingSectionActivities', value: false });
  }

  renderRadioButtonItem = (type, item, input) => {
    const { change } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div key={item.id} className={cx(s.checkBoxContainer)}>
        <div className={cx(s.space2, s.displayFlex)}>
          <FormCheck
            type="radio"
            checked={input.value === item.name}
            name={input.name}
            value={item.name}
            onChange={() => {
              input.onChange(item.name);
              if ('section' === type) {
                change('SearchForm', 'activity', null);
                this.getSectionActivities(item.id);
              }
            }}
          />
          <span className={cx(s.captionTitle, s.textSection)}>{item.name}</span>
        </div>
      </div>
    )
  }

  render() {
    const { loadingSectionActivities, className } = this.props;
    const { isOpen, selectedSection } = this.state;

    return (
      <Loader type={"page"} show={loadingSectionActivities}>
        <div className={cx(className)}>
          <p className={cx(s.moreFilterTitle, s.space4, s.spaceTop4, s.textBold)}>
            Activity Sections
          </p>

          <div className={cx(s.displayTable, s.space2)}>
            <div className={cx(s.displayTableCell, s.fullWidth)}>
              <Field
                name="section"
                component={this.renderSections}
                isOpen={isOpen}
              />
            </div>
          </div>

          {
            selectedSection.id &&
            <div>
              <p className={cx(s.moreFilterTitle, s.space4, s.spaceTop4, s.textBold)}>
                {`Activities under the section ${selectedSection.name}`}
              </p>

              <div className={cx(s.displayTable, s.space2)}>
                <div className={cx(s.displayTableCell, s.fullWidth)}>
                  <Field
                    name="activity"
                    activities={selectedSection.activities}
                    component={this.renderActivities}
                  />
                </div>
              </div>

            </div>
          }
        </div>
      </Loader>
    );
  }
}

const selector = formValueSelector("SearchForm");
const mapState = (state) => ({
  section: selector(state, "section"),
  loadingSectionActivities: state.personalized.loadingSectionActivities,
});

const mapDispatch = {
  setPersonalizedValues,
  change,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(SectionsAndActivities)));
