import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Link from '../../Link/Link';
import ModalForm from './ModalForm';

import { openCancelModal } from '../../../actions/siteadmin/modalActions';
import messages from '../../../locale/messages';

import bt from '../../../components/commonStyle.css';
import s from './CancellationPolicyManagement.css';
class CancellationPolicyManagement extends React.Component {

  render() {
    const { data, openCancelModal } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <ModalForm />
        <>
          <h1 className={s.headerTitle}><FormattedMessage {...messages.cancellationPolicyManagement} /></h1>
          <Button onClick={openCancelModal} className={cx(bt.btnPrimary, bt.btnlarge, s.space3)}>
            <FormattedMessage {...messages.updateCancellationInfo} />
          </Button>
          <div className={cx('table-responsive', 'tableBorderRadiusAdmin', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{formatMessage(messages.idLabel)}</th>
                  <th scope="col">{formatMessage(messages.policyName)}</th>
                  <th scope="col">{formatMessage(messages.policyContent)}</th>
                  <th scope="col">{formatMessage(messages.editLabel)}</th>
                </tr>
              </thead>
              <tbody>
              {
                data && data.results && data.results.length > 0 ?
                data.results.map(function (value, key) {
                  return (
                    <tr key={key}>
                      <td>{value.id}</td>
                      <td>{value.policyName}</td>
                      <td>{value.policyContent}</td>
                      <td>
                        <Link to={"/siteadmin/cancellation-policies/edit/" + value.id}>
                          <FormattedMessage {...messages.editLabel} />
                        </Link>
                      </td>
                    </tr>
                  )
                })
                : (
                  <tr><td colSpan="4">{formatMessage(messages.noRecordFound)}</td></tr>
                )
              }
              </tbody>
            </table>
          </div>
        </>
      </div>
    );
  }
}

const mapState = (state) => ({
});

const mapDispatch = {
  openCancelModal
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(CancellationPolicyManagement)));