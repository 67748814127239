import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Field, reduxForm } from 'redux-form';
import submit from './submit';
import validate from './validate';
import { injectIntl } from 'react-intl';

// Locale
import messages from '../../locale/messages';
import PopulateData from '../../helpers/populateData';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './RegisterForm.css';
import bt from '../../components/commonStyle.css';
import {
  Button,
  Form,
} from 'react-bootstrap';

//Images
import ShowPassword from '../../../public/SiteIcons/pswVisible.svg';
import HidePassword from '../../../public/SiteIcons/pwdHidden.svg';

class RegisterForm extends Component {

  static propTypes = {
    formatMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: '',
      dateOfBirthData: {}
    };
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    let now = new Date();
    let currentYear = now.getFullYear();
    let years = PopulateData.generateData(1920, currentYear, "desc");
    let days = PopulateData.generateData(1, 31);
    let months = PopulateData.generateData(0, 11);
    this.setState({
      dateOfBirthData: {
        years: years,
        months: months,
        days: days
      }
    });
  }

  handleChange(fieldName) {
    this.setState({ showPassword: fieldName === this.state.showPassword ? "" : fieldName });
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, showPassword, placeHolder, maxlength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group className={cx(bt.space3, bt.pwdSection)}>
        <label className={s.loginLabel}>{label}</label>
        <Form.Control {...input} placeholder={placeHolder ? placeHolder : label} type={showPassword === input.name ? input : type} className={className} maxLength={maxlength} />
        {type == 'password' && <span className={cx(bt.pwdImage, bt.loginPwdSection, 'svgImg', 'pwdImageRTL')} onClick={() => this.handleChange(input.name)}>
          {showPassword === input.name ? <img src={ShowPassword} /> : <img src={HidePassword} />}
        </span>}
        {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
      </Form.Group>
    )
  }

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    return (
      <Form.Group className={cx(bt.space3, bt.pwdSection)}>

        <Form.Select {...input} className={className}>
          {children}
        </Form.Select>
      </Form.Group>
    )
  }

  render() {
    const { error, handleSubmit, submitting, dispatch } = this.props;
    const { formatMessage } = this.props.intl;
    const { dateOfBirthData } = this.state;

    return (
      <form onSubmit={handleSubmit(submit)} className={cx('SelectFocus', 'inputDateHide')}>
        {error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
        <Field
          name="firstName"
          type="text"
          component={this.renderFormControl}
          label={formatMessage(messages.firstName)}
          className={bt.commonControlInput}
          maxLength={25}
        />
        <Field name="lastName"
          type="text"
          component={this.renderFormControl}
          label={formatMessage(messages.lastName)}
          className={bt.commonControlInput}
          maxLength={25}
        />
        <Field name="email"
          type="text"
          component={this.renderFormControl}
          label={formatMessage(messages.email)}
          className={bt.commonControlInput}
        />
        <Field name="password"
          type="password"
          component={this.renderFormControl}
          label={formatMessage(messages.password)}
          className={bt.commonControlInput}
          showPassword={this.state.showPassword}
          maxLength={25}
        />
        <div className={s.Birthpadding}>
          <label className={s.loginLabel}>{formatMessage(messages.birthDay)}</label>
          <div className={s.birthFlex}>
            <Field
              name="month"
              component={this.renderFormControlSelect}
              className={cx(s.birthForm, 'birthFormRtl', bt.commonControlSelect)}
            >
              <option value="">{formatMessage(messages.month)}</option>
              {
                dateOfBirthData && dateOfBirthData.months && dateOfBirthData.months.length > 0 && dateOfBirthData.months.map((item, key) => {
                  return (
                    <option key={key} value={item}>{item + 1}</option>
                  )
                })
              }
            </Field>

            <Field
              name="day"
              component={this.renderFormControlSelect}
              className={cx(s.birthForm, 'birthFormRtl', bt.commonControlSelect)}
            >
              <option value="">{formatMessage(messages.transferDate)}</option>
              {
                dateOfBirthData && dateOfBirthData.days && dateOfBirthData.days.length > 0 && dateOfBirthData.days.map((item, key) => {
                  return (
                    <option key={key} value={item}>{item}</option>
                  )
                })
              }
            </Field>

            <Field name="year" component={this.renderFormControlSelect} className={cx(s.birthForm, 'birthFormRtl', bt.commonControlSelect)}>
              <option value="">{formatMessage(messages.year)}</option>
              {
                dateOfBirthData && dateOfBirthData.years && dateOfBirthData.years.length > 0 && dateOfBirthData.years.map((item, key) => {
                  return (
                    <option key={key} value={item}>{item}</option>
                  )
                })
              }
            </Field>
          </div>
        </div>

        <Button
          className={cx(bt.btnPrimary, bt.btnBig, bt.space4, 'btn-block')}
          type="submit"
          disabled={submitting}
        >
          {formatMessage(messages.signUp)}
        </Button>
      </form>
    )
  }
}

RegisterForm = reduxForm({
  form: 'RegisterForm', // a unique name for this form
  validate
})(RegisterForm);

export default injectIntl(withStyles(s, bt)(RegisterForm));