import React from 'react';
import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
	Row,
	Col
} from 'react-bootstrap';

import AVSectionItem from './AVSectionItem/AVSectionItem';
import Loader from '../../Loader/Loader';
import AVActivities from '../AVActivities/AVActivities';

import s from './AVSections.css';

class AVSections extends React.Component {
	static propTypes = {
	};

	static defaultProps = {
	};

	render() {
		const { sections, onSectionClick, loading, selectedSection, region } = this.props;

		const sliderSettings = {
			centerMode: false,
			infinite: true,
			slidesToShow: 6,
			rows: selectedSection.id ? 1 : 2,
			slidesPerRow: 1,
			slidesToScroll: 1,
			autoplay: true,
			speed: 1000,
			autoplaySpeed: 1000,
			cssEase: "linear",
			adaptiveHeight: true,
			arrows: false,
			// rtl: true, // For arabic
		};

		return (
			<Loader type={"page"} show={loading}>
				<Row>
					<Col md={12}>
						<h3>Added Values Experiences</h3>
						<p>Hosts will provide some exciting experiences for their guest during the trip.</p>
					</Col>
				</Row>
				{
					selectedSection.id &&
					<Row>
						<Col md={12}>
							<AVActivities
								section={selectedSection}
								region={region}
							/>
						</Col>
					</Row>
				}
				<Row>
					<Col md={12}>
						{/* TODO Keep only one mapping */}
						{
							(sections.length > 0) ?
							<Slider {...sliderSettings}>
								{
									sections.map((section, index) => (
										<AVSectionItem
											key={index} 
											section={section}
											onSectionClick={onSectionClick}
										/>
									))
								}
								{
									sections.map((section, index) => (
										<AVSectionItem
											key={index} 
											section={section}
											onSectionClick={onSectionClick}
										/>
									))
								}
								{
									sections.map((section, index) => (
										<AVSectionItem
											key={index} 
											section={section}
											onSectionClick={onSectionClick}
										/>
									))
								}
							</Slider>
							: (
								<p>No available experiences for this city</p>
							)
						}
					</Col>
				</Row>
			</Loader>
		);
	}
}

export default compose(withStyles(s))(AVSections);