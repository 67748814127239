import React from 'react';
import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
	Container,
	Row,
	Col
} from 'react-bootstrap';

import s from './AVFilters.css';

class AVFilters extends React.Component {
	static propTypes = {
	};

	static defaultProps = {
	};

	render() {
		const { region, regionsToShow, onRegionSelectChange } = this.props;

		return (
			<Row>
				<Col md={4}>
					<h4>Filter by City</h4>
					{
						(regionsToShow.length > 0) &&
						<select className="form-control" value={region ?? ''} onChange={(event) => onRegionSelectChange(event)}>
							<option value="" disabled hidden>Select your destination city</option>
							<option value=" ">All Cities</option>
							{
								regionsToShow.map(region => (
									<option key={region} value={region}>{region}</option>
								))
							}
						</select>
					}
				</Col>
			</Row>
		);
	}
}

export default compose(withStyles(s))(AVFilters);