// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux Form
import { Field, reduxForm, formValueSelector } from 'redux-form';

// Translation
import { injectIntl, FormattedMessage } from 'react-intl';

// Locale
import messages from '../../locale/messages';

// Helpers
import validateStep3 from './validateStep3';

// Redux
import { connect } from 'react-redux';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
  Row,
  Form,
  Col
} from 'react-bootstrap';
import s from './ListPlaceStep1.css';
import bt from '../../components/commonStyle.css';

// Component
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';
import updateStep3 from './updateStep3';

//Image
import toolTipIcon from '../../../public/SiteIcons/listCommonToolTip.svg';

class AdvanceNoticePage extends Component {

  static propTypes = {
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.handleCancellation = this.handleCancellation.bind(this);
  }

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
        <Form.Select {...input} className={className} >
          {children}
        </Form.Select>
      </div>
    )
  }

  renderTimeSelect = ({ input, label, meta: { touched, error }, children, className, startValue, endValue }) => {
    const { formatMessage } = this.props.intl;
    let start = (startValue) ? startValue : 8;
    let end = (endValue) ? endValue : 25;
    let timeArray = [];
    for (let i = start; i <= end; i++) {
      timeArray.push(i);
    }

    return (
      <div>
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
        <Form.Select {...input} className={className} >
          <option value="Flexible">{label}</option>
          {
            timeArray.map((item, key) => {
              return (
                <option value={item} key={key}>
                  {((item > 12) ? ((item > 24) ? item - 24 : item - 12) : item)}
                  {(item >= 12 && item < 24) ? 'PM' : 'AM'}
                  {(item == 12) ? ' (noon)' : ''}
                  {(item == 24) ? ' (mid night)' : ''}
                  {(item > 24) ? ' (next day)' : ''}
                </option>
              )
            })
          }
        </Form.Select>
      </div>
    )
  }

  handleCancellation() {
    const { cancellationPolicies, cancelPolicy } = this.props;
    let itemListData = cancellationPolicies && cancellationPolicies.length > 0 ? cancellationPolicies.filter(policy => policy.id == cancelPolicy) : [];
  }

  render() {
    const { handleSubmit, previousPage, nextPage, formPage, step, cancellationPolicies } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={s.grid}>
        <SidePanel
          title={formatMessage(messages.stepThreeCommonHeading)}
          landingContent={formatMessage(messages.whatamenitiesPanel)}
        />
        <form onSubmit={handleSubmit}>
          <div className={s.landingMainContent}>
            <Form.Label className={cx(s.landingLabel, 'textWhite')}>
              <FormattedMessage {...messages.advanceNoticeCheckInTitle} />
            </Form.Label>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <Form.Group className={s.formGroup}>
                  <Form.Label className={cx(s.landingLabel, 'textWhite', s.FromTextCss)}>
                    <FormattedMessage {...messages.advanceNoticeFrom} />
                  </Form.Label>
                  <Field
                    name="checkInStart"
                    component={this.renderTimeSelect}
                    className={cx(s.formControlSelect, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
                    startValue={8}
                    endValue={25}
                    label={formatMessage(messages.flexible)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <Form.Group className={s.formGroup}>
                  <Form.Label className={cx(s.landingLabel, 'textWhite', s.FromTextCss)}>
                    <FormattedMessage {...messages.advanceNoticeTo} />
                  </Form.Label>
                  <Field
                    name="checkInEnd"
                    component={this.renderTimeSelect}
                    className={cx(s.formControlSelect, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
                    startValue={9}
                    endValue={26}
                    label={formatMessage(messages.flexible)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {
              cancellationPolicies &&
              <Form.Group className={cx(s.formGroup, s.spaceTop4)}>
                <Form.Label className={cx(s.landingLabel, 'textWhite')}>
                  <FormattedMessage {...messages.chooseCancellationPolicy} />
                </Form.Label>
                <Field
                  name="cancellationPolicy"
                  component={this.renderFormControlSelect}
                  className={cx(s.formControlSelect, s.jumboSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
                  onChange={() => this.handleCancellation()}
                >
                  <option value={"1"}>{formatMessage(messages.flexible) + ': ' + formatMessage(messages[cancellationPolicies[0].policyContent])}</option>
                  <option value={"2"}>{formatMessage(messages.moderate) + ': ' + formatMessage(messages[cancellationPolicies[1].policyContent])}</option>
                  <option value={"3"}>{formatMessage(messages.strict) + ': ' + formatMessage(messages[cancellationPolicies[2].policyContent])}</option>
                </Field>
              </Form.Group>
            }
          </div>
          <FooterButton
            nextPage={nextPage}
            previousPage={previousPage}
            nextPagePath={"pricing"}
            previousPagePath={"house-rules"}
            formPage={formPage}
            step={step}
          />
        </form>
      </div>
    );
  }
}

AdvanceNoticePage = reduxForm({
  form: 'ListPlaceStep3', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep3,
  onSubmit: updateStep3
})(AdvanceNoticePage);

const selector = formValueSelector('ListPlaceStep3'); // <-- same as form name

const mapState = (state) => ({
  cancelPolicy: selector(state, 'cancellationPolicy'),
  listingFields: state.listingFields.data,
  existingList: state.location.isExistingList,
  cancellationPolicies: state.location.cancellationPolicies,
});

const mapDispatch = {};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(AdvanceNoticePage)));