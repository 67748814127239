import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { graphql, gql, compose } from 'react-apollo';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, injectIntl } from 'react-intl';

import CurrencyConverter from '../../CurrencyConverter';
import Link from '../../Link';
import CustomPagination from '../../CustomPagination';

import reservationsQuery from './reservationsQuery.graphql';
import { updatePayoutStatus } from '../../../actions/updatePayoutStatus';
import { getPayoutStatus } from './getPayoutStatus';
import messages from '../../../locale/messages';
import formatReservationState from '../../../helpers/formatReservationState';

import s from './ManagePayout.css';

class ManagePayout extends React.Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        getAllPayouts: PropTypes.shape({
            loading: PropTypes.bool.isRequired,
            refetch: PropTypes.any.isRequired,
            getTransactionHistory: PropTypes.shape({
                count: PropTypes.number.isRequired,
                reservationData: PropTypes.arrayOf(PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    listId: PropTypes.number.isRequired,
                    hostId: PropTypes.string.isRequired,
                    guestId: PropTypes.string.isRequired,
                    checkIn: PropTypes.string.isRequired,
                    checkOut: PropTypes.string.isRequired,
                    guestServiceFee: PropTypes.number.isRequired,
                    hostServiceFee: PropTypes.number.isRequired,
                    total: PropTypes.number.isRequired,
                    currency: PropTypes.string.isRequired,
                    reservationState: PropTypes.string.isRequired,
                    listData: PropTypes.shape({
                        title: PropTypes.string.isRequired
                    }),
                    hostData: PropTypes.shape({
                        profileId: PropTypes.number.isRequired,
                        firstName: PropTypes.string.isRequired
                    }),
                    hostPayout: PropTypes.shape({
                        id: PropTypes.number.isRequired,
                        payEmail: PropTypes.string.isRequired,
                        methodId: PropTypes.number.isRequired,
                        currency: PropTypes.string.isRequired,
                        last4Digits: PropTypes.number
                    }),
                    hostTransaction: PropTypes.shape({
                        id: PropTypes.number.isRequired,
                    }),
                    hostFailedTransaction: PropTypes.shape({
                        id: PropTypes.number.isRequired,
                    }),
                    guestData: PropTypes.shape({
                        profileId: PropTypes.number.isRequired,
                        firstName: PropTypes.string.isRequired
                    }),
                    transaction: PropTypes.shape({
                        payerEmail: PropTypes.string.isRequired,
                        paymentType: PropTypes.string.isRequired,
                        total: PropTypes.number.isRequired,
                        currency: PropTypes.string.isRequired,
                        paymentMethodId: PropTypes.number
                    }),
                    refundStatus: PropTypes.shape({
                        id: PropTypes.number.isRequired,
                        receiverEmail: PropTypes.string.isRequired,
                        total: PropTypes.number.isRequired,
                        currency: PropTypes.string.isRequired
                    }),
                    cancellationDetails: PropTypes.shape({
                        refundToGuest: PropTypes.number.isRequired,
                        payoutToHost: PropTypes.number.isRequired,
                        total: PropTypes.number.isRequired,
                        currency: PropTypes.string.isRequired,
                        guestServiceFee: PropTypes.number.isRequired,
                        hostServiceFee: PropTypes.number.isRequired,
                    }),
                })),
            }),
        }).isRequired,
    }

    static defaultProps = {
        getAllPayouts: {
            loading: true,
            getAllPayoutReservation: {
                count: null,
                reservationData: []
            }
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            searchList: '',
            typing: false,
            typingTimeout: 0
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getAllPayouts: { completed, loading } } = nextProps;
        const { searchList, currentPage } = this.state;
        const { getAllPayouts: { refetch } } = this.props;
        let variables = { currentPage, searchList };
        if (completed && !loading) {
            refetch(variables);
        }
    }

    paginationData = (currentPage) => {
        const { getAllPayouts: { refetch } } = this.props;
        let variables = { currentPage };
        this.setState({ currentPage });
        refetch(variables);
    }

    handleClick = (searchList) => {
        const { getAllPayouts: { refetch } } = this.props;
        const { currentPage } = this.state;
        let variables = {
            currentPage: 1,
            searchList: searchList
        };
        this.setState({ currentPage: 1 });
        refetch(variables);
    }

    handleSearchChange = (e) => {
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }
        self.setState({
            searchList: e.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.handleClick(self.state.searchList);
            }, 450)
        });
    }

    handleHoldPayout = (e, id, currentPage) => {
        const { updatePayoutStatus, getAllPayouts: { refetch } } = this.props;
        let isHold = e.target.value;
        isHold = isHold == "true" ? true : false;
        updatePayoutStatus(id, isHold);
        let variables = { currentPage };
        this.setState({ currentPage });
        refetch(variables);
    }

    render() {
        const { getAllPayouts: { loading, getAllPayoutReservation, refetch } } = this.props;
        const { currentPage } = this.state;
        const { formatMessage } = this.props.intl;

        return (
            <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
                <div>
                    <h1 className={s.headerTitle}><FormattedMessage {...messages.payoutManagement} /></h1>
                    <div className={cx(s.exportSection, 'bgBlack')}>
                        <div>
                            <Form.Control
                                type="text"
                                placeholder={formatMessage(messages.search)}
                                onChange={(e) => this.handleSearchChange(e)}
                                className={cx('searchInputControl', 'searchInputControlWidth', 'searchInputControlAR')}
                            />
                        </div>
                    </div>
                    <div className={cx('table-responsive', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">{formatMessage(messages.reservationId)}</th>
                                    <th scope="col">{formatMessage(messages.codeLabel)}</th>
                                    <th scope="col">{formatMessage(messages.adminListTitle)}</th>
                                    <th scope="col">{formatMessage(messages.hostNameLabel)}</th>
                                    <th scope="col">{formatMessage(messages.payoutLabel)}</th>
                                    <th scope="col">{formatMessage(messages.reservationStatus)}</th>
                                    <th scope="col">{formatMessage(messages.payoutStatus)}</th>
                                    <th scope="col">{formatMessage(messages.holdPayout)}</th>
                                    <th scope="col">{formatMessage(messages.details)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    getAllPayoutReservation && getAllPayoutReservation.reservationData && getAllPayoutReservation.reservationData.length > 0 ?
                                    getAllPayoutReservation.reservationData.map((item, index) => {
                                        let payoutAmount;
                                        let status = getPayoutStatus(item);

                                        if (item.reservationState == 'cancelled') {
                                            payoutAmount = item.cancellationDetails.payoutToHost;
                                        } else {
                                            payoutAmount = item.total - item.hostServiceFee;
                                        }
                                        return (
                                            <tr key={index}>
                                                <td>{item.id}</td>
                                                <td>{item.confirmationCode}</td>
                                                {
                                                    item.listData ?
                                                    <td><a href={"/rooms/" + item.listId} target='_blank'>{item.listData.title}</a></td>
                                                    :
                                                    <td data={formatMessage(messages.dataMissing)} />
                                                }
                                                <td>{item.hostData && item.hostData.firstName}</td>
                                                <td>
                                                    <CurrencyConverter
                                                        amount={payoutAmount}
                                                        from={item.currency}
                                                    />
                                                </td>
                                                <td className={s.ChangeToUpperCase}>{formatReservationState(item.reservationState)}</td>
                                                <td>
                                                    {
                                                        status && status.defaultMessage === 'Failed' ?
                                                        <Link to={'/siteadmin/failed-payout/' + item.id}>{formatMessage(status)}</Link> 
                                                        :
                                                        (status ? formatMessage(status) : status)
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        ((item && item.hostTransaction) || item.reservationState === 'expired'
                                                        || item.reservationState === 'declined')
                                                        ? ' - '
                                                        : 
                                                        <select value={item.isHold} onChange={(e) => this.handleHoldPayout(e, item.id, currentPage)}>
                                                            <option value={true}>{formatMessage(messages.grant)}</option>
                                                            <option value={false}>{formatMessage(messages.deny)}</option>
                                                        </select>
                                                    }
                                                </td>
                                                <td>
                                                    <Link to={"/siteadmin/viewpayout/" + item.id + '/payout'} >
                                                        <FormattedMessage {...messages.messageHistroyLabel} />
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : (
                                        <tr><td colSpan="9">{formatMessage(messages.noRecordFound)}</td></tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                        getAllPayoutReservation && getAllPayoutReservation.reservationData && getAllPayoutReservation.reservationData.length > 0 &&
                        <CustomPagination
                            total={getAllPayoutReservation.count}
                            currentPage={currentPage}
                            defaultCurrent={1}
                            defaultPageSize={10}
                            change={this.paginationData}
                            paginationLabel={formatMessage(messages.payoutsLabel)}
                            isScroll
                        />
                    }
                </div>
            </div>
        )
    }
}

const mapState = (state) => ({
    completed: state.payout.completed,
    loading: state.payout.loading,
});

const mapDispatch = {
    updatePayoutStatus,
};
export default compose(injectIntl,
    withStyles(s),
    connect(mapState, mapDispatch),
    graphql(reservationsQuery, {
        name: 'getAllPayouts',
        options: {
            variables: {
                currentPage: 1,
                searchList: ''
            },
            fetchPolicy: 'network-only',
        }
    })
)(ManagePayout);