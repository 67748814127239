
import messages from '../../locale/messages';


const validateStep3 = values => {
  console.log('from validateStep3 values', values);

	const errors = {};

	if (Number(values.checkInStart) >= Number(values.checkInEnd)) errors.checkInStart = messages.advanceNoticeError;
	
	if (Number(values.checkInStart) < 8 || Number(values.checkInStart) > 25) errors.checkInStart = messages.advanceNoticeInvalid;

	if (Number(values.checkInEnd) < 9 || Number(values.checkInEnd) > 26) errors.checkInEnd = messages.advanceNoticeInvalid;
	
	if (!values.basePrice) errors.basePrice = messages.required;
	
	if (isNaN(values.basePrice) || (!/^[0-9\.]+$/.test(values.basePrice)) || (parseFloat(values.basePrice, 10) < 1)) errors.basePrice = messages.basePriceInvalid;
	
	if (values.cleaningPrice) {
		if (isNaN(values.cleaningPrice) || (!/^[0-9\.]+$/.test(values.cleaningPrice)) || (parseInt(values.cleaningPrice, 10) < 0)) errors.cleaningPrice = messages.cleaningPriceInvalid;
	}

	if (values.tax) {
		if (isNaN(values.tax) || (!/^[0-9]+$/.test(values.tax)) || (parseInt(values.tax, 10) < 0) || parseInt(values.tax, 10) > 99) errors.tax = messages.taxInvalid;
	}
	
	if (values.weeklyDiscount) {
		if (isNaN(values.weeklyDiscount) || (!/^[0-9]+$/.test(values.weeklyDiscount)) || (parseInt(values.weeklyDiscount, 10) < 0) || parseInt(values.weeklyDiscount, 10) > 99) errors.weeklyDiscount = messages.discountInvalid;
	}

	if (values.monthlyDiscount) {
		if (isNaN(values.monthlyDiscount) || (!/^[0-9]+$/.test(values.monthlyDiscount)) || (parseInt(values.monthlyDiscount, 10) < 0) || parseInt(values.monthlyDiscount, 10) > 99) errors.monthlyDiscount = messages.discountInvalid;
	}

	if (Number(values.maxNight) > 0) {
		if (values.minNight > values.maxNight) errors.minNight = messages.tripLengthError1;
	}

	if (values.taxRate) {
		if (isNaN(values.taxRate) || (!/^[0-9\.]+$/.test(values.taxRate)) || (parseInt(values.taxRate, 10) < 0) || parseInt(values.taxRate, 10) > 99) errors.taxRate = messages.taxRateInvalidError;
	}

	if (values.minMonthlyRental) {
		if ((Number(values.minMonthlyRental) < 1) || (Number(values.minMonthlyRental) > 12)) errors.minMonthlyRental = messages.min_monthly_rental_input_invalid;
	}

	if (values.maxMonthlyRental) {
		if ((Number(values.maxMonthlyRental) < 1) || (Number(values.maxMonthlyRental) > 12)) errors.maxMonthlyRental = messages.max_monthly_rental_input_invalid;
		if (values.minMonthlyRental > values.maxMonthlyRental) errors.maxMonthlyRental = messages.max_monthly_rental_input_error_less_than_min;
	}

	return errors;
}

export default validateStep3;
