
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './InstantBook.css';

import cx from 'classnames';

// Redux Form
import { Field, formValueSelector, change } from 'redux-form';

// Redux
import { connect } from 'react-redux';

// Locale
import messages from '../../../../../../locale/messages';

import Switch from '../../../../../Switch/Switch';

class InstantBook extends Component {

  static propTypes = {
    className: PropTypes.any,
    handleTabToggle: PropTypes.any,
    isExpand: PropTypes.bool
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);
  }

  renderSwitch = ({ input }) => {
    const { bookingType, change } = this.props;

    let isInstant = (bookingType === 'instant');

    return (
      <div>
        <Switch
          {...input}
          checked={isInstant}
          onToggle={() =>
            change('SearchForm', 'bookingType', isInstant ? 'request' : 'instant')
          }
        />
      </div>
    )
  }

  render() {
    const { className } = this.props;
    return (
      <div className={className}>
        <div className={cx(s.displayFlex, s.space4)}>
          <div>
            <p className={cx(s.moreFilterTitle, s.textBold)}>
              <FormattedMessage {...messages.instantBook} />
            </p>
            <p className={cx(s.captionTitle, s.noMarginBottom)}>
              <FormattedMessage {...messages.instantBookInfo} />
            </p>
          </div>
          <div className={cx('searchSwitch', 'directionLtr', 'textAlignLeftRtl')}>
            <Field
              name="bookingType"
              component={this.renderSwitch}
            />
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector('SearchForm');
const mapState = (state) => ({
  fieldsSettingsData: state.listingFields.data,
  bookingType: selector(state, 'bookingType')
});

const mapDispatch = {
  change,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(InstantBook)));