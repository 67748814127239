import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import Link from '../../../components/Link';
import Confirm from 'react-confirm-bootstrap';
import {
  Button
} from 'react-bootstrap';
// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './BlogManagement.css';
import bt from '../../../components/commonStyle.css';
import { deleteBlogDetails, updateBlogStatus } from '../../../actions/siteadmin/deleteBlogDetails';
import history from '../../../core/history';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';

class BlogManagement extends React.Component {

  static propTypes = {
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      location: PropTypes.string,
      locationAddress: PropTypes.string,
      isEnable: PropTypes.bool,
      images: PropTypes.string,
    })),
    deleteBlogDetails: PropTypes.any,
    updateBlogStatus: PropTypes.any,
  };

  static defaultProps = {
    data: []
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    history.push('/siteadmin/page/add')
  }

  render() {
    const { data, deleteBlogDetails, updateBlogStatus } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <div>
          <h1 className={s.headerTitle}><FormattedMessage {...messages.contentManagementSystem} /></h1>
          <div className={s.space4}>
            <Button onClick={this.handleClick} className={cx(bt.btnPrimary, bt.btnLarge)}>
              <FormattedMessage {...messages.addPageLabel} />
            </Button>
          </div>
          <div className={cx('table-responsive', 'tableBorderRadiusAdmin', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{formatMessage(messages.idLabel)}</th>
                  <th scope="col">{formatMessage(messages.metaTitleLabel)}</th>
                  <th scope="col">{formatMessage(messages.metaDescriptionLabel)}</th>
                  <th scope="col">{formatMessage(messages.pageTitleLabel)}</th>
                  <th scope="col">{formatMessage(messages.pageUrl)}</th>
                  <th scope="col">{formatMessage(messages.footerCategoryLabel)}</th>
                  <th scope="col">{formatMessage(messages.status)}</th>
                  <th scope="col">{formatMessage(messages.preview)}</th>
                  <th scope="col">{formatMessage(messages.editLabel)}</th>
                  <th scope="col">{formatMessage(messages.delete)}</th>
                </tr>
              </thead>
              <tbody>
                {
                  data && data.length > 0 ?
                  data.map(function (value, key) {
                    return (
                      <tr key={key}>
                        <td>{value.id}</td>
                        <td>{value.metaTitle}</td>
                        <td>{value.metaDescription}</td>
                        <td>{value.pageTitle}</td>
                        <td>{value.pageUrl}</td>
                        <td>{value.footerCategory}</td>
                        <td>
                          <select value={value.isEnable} onChange={(e) => updateBlogStatus(value.id, value.isEnable)}>
                            <option value={true}>{formatMessage(messages.enabledLabel)}</option>
                            <option value={false}>{formatMessage(messages.disabledLabel)}</option>
                          </select>
                        </td>
                        <td>
                          <a href={"/page/" + value.pageUrl} target={'_blank'}>
                            <FormattedMessage {...messages.preview} />
                          </a>
                        </td>
                        <td>
                          <Link to={"/siteadmin/edit/page/" + value.id}>
                            <FormattedMessage {...messages.editLabel} />
                          </Link>
                        </td>
                        <td>
                          <div>
                            <Confirm
                              onConfirm={() => deleteBlogDetails(value.id)}
                              body={formatMessage(messages.areYouSureDeleteWishList)}
                              confirmText={formatMessage(messages.confirmDelete)}
                              cancelText={formatMessage(messages.cancel)}
                              title={formatMessage(messages.deletingPageDetails)}
                            >
                              <a href="#"><FormattedMessage {...messages.delete} /></a>
                            </Confirm>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                  : (
                    <tr>
                      <td colSpan="10">{formatMessage(messages.noRecordFound)}</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
});

const mapDispatch = {
  deleteBlogDetails,
  updateBlogStatus
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(BlogManagement)));