import React from 'react';
import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
	Row,
	Col
} from 'react-bootstrap';

import AVActivityItem from './AVActivityItem/AVActivityItem';

import s from './AVActivities.css';

class AVActivities extends React.Component {
	static propTypes = {
	};

	static defaultProps = {
	};

	render() {
		const { section, region } = this.props;

		const logosPerRow = 4;

		const sliderSettings = {
			centerMode: false,
			infinite: true,
			slidesToShow: 6,
			rows: logosPerRow,
			slidesPerRow: 1,
			slidesToScroll: 1,
			autoplay: true,
			speed: 1000,
			autoplaySpeed: 1000,
			cssEase: "linear",
			adaptiveHeight: true,
			arrows: false,
			// rtl: true, // For arabic
		};

		let sectionSearchLink = `/?section=${section.name}`;
		if (region) {
			sectionSearchLink += `&&address=${region}`;
		}

		return (
			<Row>
				<Col md={2} className="align-content-center">
					<img src={'/images/sections/' + section.image} width={100} />
					<h5>{section.name}</h5>
					<a href={sectionSearchLink} target="_blank">Filter Listings by this section</a>
				</Col>
				<Col md={10}>
					<Row className="mb-3">
						{
							(section.activities.length > logosPerRow) ?
								<Slider {...sliderSettings}>
									{
										section.activities.map((activity, index) => (
											<AVActivityItem
												key={index}
												activity={activity}
												sectionName={section.name}
												region={region}
											/>
										))
									}
								</Slider>
								: (
									section.activities.map((activity, index) => (
										<Col md={3} key={index}>
											<AVActivityItem
												key={index}
												activity={activity}
												sectionName={section.name}
												region={region}
											/>
										</Col>
									))
								)
						}
					</Row>
				</Col>
			</Row>
		);
	}
}

export default compose(withStyles(s))(AVActivities);