export function handleReservationDiscount(subtotal, dayDifference, weeklyDiscount, monthlyDiscount) {
  let discountValue = 0;
  let discountType = null;

  if (dayDifference >= 7) {
    if ((monthlyDiscount > 0) && (dayDifference >= 30)) {
      discountValue = (Number(subtotal) * Number(monthlyDiscount)) / 100;
      discountType = 'monthly';
    }
    else if (weeklyDiscount > 0) {
      discountValue = (Number(subtotal) * Number(weeklyDiscount)) / 100;
      discountType = 'weekly';
    }
  }

  return { discountValue, discountType };
}