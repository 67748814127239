import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { graphql, gql, compose } from 'react-apollo';
import Confirm from 'react-confirm-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Form } from 'react-bootstrap';

import Link from '../../../components/Link';
import StarRating from '../../StarRating';
import s from './AdminReviewsManagement.css';

import { censorEmail, censorPhone } from '../../../helpers/secureEmail';

import CustomPagination from '../../CustomPagination';

import messages from '../../../locale/messages';
import { deleteAdminReview } from '../../../actions/siteadmin/AdminReviews/deleteAdminReview';
import reviewsManagement from './reviewsManagement.graphql';

class AdminReviewsManagement extends React.Component {

  static propTypes = {
    data: PropTypes.array,
    editUser: PropTypes.any,
    deleteAdminReview: PropTypes.any,
    title: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0
    }
  }

  paginationData = (currentPage)=>{
    const { reviewsManagement: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);

  }

  handleClick = (searchList) => {
    const { reviewsManagement: { refetch } } = this.props;
    const { currentPage } = this.state;
    let variables = {
      currentPage: 1,
      searchList: searchList
    };
    this.setState({ currentPage: 1 });
    refetch(variables);
  }

  handleSearchChange = (e) => {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      searchList: event.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleClick(self.state.searchList);
      }, 450)
    });
  }

  deleteReview(id) {
    const { deleteAdminReview } = this.props;
    const { reviewsManagement: { refetch } } = this.props;

    let variables = { currentPage: 1 };
    deleteAdminReview(id);
    this.setState({ currentPage: 1 });
    refetch(variables);
  }

  render() {
    const { editUser, deleteAdminReview, title } = this.props;
    const { currentPage } = this.state;
    const { reviewsManagement: { loading, reviewsManagement } } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <div>
          <h1 className={s.headerTitle}> <FormattedMessage {...messages.adminReviews} /></h1>
          <div className={cx(s.exportSection, 'bgBlack')}>
            <div>
              <Form.Control
                type="text"
                placeholder={formatMessage(messages.search)}
                onChange={(e) => this.handleSearchChange(e)}
                className={cx('searchInputControl', 'searchInputControlWidth', 'searchInputControlAR')}
              />
            </div>
          </div>
          <div className={cx('table-responsive', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{formatMessage(messages.listId)}</th>
                  <th scope="col">{formatMessage(messages.adminListTitle)}</th>
                  <th scope="col">{formatMessage(messages.reviewContentLabel)}</th>
                  <th scope="col">{formatMessage(messages.ratingReviewLabel)}</th>
                  <th scope="col">{formatMessage(messages.editLabel)}</th>
                  <th scope="col">{formatMessage(messages.delete)}</th>
                </tr>
              </thead>
              <tbody>
                {
                  reviewsManagement && reviewsManagement.reviewsData.length > 0 ?
                  reviewsManagement.reviewsData.map((value, key) => {
                    return (
                      <tr key={key}>
                        <td>{value.listId}</td>
                        <td><a href={"/rooms/" + value.listId} target="_blank">{value.listData ? value.listData.title : 'List is missing'}</a></td>
                        <td>{value.reviewContent}</td>
                        <td>
                          <StarRating className={s.reviewStar} value={value.rating} name={'review'} starCount={5} />
                        </td>
                        <td>
                          <Link to={"/siteadmin/reviews/edit-review/" + value.id}>
                            <FormattedMessage {...messages.editLabel} />
                          </Link>
                        </td>
                        <td>
                          <div>
                            <Confirm
                              onConfirm={() => this.deleteReview(value.id)}
                              body={formatMessage(messages.areYouSureDeleteWishList)}
                              confirmText={formatMessage(messages.confirmDelete)}
                              cancelText={formatMessage(messages.cancel)}
                              title={formatMessage(messages.deleteReviewLabel)}
                            >
                              <a href="#">
                                <FormattedMessage {...messages.delete} />
                              </a>
                            </Confirm>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                  : (
                    <tr><td colSpan="6">{formatMessage(messages.noRecordFound)}</td></tr>
                  )
                }
              </tbody>
            </table>
          </div>
          {
            reviewsManagement && reviewsManagement.reviewsData && reviewsManagement.reviewsData.length > 0
            && <div>
              <CustomPagination
                total={reviewsManagement.count}
                currentPage={currentPage}
                defaultCurrent={1}
                defaultPageSize={10}
                change={this.paginationData}
                paginationLabel={formatMessage(messages.reviews)}
                isScroll
              />
            </div>
          }
        </div>
      </div>
    );
  }

}

const mapState = (state) => ({
});

const mapDispatch = {
  deleteAdminReview,
};

export default compose(injectIntl,
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(reviewsManagement, {
    name: 'reviewsManagement',
    options: {
      variables: {
        currentPage: 1,
        searchList: '',
        type: 'admin'
      },
      fetchPolicy: 'network-only',
    }
  })
)(AdminReviewsManagement);