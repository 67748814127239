import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
  Row,
  Col,
  Form,
  Button,
} from 'react-bootstrap';

// Component
import DateRange from '../DateRange';
import BillDetails from './BillDetails';
import CheckinSingleDate from '../CheckinSingleDate/CheckinSingleDate';
import MonthlyRentalCheckoutMonthSelector from './MonthlyRentalCheckoutMonthSelector';

import BookingButton from './BookingButton';

import history from '../../../core/history';

// Locale
import messages from '../../../locale/messages';

import bt from '../../../components/commonStyle.css';
import s from './Calendar.css';

class BookingForm extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    availability: PropTypes.bool,
    maximumStayExceeded: PropTypes.bool,
    blockedDates: PropTypes.array,
    isHost: PropTypes.bool.isRequired,
    formatMessage: PropTypes.any,
    account: PropTypes.shape({
      userBanStatus: PropTypes.number,
    })
  };

  static defaultProps = {
    blockedDates: [],
    availability: true,
    maximumStayExceeded: false,
    minimumStayNotReached: false,
  };

  renderFormControlSelect({ input, label, meta: { touched, error }, children, className }) {
    return (
      <div>
        <Form.Select {...input} className={className}>
          {children}
        </Form.Select>
      </div>
    );
  }

  renderGuests() {
    const { UserListing } = this.props;
    const { formatMessage } = this.props.intl;

    let guestNameMessage = null;
    const rows = [];
    for (let i = 1; i <= UserListing.personCapacity; i++) {
      if (i == 1) {
        guestNameMessage = formatMessage(messages.guest_count_singular);
      }
      if (i == 2) {
        guestNameMessage = formatMessage(messages.guest_count_2);
      }
      else if ((i >= 3) && (i <= 10)) {
        guestNameMessage = formatMessage(messages.guest_count_from_3_to_10);
      }
      else if (i > 10) {
        guestNameMessage = formatMessage(messages.guest_count_above_10);
      }
      rows.push(<option key={i} value={i}>{((i >= 3) ? i + ' ' : '') + guestNameMessage}</option>);
    }
    return rows;
  }

  render() {
    const { UserListing, UserListing: { listingData }, isHost, blockedDates, isLoading, availability, maximumStayExceeded, account, minimumStayNotReached, availableMonths, bookingFormValues } = this.props;
    const { formatMessage } = this.props.intl;

    let dateRangeChosen = false;
    if (bookingFormValues.startDate && bookingFormValues.endDate) {
      dateRangeChosen = true;
    }

    let loggedinUserBanned = false;
    if (account?.userBanStatus === 1) {
      loggedinUserBanned = true;
    }

    let submitButtonDisabled = false;
    if (!dateRangeChosen || (listingData.basePrice < 1) || isHost || maximumStayExceeded || minimumStayNotReached || loggedinUserBanned) {
      submitButtonDisabled = true;
    }

    let monthlyRentalToggled = listingData.isMonthlyRentalOnly || bookingFormValues.showMonthlyRental;
    if (monthlyRentalToggled && (bookingFormValues.monthlyRentalCheckoutMonth > 0)) {
      submitButtonDisabled = false;
    }
    else if (monthlyRentalToggled && !bookingFormValues.monthlyRentalCheckoutMonth > 0) {
      submitButtonDisabled = true;
    }

    let submitButtonLabel;
    if (isHost) {
      submitButtonLabel = formatMessage(messages.host_booking_own_listing);
    }
    else {
      if (UserListing.bookingType === 'instant') {
        submitButtonLabel = formatMessage(messages.book);
      }
      else {
        submitButtonLabel = formatMessage(messages.requestToBook);
      }

      if (monthlyRentalToggled && (bookingFormValues.monthlyRentalCheckoutMonth > 0)) {
        submitButtonLabel += ' (' + bookingFormValues.monthlyRentalCheckoutMonth + ' months)';
      }
    }

    return (
      <Form>
        <Form.Group className={s.noMargin}>
          <Row>
            {
              monthlyRentalToggled ? (
              <Col xs={12} sm={12} md={12} lg={12}>
                <label className={cx(s.guestText, 'guestTextRTL')}>
                  <FormattedMessage {...messages.checkinDate} />
                </label>
                <span className={cx('viewListingDate')}>
                  <CheckinSingleDate
                    listingId={UserListing.id}
                    blockedDates={blockedDates}
                    maxDaysNotice={listingData.maxDaysNotice}
                    country={UserListing.country}
                  />
                </span>
              </Col>
              )
              : (
              <Col xs={12} sm={12} md={12} lg={12}>
                <label className={cx(s.guestText, 'guestTextRTL')}>
                  <FormattedMessage {...messages.dates} />
                </label>
                <span className={cx('viewListingDate')}>
                  <DateRange
                    listingId={UserListing.id}
                    blockedDates={blockedDates}
                    maxDaysNotice={listingData.maxDaysNotice}
                    country={UserListing.country}
                    minimumNights={listingData.minNight}
                    maximumNights={listingData.maxNight}
                  />
                </span>
              </Col>
              )
            }
          </Row>
        </Form.Group>
        {
          (monthlyRentalToggled && (availableMonths > 0)) &&
          <MonthlyRentalCheckoutMonthSelector
            minMonthlyRental={listingData.minMonthlyRental}
            startDate={bookingFormValues.startDate}
            bookingEditModal={false}
          />
        }
        <Form.Group>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Form.Label className={cx(s.guestText, 'guestTextRTL')}><FormattedMessage {...messages.howManyGuest} /></Form.Label>
              <Field
                name="guests"
                component={this.renderFormControlSelect}
                className={cx(s.formControlSelect, bt.commonControlSelect, 'viewGuestCount', s.guestFormControlSelect)}
              >
                {this.renderGuests()}
              </Field>
            </Col>
          </Row>
        </Form.Group>

        {
          (!isLoading && !loggedinUserBanned) &&
          <>
            {
              !monthlyRentalToggled && dateRangeChosen && (maximumStayExceeded || minimumStayNotReached) &&
              <div className={cx(s.bookItMessage, s.spaceTop3)}>
                <p className={cx(s.noMargin, s.textCenter, s.textError)}>
                  { maximumStayExceeded && <div>{`${formatMessage(messages.maximumStayExceeded)} ${listingData.maxNight} ${(listingData.maxNight > 1) ? formatMessage(messages.nights) : formatMessage(messages.night)}`}</div> }
                  { minimumStayNotReached && <div>{`${formatMessage(messages.minimumNightStay)} ${listingData.minNight} ${(listingData.minNight > 1) ? formatMessage(messages.nights) : formatMessage(messages.night)}`}</div> }
                </p>
              </div>
            }
            {
              ((!monthlyRentalToggled && !availability && dateRangeChosen) ||
              (monthlyRentalToggled && (availableMonths === 0))) &&
              <div className={cx(s.bookItMessage, s.spaceTop3)}>
                <p className={cx(s.noMargin, s.textCenter, s.textError)}>
                  <FormattedMessage {...messages.hostErrorMessage2} />
                </p>
              </div>
            }
            {
              ((!monthlyRentalToggled && !maximumStayExceeded && !minimumStayNotReached && availability && dateRangeChosen)
              || (monthlyRentalToggled && (availableMonths > 0) && (bookingFormValues.monthlyRentalCheckoutMonth > 0))) &&
              <BillDetails
                listingData={listingData}
              />
            }
            {
              ((!monthlyRentalToggled && !availability && dateRangeChosen) ||
              (monthlyRentalToggled && (availableMonths === 0))) &&
              <div>
                <Form.Group className={s.formGroup}>
                  <Button className={cx(s.btn, s.btnBlock, bt.btnLarge, bt.btnPrimaryBorder)} onClick={() => history.push('/')}>
                    <FormattedMessage {...messages.viewOtherListings} />
                  </Button>
                </Form.Group>
              </div>
            }
            {
              ((!monthlyRentalToggled && !maximumStayExceeded && !minimumStayNotReached && availability && dateRangeChosen)
              || (monthlyRentalToggled && (availableMonths > 0) && (bookingFormValues.monthlyRentalCheckoutMonth > 0))) &&
              <BookingButton
                listingId={UserListing.id}
                listingTitle={UserListing.title}
                disabled={submitButtonDisabled}
                buttonLabel={submitButtonLabel}
              />
            }
          </>
        }
      </Form>
    );
  }
}

BookingForm = reduxForm({
  form: 'BookingForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(BookingForm);

const mapState = state => ({
  isLoading: state.viewListing.isLoading,
  maximumStayExceeded: state.viewListing.maximumStayExceeded,
  minimumStayNotReached: state.viewListing.minimumStayNotReached,
  account: state.account.data,
  availability: state.viewListing.availability,
  availableMonths: state.viewListing.availableMonths,
  bookingFormValues: getFormValues('BookingForm')(state),
});

const mapDispatch = {
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(BookingForm)));