import { reset, change } from "redux-form";
import { getSearchResults, loadingSearchResults } from "./getSearchResults";
import GetSearchResults from "../../src/components/SearchListing/SearchResults/GetSearchResults.graphql"
import {
  SEARCH_LISTING_START,
  SEARCH_LISTING_SUCCESS,
  SEARCH_LISTING_ERROR,
} from "../constants";

export function searchListing({
  location,
  dates,
  personCapacity,
  roomType,
  geography,
  geoType,
  lat,
  lng,
  sw_lat,
  sw_lng,
  ne_lat,
  ne_lng,
  currentPage,
  totalPrice,

  isOneTotalToggle,
  offset,
  loadCount,
  bedrooms,
  privateBathrooms,
  sharedBathrooms,
  beds,
  amenities,
  safetyAmenities,
  spaces,
  houseRules,
  priceRange,
  bookingType,
  hostLanguages,
  isMonthlyRental,
  section,
  activity,
}, initialSearch = false) {
  return async (dispatch, getState, { client }) => {
    console.log('from searchListing action', initialSearch);
    dispatch({ type: SEARCH_LISTING_START });

    dispatch(loadingSearchResults());

    if (initialSearch) {
      dispatch(reset("SearchForm"));
    }

    const searchByMap = getState()?.form?.SearchForm?.values?.searchByMap;
    dispatch(change("SearchForm", "searchByMap", searchByMap))

    try {
      const { data } = await client.query({
        query: GetSearchResults,
        variables: {
          personCapacity: Number(personCapacity),
          roomType,
          dates,
          currentPage,
          geography,
          geoType,
          lat,
          lng,
          sw_lat,
          sw_lng,
          ne_lat,
          ne_lng,
          location,
          totalPrice,

          searchByMap,

          isOneTotalToggle,
          offset,
          loadCount,
          bedrooms,
          privateBathrooms: Number(privateBathrooms),
          sharedBathrooms: Number(sharedBathrooms),
          beds,
          amenities,
          safetyAmenities,
          spaces,
          houseRules,
          priceRange,
          bookingType,
          hostLanguages,
          isMonthlyRental,
          section,
          activity,
        },
        fetchPolicy: "network-only",
      });
      console.log('SearchListing request response data:', data);
      if (data?.SearchListing) {
        dispatch({ type: SEARCH_LISTING_SUCCESS });
        
        if (initialSearch) {
          await Promise.all([
            dispatch(change("SearchForm", "personCapacity", personCapacity)),
            dispatch(change("SearchForm", "roomType", roomType)),
            dispatch(change("SearchForm", "dates", dates)),
            dispatch(change("SearchForm", "geography", geography)),
            dispatch(change("SearchForm", "currentPage", currentPage)),
            dispatch(change("SearchForm", "geoType", geoType)),
            dispatch(change("SearchForm", "lat", lat)),
            dispatch(change("SearchForm", "lng", lng)),
            dispatch(change("SearchForm", "sw_lat", sw_lat)),
            dispatch(change("SearchForm", "sw_lng", sw_lng)),
            dispatch(change("SearchForm", "ne_lat", ne_lat)),
            dispatch(change("SearchForm", "ne_lng", ne_lng)),
            dispatch(change("SearchForm", "initialLoad", true)),
            dispatch(change("SearchForm", "markerHighlight", {})),

            // Default Map Show
            dispatch(change("SearchForm", "showMap", true)),
            dispatch(change("SearchForm", "totalPrice", totalPrice)),


            dispatch(change("SearchForm", "isOneTotalToggle", isOneTotalToggle)),
            dispatch(change("SearchForm", "offset", offset)),
            dispatch(change("SearchForm", "loadCount", loadCount)),
            dispatch(change("SearchForm", "bedrooms", bedrooms)),
            dispatch(change("SearchForm", "privateBathrooms", privateBathrooms)),
            dispatch(change("SearchForm", "sharedBathrooms", sharedBathrooms)),
            dispatch(change("SearchForm", "beds", beds)),
            dispatch(change("SearchForm", "amenities", amenities)),
            dispatch(change("SearchForm", "safetyAmenities", safetyAmenities)),
            dispatch(change("SearchForm", "spaces", spaces)),
            dispatch(change("SearchForm", "houseRules", houseRules)),
            dispatch(change("SearchForm", "priceRange", priceRange)),
            dispatch(change("SearchForm", "bookingType", bookingType)),
            dispatch(change("SearchForm", "hostLanguages", hostLanguages)),
            dispatch(change("SearchForm", "isMonthlyRental", isMonthlyRental)),
            dispatch(change("SearchForm", "section", section)),
            dispatch(change("SearchForm", "activity", activity)),
          ]);
        }

        dispatch(getSearchResults(data?.SearchListing));
      }
    } catch (error) {
      dispatch({
        type: SEARCH_LISTING_ERROR,
        payload: {
          error,
        },
      });
      return false;
    }

    return true;
  };
}
