import React, { Component } from "react";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

import Select, { components } from 'react-select';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

// Redux Form
import {
  reduxForm,
  formValueSelector,
} from "redux-form";
import { connect } from "react-redux";

import { changePersonalizedData } from '../../../../actions/toggleControl';
import { setPersonalizedValues } from '../../../../actions/personalized';

import messages from "../../../../locale/messages";

import { amenitiesUploadDir } from '../../../../config';
import s from "./RoomType.css";

class RoomType extends Component {

  constructor(props) {
    super(props);
  }

  handleRoomTypeChange = (e) => {
    const { setPersonalizedValues, changePersonalizedData } = this.props;
    const selectedValues = e.map(option => option.value);
    changePersonalizedData('SearchForm', 'roomType', selectedValues);
    setPersonalizedValues({ name: 'roomType', value: selectedValues })
  }

  CustomMenuList = (props) => {
    const { smallDevice } = this.props;
    return (
      <components.MenuList {...props}>
        <div style={{ display: 'flex', flexWrap: 'wrap'}}>
          {props.children.map((child, index) => (
            <div key={index} style={{ width: (smallDevice) ? '100%' : '50%' }}>
              {child}
            </div>
          ))}
        </div>
      </components.MenuList>
    );
  };

  render() {
    const { selectedRoomTypes, roomTypeData, theme, smallDevice } = this.props;
    const { formatMessage } = this.props.intl;

    let roomTypeOptions = [];
    if (roomTypeData?.length > 0) {
      roomTypeOptions = roomTypeData.map(item => ({
        text: item.itemName,
        value: item.id,
        image: item.image,
      }));
    }

    return (
      <>
        {
          (smallDevice) &&
          <div
            className={cx(
              s.displayTableCell,
              s.captionTitle,
              s.fullWidth,
              'text-nowrap'
            )}
          >
            <FormattedMessage {...messages.header_search_form_room_types_title} />
          </div>
        }
        <Select
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          controlShouldRenderValue={ (smallDevice) ? true : false}
          options={roomTypeOptions}
          value={roomTypeOptions.filter(option => selectedRoomTypes?.includes(option.value))}
          onChange={(e) => this.handleRoomTypeChange(e)} 
          components={{ MenuList: this.CustomMenuList }}
          placeholder={(selectedRoomTypes?.length >= 1) ? formatMessage(messages.header_search_form_room_types_with_selection) : formatMessage(messages.header_search_form_room_types_no_selection)}
          className={cx(s.formControlSelect, s.input, s.inputPadding, 'inputPaddingAR text-nowrap')}
          styles={{
            control: (provided, state) => ({
              ...provided,
              backgroundColor: 'transparent',
              color: 'var(--common-text-color)',
              fontSize: '14px',
              border: 'none',
              boxShadow: 'none',
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: 0,
            }),
            option: (provided, state) => ({
              ...provided,
              color: ('dark' === theme) ? 'white' : 'var(--common-text-color)',
              backgroundColor: state.isSelected ? (('dark' === theme) ? 'var(--darkmode-text-color)' : 'var(--common-text-Secondry-color)') : (('dark' === theme) ? 'var(--darkmode-primary-color)' : 'transparent'),
              '&:focus': {
                backgroundColor: state.isSelected ? (('dark' === theme) ? 'var(--darkmode-text-color)' : 'var(--common-text-Secondry-color)') : (('dark' === theme) ? 'var(--darkmode-primary-color)' : 'transparent'),
              },
              '&:active': {
                backgroundColor: state.isSelected ? (('dark' === theme) ? 'var(--darkmode-text-color)' : 'var(--common-text-Secondry-color)') : (('dark' === theme) ? 'var(--darkmode-primary-color)' : 'lightgrey'),
              },
              '&:hover': {
                backgroundColor: state.isSelected ? (('dark' === theme) ? 'var(--darkmode-text-color)' : 'var(--common-text-Secondry-color)') : (('dark' === theme) ? 'var(--darkmode-primary-color)' : 'lightgrey'),
              },
              padding: 20,
              '& img': {
                filter: ('dark' === theme) ? 'invert(100%) brightness(200%)' : '',
              }
            }),
            menu: (provided, state) => ({
              ...provided,
              border: 'none',
            }),
            placeholder: (provided, state) => ({
              ...provided,
              color: ('dark' === theme) ? 'var(--darkmode-text-color)' : 'var(--common-text-color)',
              minWidth: 'max-content',
            }),
            clearIndicator: () => ({
              display: 'none'
            }),
            indicatorSeparator: () => ({
              display: 'none'
            }),
            menu: (provided) => ({
              ...provided,
              width: (!smallDevice) ? '400px' : '100%',
              minHeight: 'max-content',
              marginLeft: (!smallDevice) ? '-100px' : ''
            }),
          }}
          getOptionLabel={e => (
            <div className="d-flex align-items-center">
              <img src={'/images/amenities/' + e.image} alt={e.text} width={20} style={{marginLeft: '10px'}}/>
              <span className="text-nowrap me-2">{messages[e.text] ? formatMessage(messages[e.text]) : messages[e.text]}</span>
            </div>
          )}
        />
      </>
    );
  }
}

const selector = formValueSelector("SearchForm");
const mapState = (state) => ({
  roomTypeData: state?.listingFields?.data?.roomType,
  selectedRoomTypes: selector(state, "roomType"),
  theme: state?.currency?.theme,
});

const mapDispatch = {
  setPersonalizedValues,
  changePersonalizedData,
};

export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(RoomType))
);
