import {
  GET_LANGUAGES_SUCCESS,
  GET_ALL_LANGUAGES_SUCCESS,
} from '../constants';

export default function language(state = {}, action) {
  switch (action.type) {

    case GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        availableLanguages: action.availableLanguages,
      };

    case GET_ALL_LANGUAGES_SUCCESS:
      return {
        ...state,
        allLanguages: action.allLanguages,
      };

    default:
      return state;
  }
}
