import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// Component
import Booking from '../../components/Booking';
import Loader from '../../components/Loader/Loader';

import getUserListingQuery from './UserListing.graphql';

import s from './Book.css';

class Book extends Component {

  render() {
		const { userListingData: { UserListing, loading } } = this.props;

    return (
      <div className={cx(s.container, s.overFlowHiddenMobile)}>
				{
					loading ?
					<Loader />
					: (
            <Booking 
              UserListing={UserListing}
            />
					)
				}
      </div>
    );
  }
}

export default compose(
  injectIntl,
	withStyles(s),
	graphql(getUserListingQuery,
		{
			name: 'userListingData',
      options: (props) => ({
        variables: {
          listId: props.listingId,
        },
        fetchPolicy: 'network-only',
				ssr: false
      })
    }
	),
)(Book);