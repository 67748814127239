import React from 'react';
import { compose } from 'react-apollo';
import { connect } from "react-redux";
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import LanguageManagement from '../../../components/siteadmin/LanguageManagement/LanguageManagement';
import s from './Language.css';

class Language extends React.Component {

  render () {
    const { languages } = this.props;
    return <LanguageManagement data={languages} />
  }
}

const mapState = (state) => ({
  languages: state?.language?.allLanguages,
});

export default compose(withStyles(s))(connect(mapState)(Language));