// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  Button,
  Row,
  Col,
  Form,
} from 'react-bootstrap';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

import { updateLanguage, deleteLanguage } from '../../../actions/siteadmin/LanguageManagement/languageManagement';
import validate from './validate';

import messages from '../../../locale/messages';

import s from './ManageLanguagesForm.css';
import bt from '../../../components/commonStyle.css';

class EditLanguageForm extends Component {

  static propTypes = {
    isEnabled: PropTypes.any,
    id: PropTypes.number,
    deleteLanguage: PropTypes.any,
  };

  constructor(props) {
    super(props);
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxLength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <label className={s.labelTextNew}>{label}</label>
        <Form.Control {...input} type={type} className={className} maxLength={maxLength} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    )
  }

  handleUpdateLanguage(e) {
    const { id, langCode, langName, isEnabled, updateLanguage } = this.props;
    updateLanguage({id, langCode, langName, isEnabled: ('0' !== isEnabled)});
  }

  render() {
    const { error, submitting, id, deleteLanguage, isEnabled } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.formMaxWidth, 'maxwidthcenter', 'empty', 'adminRadioBtn')}>
          {error && <strong>{formatMessage(error)}</strong>}
          <Form.Group className={s.space3}>
            <Field
              name="langCode"
              type="text"
              component={this.renderFormControl}
              label={formatMessage(messages.langCodeLabel)}
              className={cx(bt.commonControlInput)}
              maxLength={200}
            />
          </Form.Group>
          <Form.Group className={s.space3}>
            <Field
              name="langName"
              type="text"
              component={this.renderFormControl}
              label={formatMessage(messages.langNameLabel)}
              className={cx(bt.commonControlInput)}
              maxLength={200}
            />
          </Form.Group>
          <Form.Group className={s.space3}>
            <label className={cx(s.labelTextNew, s.btnUPdate, bt.curderPointer)}>
              <Field name="isEnabled" component="input" type="radio" value={1} checked={isEnabled == true} /> <span className={s.radioBtn}>{formatMessage(messages.enabledLabel)}</span>
            </label>
            <label className={cx(s.labelTextNew, s.btnModalDelete, bt.curderPointer, 'adminDelete')}>
              <Field name="isEnabled" component="input" type="radio" value={0} checked={isEnabled != true} /> <span className={s.radioBtn}>{formatMessage(messages.disabledLabel)}</span>
            </label>
          </Form.Group>
          <Form.Group className={cx(s.space3)}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} className={cx(bt.textAlignRight, 'textAlignLeftRtl')}>
                <div className={s.btnUPdate}>
                  <Button className={cx(bt.btnPrimary, s.btnWidth)} type="submit" disabled={submitting} onClick={() => this.handleUpdateLanguage()}>
                    {formatMessage(messages.update)}
                  </Button>
                </div>
                <div className={cx(s.btnModalDelete, 'adminUpdate')}>
                  <Button className={cx(bt.btnPrimaryBorder, s.btnWidth, 'bgBlack')} onClick={() => deleteLanguage(id)} disabled={submitting}>
                    {formatMessage(messages.delete)}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form.Group>
      </div>
    )
  }

}

EditLanguageForm = reduxForm({
  form: "EditLanguageForm",
  validate
})(EditLanguageForm);

const selector = formValueSelector("EditLanguageForm");
const mapState = (state) => ({
  id: selector(state, 'id'),
  langCode: selector(state, 'langCode'),
  langName: selector(state, 'langName'),
  isEnabled: selector(state, 'isEnabled'),
});

const mapDispatch = {
  updateLanguage,
  deleteLanguage,
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(EditLanguageForm)));