import { gql } from 'react-apollo';
import {
  GET_ALL_LANGUAGES_START,
  GET_ALL_LANGUAGES_SUCCESS,
  GET_ALL_LANGUAGES_ERROR,
  ADD_LANGUAGE_START,
  ADD_LANGUAGE_SUCCESS,
  ADD_LANGUAGE_ERROR,
  UPDATE_LANGUAGE_START,
  UPDATE_LANGUAGE_SUCCESS,
  UPDATE_LANGUAGE_ERROR,
  DELETE_LANGUAGE_START,
  DELETE_LANGUAGE_SUCCESS,
  DELETE_LANGUAGE_ERROR,
} from '../../../constants';

import { closeManageLanguagesModal } from '../modalActions';
import showToaster from '../../../helpers/showToaster';
import getAllLanguagesQuery from './getAllLanguages.graphql';

export function getAllLanguages() {

  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_ALL_LANGUAGES_START,
    });

    try {
      const { data } = await client.query({
        query: getAllLanguagesQuery,
      });

      if (data && data.getLanguages) {
        dispatch({
          type: GET_ALL_LANGUAGES_SUCCESS,
          allLanguages: data.getLanguages
        });
      }
      else {
        dispatch({
          type: GET_ALL_LANGUAGES_ERROR,
        });

        showToaster({ messageId: data.getLanguages.message ?? 'getLanguagesError', toasterType: 'error' })
        return false;
      }

    } catch (error) {
      dispatch({
        type: GET_ALL_LANGUAGES_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'getLanguagesError', toasterType: 'error' })
      return false;
    }
    return true;
  };
}

export function addLanguage({
  langCode,
  langName,
}) {

  return async (dispatch, getState, { client }) => {
    dispatch({
      type: ADD_LANGUAGE_START,
    });

    try {

      const query = gql`
        query addLanguage (
          $langCode: String,
          $langName: String,
        ){
          addLanguage(
            langCode: $langCode,
            langName: $langName,
          ){
            status,
            message
          }
        }
      `;

      const { data } = await client.query({
        query,
        variables: { 
          langCode,
          langName,
        },
      });

      if (data.addLanguage.status === "success") {
        dispatch({
          type: ADD_LANGUAGE_SUCCESS,
        });

        dispatch(closeManageLanguagesModal());
        // await dispatch(getAllLanguages());
        showToaster({ messageId: 'addLanguageSuccess', toasterType: 'success' })
      }
      else {
        dispatch({
          type: ADD_LANGUAGE_ERROR,
        });
        showToaster({ messageId: data.addLanguage.message ?? 'addLanguageError', toasterType: 'error' })
        return false;
      }

    } catch (error) {

      dispatch({
        type: ADD_LANGUAGE_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'addLanguageError', toasterType: 'error' })
      return false;
    }
    return true;
  };
}

export function updateLanguage({
  id,
  langCode,
  langName,
  isEnabled
}) {

  return async (dispatch, getState, { client }) => {
    dispatch({
      type: UPDATE_LANGUAGE_START,
    });

    try {

      const mutation = gql`
        mutation updateLanguage (
          $id: Int,
          $langCode: String,
          $langName: String,
          $isEnabled: Boolean
        ){
          updateLanguage(
            id: $id,
            langCode: $langCode,
            langName: $langName,
            isEnabled: $isEnabled
          ){
            status,
            message
          }
        }
      `;

      const { data } = await client.mutate({
        mutation,
        variables: { 
          id,
          langCode,
          langName,
          isEnabled
        },
        refetchQueries: [{ query: getAllLanguagesQuery }]
      });
      console.log('data resp', data);

      if (data.updateLanguage.status === "success") {
        dispatch({
          type: UPDATE_LANGUAGE_SUCCESS,
        });
        
        dispatch(closeManageLanguagesModal());
        showToaster({ messageId: 'updateLanguageSuccess', toasterType: 'success' })
      }
      else {
        dispatch({
          type: UPDATE_LANGUAGE_ERROR,
        });
        showToaster({ messageId: data.updateLanguage.message ?? 'updateLanguageError', toasterType: 'error' })
        return false;
      }

    } catch (error) {

      dispatch({
        type: UPDATE_LANGUAGE_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'updateLanguageError', toasterType: 'error' })
      return false;
    }
    return true;
  };
}

export function deleteLanguage(id) {

  return async (dispatch, getState, { client }) => {
    dispatch({
      type: DELETE_LANGUAGE_START,
    });

    try {
      const query = gql`
        query deleteLanguage (
          $id: Int,
        ){
          deleteLanguage(
            id: $id,
          ){
            status
          }
        }
      `;

      const { data } = await client.query({
        query,
        variables: { 
          id
        },
      });

      if (data.deleteLanguage.status === "success") {
        dispatch({
          type: DELETE_LANGUAGE_SUCCESS,
        });

        dispatch(closeManageLanguagesModal());
        showToaster({ messageId: 'deleteLanguageSuccess', toasterType: 'success' })
      }

    } catch (error) {

      dispatch({
        type: DELETE_LANGUAGE_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'deleteLanguageError', toasterType: 'error' })
      return false;
    }
    return true;
  };
}