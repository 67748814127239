import React from 'react';
import Layout from '../../components/Layout';
import messages from '../../locale/messages';
import AddedValues from './AddedValues';

export default function action({ query, intl }) {

  // const title = intl?.formatMessage(messages.addedValues);
  const title = 'EYGAR Added Values';

  let region = null;
  if ('city' in query && query.city) {
    region = query.city;
  }

  return {
    title,
    component:
      <Layout>
        <AddedValues
          region={region}
        />
      </Layout>,
  };
}