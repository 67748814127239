import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// Component
import CurrencyConverter from '../../CurrencyConverter';

import { calculateDayDifference } from '../../../helpers/calculateDayDifference';
import { handleReservationSpecialPricing } from '../../../helpers/handleReservationSpecialPricing';
import { handleReservationDiscount } from '../../../helpers/handleReservationDiscount';
import { calculateReservationServiceFee } from '../../../helpers/calculateReservationServiceFee';

// Locale
import messages from '../../../locale/messages';

import Faq from '../../../../public/SiteIcons/question.svg'

import s from './Payment.css';

class PaymentDetails extends Component {
  static propTypes = {
    serviceFees: PropTypes.shape({
      guest: PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    base: PropTypes.string.isRequired,
    rates: PropTypes.object.isRequired,
    formatMessage: PropTypes.any,
    specialPricingDays: PropTypes.array,
  };

  render() {
    const { serviceFees, base, rates, specialPricingDays, bookingFormValues, listingData } = this.props;
    const { formatMessage } = this.props.intl;

    let monthlyRentalToggled = listingData.isMonthlyRentalOnly || bookingFormValues.showMonthlyRental;
    if (
      (monthlyRentalToggled && !bookingFormValues.monthlyRentalCheckoutMonth)
      || (!monthlyRentalToggled && (!bookingFormValues.startDate || !bookingFormValues.endDate))
    ) {
      return null;
    }

    let dayDifference, subtotal, dayAveragePrice, specialPricingApplied, discountValue, discountType, guestServiceFee, total;
    if (monthlyRentalToggled) {
      subtotal = listingData.monthlyRentalPrice;
      guestServiceFee = calculateReservationServiceFee(subtotal, base, rates, listingData.currency, {
        type: serviceFees.monthlyRentalGuestType, value: serviceFees.monthlyRentalGuestValue, currency: serviceFees.currency
      });
      total = subtotal + guestServiceFee + listingData.monthlyRentalCleaningPrice;
    }
    else {
      dayDifference = calculateDayDifference(bookingFormValues.startDate, bookingFormValues.endDate);
      if (dayDifference <= 0) {
        return null;
      }

      ({ subtotal, dayAveragePrice, specialPricingApplied } = handleReservationSpecialPricing(bookingFormValues.startDate, listingData.basePrice, dayDifference, specialPricingDays));
      ({ discountValue, discountType } = handleReservationDiscount(subtotal, dayDifference, listingData.weeklyDiscount, listingData.monthlyDiscount));
      subtotal = subtotal - discountValue;

      guestServiceFee = calculateReservationServiceFee(subtotal, base, rates, listingData.currency, {
        type: serviceFees.guestType, value: serviceFees.guestValue, currency: serviceFees.currency
      });
      total = subtotal + guestServiceFee + listingData.cleaningPrice;
    }

    return (
      <div>
        <h3 className={cx(s.pricingTitle, 'rtlBookText')}><FormattedMessage {...messages.priceDetails} /></h3>
        <div className={cx(s.grid, 'textWhite')}>
          {
            monthlyRentalToggled ?
              <div className={cx(s.specialPriceText, 'directionLtr')}>
                Monthly Rent
              </div>
              : (
                <div>
                  <div className={s.specialPriceIcon}>
                    {
                      specialPricingApplied &&
                      <span className={s.iconSection}>
                        <img src={Faq} className={cx(s.faqImage, 'faqRtl')} />
                      </span>
                    }
                    <div className={cx(s.tltip, s.relativeSection, 'relativeSectionRTL')}>
                      <FormattedMessage {...messages.averagePricePerNight} />
                    </div>
                  </div>
                  <span className={cx(s.specialPriceText, 'directionLtrTextRight')}>
                    <CurrencyConverter
                      amount={dayAveragePrice}
                      from={listingData.currency}
                    />
                    {' x'} {dayDifference} {dayDifference > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}
                  </span>
                </div>
              )
          }
          <div>
            <CurrencyConverter
              amount={subtotal}
              from={listingData.currency}
            />
          </div>
        </div>
        {
          (!monthlyRentalToggled && (discountValue > 0)) &&
          <div className={cx(s.grid, 'textWhite', s.discountText)}>
            <div>
              {('weekly' === discountType) ? `${listingData.weeklyDiscount}% ${formatMessage(messages.weeklyDiscount)}` : ''}
              {('monthly' === discountType) ? `${listingData.monthlyDiscount}% ${formatMessage(messages.monthlyDiscount)}` : ''}
            </div>
            <div>
              -  <CurrencyConverter
                amount={discountValue}
                from={listingData.currency}
              />
            </div>
          </div>
        }
        {
          monthlyRentalToggled && (listingData.monthlyRentalCleaningPrice > 0) &&
          <div className={cx(s.grid, 'textWhite')}>
            <div><FormattedMessage {...messages.cleaningFee} /></div>
            <div>
              <CurrencyConverter
                amount={listingData.monthlyRentalCleaningPrice}
                from={listingData.currency}
              />
            </div>
          </div>
        }
        {
          !monthlyRentalToggled && (listingData.cleaningPrice > 0) &&
          <div className={cx(s.grid, 'textWhite')}>
            <div><FormattedMessage {...messages.cleaningFee} /></div>
            <div>
              <CurrencyConverter
                amount={listingData.cleaningPrice}
                from={listingData.currency}
              />
            </div>
          </div>
        }
        {
          (guestServiceFee > 0) &&
          <div className={cx(s.grid, 'textWhite')}>
            <div><FormattedMessage {...messages.serviceFee} /></div>
            <div>
              <CurrencyConverter
                amount={guestServiceFee}
                from={listingData.currency}
              />
            </div>
          </div>
        }
        {
          monthlyRentalToggled && (listingData.deposit > 0) &&
          <div className={cx(s.grid, 'textWhite')}>
            {/* <div><FormattedMessage {...messages.deposit} /></div> */}
            <div>Deposit</div>
            <div>
              <CurrencyConverter
                amount={listingData.deposit}
                from={listingData.currency}
              />
            </div>
          </div>
        }

        <div className={cx(s.grid, s.totalValue, 'textWhite')}>
          <div>
            <FormattedMessage {...messages.total} />
            <br />
            {
              monthlyRentalToggled &&
              <span style={{ fontSize: '12px' }}>&nbsp;{'(first month + deposit)'}</span>
            }
          </div>
          <div>
            <CurrencyConverter
              amount={total + listingData.deposit}
              from={listingData.currency}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  specialPricingDays: state.viewListing.specialPricing,
  rates: state.currency.rates,
  base: state.currency.base,
  serviceFees: state.book.serviceFees,
  bookingFormValues: getFormValues('BookingForm')(state),
});

export default injectIntl(withStyles(s)(connect(mapState)(PaymentDetails)));