import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {
  Button
} from "react-bootstrap";
import cx from "classnames";
import { change, submit, getFormValues } from "redux-form";
import { connect } from "react-redux";

import RoomType from "../../RoomType/RoomType";
import RoomsBeds from "./RoomsBeds";
import CheckboxListItems from "./CheckboxListItems";
import Price from "./Price";
import InstantBook from "./InstantBook";
import Guests from "../../Guests";
import Dates from "./Dates";
import SectionsAndActivities from "./SectionsAndActivities";

import messages from "../../../../../locale/messages";

import { openMoreFiltersModal, closeMoreFiltersModal } from "../../../../../actions/modalActions";
import { setPersonalizedValues } from "../../../../../actions/personalized";

import s from "./MoreFiltersModalBody.css";

class MoreFiltersModalBody extends Component {
  static propTypes = {
    className: PropTypes.any,
    handleTabToggle: PropTypes.any,
    isExpand: PropTypes.bool,
  };

  static defaultProps = {
    isExpand: false,
    fieldsSettingsData: {
      roomType: [],
      essentialsAmenities: [],
      spaces: [],
      houseRules: [],
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      tabs: {
        dates: false,
        guests: false,
        price: false,
        instantBook: false,
        moreFilters: false,
      },
      overlay: false,
    };
  }

  componentDidMount() {
    const { isExpand } = this.props;
    if (isExpand) {
      document.querySelector("body").setAttribute("style", "overflow: hidden");
    }
  }

  componentWillUnmount() {
    document.querySelector("body").removeAttribute("style");
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isExpand } = nextProps;
    if (isExpand) {
      document.querySelector("body").setAttribute("style", "overflow: hidden");
    } else {
      document.querySelector("body").removeAttribute("style");
    }
  }

  handleTabToggle = (currentTab, isExpand) => {
    const { tabs } = this.state;
    for (let key in tabs) {
      if (key == currentTab) {
        tabs[key] = isExpand;
      } else {
        tabs[key] = false;
      }
    }

    this.setState({
      tabs,
      overlay: isExpand,
    });
  };

  handleSubmitForm = async () => {
    const { closeMoreFiltersModal, submit, change } = this.props;
    await change('SearchForm', "currentPage", 1);
    submit('SearchForm');
    closeMoreFiltersModal();
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleOpen = async () => {
    const { openMoreFiltersModal } = this.props;
    openMoreFiltersModal();
  };

  
  handleReset = async () => {
    const { change, setPersonalizedValues, fieldsSettingsData} = this.props;

    await Promise.all([
      change('SearchForm', "amenities", []),
      change('SearchForm', "spaces", []),
      change('SearchForm', "houseRules", []),
      change('SearchForm', "privateBathrooms", 0),
      change('SearchForm', "sharedBathrooms", 0),
      change('SearchForm', "bedrooms", 0),
      change('SearchForm', "beds", 0),
      change('SearchForm', "bookingType", ""),
      change('SearchForm', "priceRange", null),
      change('SearchForm', "priceRangeLabel", null),
      change('SearchForm', "roomType", []),
      change('SearchForm', "dates", null),
      change('SearchForm', "hostLanguages", null),
      change('SearchForm', "section", null),
      change('SearchForm', "activity", null),
    ]);

    setPersonalizedValues({ name: 'startDate', value: null });
    setPersonalizedValues({ name: 'endDate', value: null });
    setPersonalizedValues({ name: 'isOneTotalToggle', value: false });
    setPersonalizedValues({ name: 'totalPrice', value: false });

    if (fieldsSettingsData?.personCapacity?.length > 0 && fieldsSettingsData?.personCapacity[0]?.startValue) {
      change('SearchForm', "personCapacity", fieldsSettingsData?.personCapacity[0]?.startValue);
    } 
    else {
      change('SearchForm', "personCapacity", 0);
    }
  };

  organizeLanguagesForCheckbox = () =>  {
    const { languages } = this.props;

    languages.forEach(language => {
      language.itemName = 'language_' + language.langCode;
      language.isEnable = '1';
    });

    let indexToMove = -1;
    languages.forEach((language, index) => {
      language.itemName = 'language_' + language.langCode;
      language.isEnable = '1';

      if (language.langCode === 'en') {
        indexToMove = index;
      }
    });

    if (indexToMove > 0) {
      const [englishLanguage] = languages.splice(indexToMove, 1);
      languages.unshift(englishLanguage);
    }

    return languages;
  }

  render() {
    const { className, formValues, searchSettings, smallDevice } = this.props;
    const { fieldsSettingsData: { essentialsAmenities, spaces, houseRules } } = this.props;
    const { formatMessage } = this.props.intl;
    const { tabs } = this.state;

    return (
      <div className={className} ref={this.setWrapperRef}>
        <div className={s.filterSection}>
          { 
            (smallDevice) &&
            <>
              <Dates
                handleTabToggle={this.handleTabToggle}
                isExpand={tabs?.dates}
                smallDevice={smallDevice}
              />
              <hr className={s.divider} />
              <Guests />
              <hr className={s.divider} />
              <RoomType 
                smallDevice={smallDevice}
              />
              <hr className={s.divider} />
            </>
          }
          <Price
            className={cx(s.filters, s.space4)}
            searchSettings={searchSettings}
            smallDevice={smallDevice}
          />
          <InstantBook
            className={cx(s.filters, s.space4)}
          />
          <RoomsBeds className={cx(s.filters, s.space4)} />
          <SectionsAndActivities
            className={cx(s.filters, s.space4)} 
            sections={searchSettings.sections}
          />
          <CheckboxListItems
            className={cx(s.filters, s.space4)}
            fieldName={"amenities"}
            options={essentialsAmenities}
            captionTitle={formatMessage(messages.amenities)}
            showLabel={formatMessage(messages.header_more_filters_show_more_button_label)}
            hideLabel={formatMessage(messages.header_more_filters_show_less_button_label)}
            isActive={formValues?.amenities?.length > 0}
            showImage={true}
          />
          <CheckboxListItems
            className={cx(s.filters, s.space4)}
            fieldName={"spaces"}
            options={spaces}
            captionTitle={formatMessage(messages.sharedSpaces)}
            showLabel={formatMessage(messages.header_more_filters_show_more_button_label)}
            hideLabel={formatMessage(messages.header_more_filters_show_less_button_label)}
            isActive={formValues?.spaces?.length > 0}
            showImage={true}
          />
          <CheckboxListItems
            className={cx(s.filters, s.space4)}
            fieldName={"houseRules"}
            options={houseRules}
            captionTitle={formatMessage(messages.houseRules)}
            showLabel={formatMessage(messages.header_more_filters_show_more_button_label)}
            hideLabel={formatMessage(messages.header_more_filters_show_less_button_label)}
            isActive={formValues?.houseRules?.length > 0}
          />
          <CheckboxListItems
            fieldName={"hostLanguages"}
            options={this.organizeLanguagesForCheckbox()}
            captionTitle={formatMessage(messages.header_more_filters_host_languages_title)}
            showLabel={formatMessage(messages.header_more_filters_show_more_button_label)}
            hideLabel={formatMessage(messages.header_more_filters_show_less_button_label)}
            isActive={formValues?.hostLanguages?.length > 0}
          />
        </div>
        <div className={s.footerSection}>
          <Button
            variant="link"
            className={cx(s.btnLink, s.linkText, "bgBlack")}
            onClick={() => this.handleReset()}
          >
            <FormattedMessage {...messages.header_more_filters_modal_clear_button_label} />
          </Button>
          <Button
            className={cx(s.btn, s.btnSecondary, s.applyBtn)}
            onClick={this.handleSubmitForm}
          >
            <FormattedMessage {...messages.applyFilters} />
          </Button>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  fieldsSettingsData: state?.listingFields?.data,
  formValues: getFormValues("SearchForm")(state),
  languages: state?.language?.availableLanguages,
});

const mapDispatch = {
  change,
  submit,
  openMoreFiltersModal,
  closeMoreFiltersModal,
  setPersonalizedValues
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(MoreFiltersModalBody)));
