import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ToggleableFooter from '../toggleableFooter/toggleableFooter';
import CookiesDisclaimer from '../CookiesDisclaimer/CookiesDisclaimer';

import s from './Layout.css';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    searchSettings: PropTypes.object,
  };

  static defaultProps = {
    searchSettings: {},
  };

  render() {
    const { page, searchSettings, guests } = this.props;
    return (
      <>
        <Header 
          page={page}
          searchSettings={searchSettings} 
          guests={guests}
        />

        {this.props.children}

        {
          ('search' == page) ?
          <ToggleableFooter />
          : <Footer />
        }

        <CookiesDisclaimer />
      </>
    );
  }
}

export default withStyles(s)(Layout);
