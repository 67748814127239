import { gql } from 'react-apollo';
import moment from 'moment';

import {
  GET_AVAILABLE_MONTHS_FOR_MONTHLY_RENTAL_START,
  GET_AVAILABLE_MONTHS_FOR_MONTHLY_RENTAL_SUCCESS,
  GET_AVAILABLE_MONTHS_FOR_MONTHLY_RENTAL_ERROR
} from '../constants';

const query = gql`
    query ($listId:Int!,  $startDate: String!) {
    MonthsAvailabilityForMonthlyRental (listId:$listId, startDate:$startDate) {
      availableMonths
    }
  }
`;

export function getAvailableMonthsForMonthlyRental(listId, startDate) {
  return async (dispatch, getState, { client }) => {

    dispatch({
      type: GET_AVAILABLE_MONTHS_FOR_MONTHLY_RENTAL_START,
      isLoading: true,
      availableMonths: 0,
    });

    try {

      // Send Request to get available months
      const { data } = await client.query({
        query,
        variables: { listId, startDate },
        fetchPolicy: 'network-only',
      });

      if (data && data.MonthsAvailabilityForMonthlyRental) {
        let availableMonths = data.MonthsAvailabilityForMonthlyRental.availableMonths;
        dispatch({
          type: GET_AVAILABLE_MONTHS_FOR_MONTHLY_RENTAL_SUCCESS,
          isLoading: false,
          availableMonths,
        });

        return availableMonths;
      }
      else {
        dispatch({
          type: GET_AVAILABLE_MONTHS_FOR_MONTHLY_RENTAL_ERROR,
          isLoading: false,
          availableMonths: 0
        });

        return 0;
      }
    } catch (error) {
      dispatch({
        type: GET_AVAILABLE_MONTHS_FOR_MONTHLY_RENTAL_ERROR,
        payload: {
          error,
        },
        isLoading: false,
      });

      return 0;
    }
  };
}
