import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { change, submit } from 'redux-form';

import MoreFiltersModalBody from './MoreFiltersModalBody/MoreFiltersModalBody';

import messages from '../../../../locale/messages';
import { closeMoreFiltersModal } from '../../../../actions/modalActions';
import { setPersonalizedValues } from "../../../../actions/personalized";

import s from './MoreFiltersModal.css';

class MoreFiltersModal extends Component {
  static propTypes = {
    filtersModal: PropTypes.bool,
    closeMoreFiltersModal: PropTypes.func,
  };

  handleModalClose = async () => {
    const { closeMoreFiltersModal, change, submit } = this.props;
    await change('SearchForm', "currentPage", 1);
    submit('SearchForm');
    closeMoreFiltersModal();
  };

  render() {
    const { handleTabToggle, isExpand, searchSettings, filtersModal, showFilter, showResults, smallDevice } = this.props;
    return (
      <div>
        <Modal
          show={filtersModal}
          animation={false}
          onHide={this.handleModalClose}
          dialogClassName={cx(s.logInModalContainer, 'moreFilterModal', 'moreFilter')}
          backdrop="static"
        >
          <Modal.Header closeButton className={cx(s.panelHeader, 'd-flex justify-content-between')}>
            <Modal.Title>
              <span><FormattedMessage {...messages.filters} /></span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.logInModalBody}>
            <MoreFiltersModalBody
              handleTabToggle={handleTabToggle}
              isExpand={isExpand}
              searchSettings={searchSettings}
              showFilter={showFilter}
              showResults={showResults}
              handleReset={this.handleReset}
              smallDevice={smallDevice}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapState = state => ({
  filtersModal: state?.modalStatus?.isMoreFiltersModal,
  userData: state?.account?.data,
  fieldsSettingsData: state?.listingFields?.data
});

const mapDispatch = {
  closeMoreFiltersModal,
  change,
  submit,
  setPersonalizedValues,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(MoreFiltersModal)));