import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ReservationTypeToggle.css';
import cx from 'classnames';

import Switch from '../../Switch/Switch';

import messages from '../../../locale/messages';

class ReservationTypeToggle extends Component {

  render() {
    const { showMonthlyRental, isMonthlyRentalOnly, onReservationTypeToggle } = this.props;
    const { formatMessage } = this.props.intl;

    let initialToggleState = showMonthlyRental;
    if (isMonthlyRentalOnly) {
      initialToggleState = true;
    }

    return (
      <div className={cx(s.displayFlex, 'justify-content-between mt-3')}>
        <div>
          <p className={cx(s.moreFilterTitle, s.textBold, 'mb-0')}>
            {
              isMonthlyRentalOnly ?
                <FormattedMessage {...messages.monthly_rental_only} />
                : (
                  <p style={{ whiteSpace: 'pre-line', lineHeight: '15px' }}>
                    {
                      !initialToggleState ?
                        formatMessage(messages.toggle_on_monthly_rental)
                        : formatMessage(messages.toggle_off_monthly_rental)
                    }
                  </p>
                )
            }
          </p>
        </div>
        <div className={cx('searchSwitch', 'directionLtr', 'textAlignLeftRtl')}>
          <Switch
            checked={initialToggleState}
            disabled={isMonthlyRentalOnly}
            onToggle={() => onReservationTypeToggle()}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(ReservationTypeToggle));