import React, { Component } from 'react';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Elements } from 'react-stripe-elements';
import { formValueSelector } from 'redux-form';
import {
  Row,
  Col,
} from "react-bootstrap";

// Component
import PaymentDetails from './PaymentDetails';
import PaymentForm from './PaymentForm';
import ListCoverPhoto from '../../ListCoverPhoto';
import StarRating from '../../StarRating';

// Locale
import messages from '../../../locale/messages';

import s from './Payment.css';
class Payment extends Component {

  render() {
    const { UserListing, showMonthlyRental } = this.props;
    const { formatMessage } = this.props.intl;

    let listType = UserListing.settingsData[0]?.listsettings?.itemName;
    let monthlyRentalToggled = UserListing.listingData.isMonthlyRentalOnly || showMonthlyRental;
    let cancellationData = UserListing.listingData.cancellation;

    let starRatingValue = 0;
    if (UserListing.reviewsCount > 0 && UserListing.reviewsStarRating > 0) {
      starRatingValue = Math.round(UserListing.reviewsStarRating / UserListing.reviewsCount)
    }

    return (
      <Row>
        <Col lg={7} md={7}>
          <Elements>
            <PaymentForm
              UserListing={UserListing}
            />
          </Elements>
        </Col>
        <Col lg={5} md={5} className={s.positionSticky}>
          <div className={cx(s.summaryCard, s.colCenter)}>
            <div className={s.postionRelative}>
              <div className={s.secondSection}>
                <div className={cx(s.displayInline, s.avatarWidth, s.vtrMiddle, s.vtrTopSm, "avatarWidthPaymentRTL")}>
                  <ListCoverPhoto
                    className={cx(s.bannerImage, s.backgroundCover)}
                    coverPhoto={UserListing.coverPhoto}
                    listPhotos={UserListing.listPhotos}
                    photoType={"x_medium"}
                    bgImage
                  />
                </div>
                <div className={cx(s.displayInline, s.contentWidth, s.vtrMiddle)}>
                  {starRatingValue > 0 && (
                    <div className={s.reviewFlex}>
                      <div className={s.reviewFlex}>
                        <span className={cx(s.starCss, "starCssRTL")}>
                          <StarRating name={"review"} value={starRatingValue} />
                        </span>
                        <span>{starRatingValue}</span>
                      </div>
                      <span className={s.dotsCss}>&#8226;</span>
                      <span>
                        {UserListing.reviewsCount}{" "}
                        {UserListing.reviewsCount > 1 ? (
                          <FormattedMessage {...messages.reviews} />
                        ) : (
                          <FormattedMessage {...messages.review} />
                        )}
                      </span>
                    </div>
                  )}
                  <div className={cx(s.textLarge, s.colorWhite, s.propertyText)}>
                    <span>{UserListing.title}</span>
                  </div>
                  <div className={cx(s.textMuted, s.colorWhite, s.listItem, "textWhite")}>
                    <ul className={cx(s.listStyle, "listStyleRTL")}>
                      <li>
                        {listType && formatMessage(messages[listType])}
                        {UserListing.beds > 0 && (
                          <span>
                            &nbsp;-&nbsp;{UserListing.beds}&nbsp;
                            {(UserListing.beds > 1) ? formatMessage(messages.beds) : formatMessage(messages.bed)}
                          </span>
                        )}
                      </li>
                    </ul>
                    <div className={s.colorWhite}>
                      {UserListing.city}, {UserListing.state}, {UserListing.country}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <PaymentDetails
              listingData={UserListing.listingData}
            />
          </div>

          <div className={s.policyCss}>
            {
              monthlyRentalToggled ?
              <span className={s.checkInText}>Free cancellation if before starting in one week or before one month if started.</span>
              : (
                <>
                  <span className={s.checkInText}>
                    <FormattedMessage {...messages.cancellationPolicy} />:{" "}
                  </span>
                  {
                    cancellationData &&
                    <>
                      <a href={"/cancellation-policies/" + cancellationData.policyName} target="_blank" className={s.policyColor}>
                        <FormattedMessage {...messages[cancellationData.policyName]} />
                      </a>
                      <div className={cx(s.checkInText)}><FormattedMessage {...messages[cancellationData.policyContent]} /></div>
                    </>
                  }
                </>
              )
            }
          </div>
        </Col>
      </Row>
    );
  }
}

const selector = formValueSelector('BookingForm');
const mapState = (state) => ({
  showMonthlyRental: selector(state, 'showMonthlyRental'),
});

export default injectIntl(withStyles(s)(connect(mapState)(Payment)));