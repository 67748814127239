// Plugins.
import React from 'react';
import moment from 'moment';
import { print } from 'graphql/language/printer';
import { initialize } from 'redux-form';

// Components.
import Layout from '../../components/Layout';
import ViewListing from './ViewListing';
import NotFound from '../notFound/NotFound';

// Action and helpers.
import fetch from '../../core/fetch';
import { getSpecialPricingData, getListBlockedDates, getFullBlockDates } from '../../actions/Listing/getSpecialPricingData';
import { checkAvailability } from '../../actions/checkAvailability';
import { getListingFields } from '../../actions/getListingFields';
import { setPersonalizedValues } from '../../actions/personalized'
import { getServiceFees } from '../../actions/ServiceFees/getServiceFees.js';

import messages from '../../locale/messages';
import { siteUrl, fileuploadDir } from '../../config.js';
import getListMetaQuery from './getListMeta.graphql';
import { getAvailableMonthsForMonthlyRental } from '../../actions/getMonthsForRental.js';

const title = 'View Listing';
function renderNotFound() {
  return {
    title,
    component: <Layout><NotFound title={title} /></Layout>,
    status: 404,
  };
}

export default async function action({ params, store, query, intl, client }) {

  const title = intl?.formatMessage(messages.viewListing);

  let preview = false;
  if (params.preview) {
    preview = true;
  }

  let listURL = params.listId;
  let listId = null;
  if (listURL && listURL.indexOf('-') >= 0) {
    let listURLData = listURL.split('-');
    listId = Number(listURLData[listURLData.length - 1]);
  }
  else {
    listId = Number(listURL);
  }

  if (!listId) {
    renderNotFound();
    return;
  }

  const resp = await fetch('/graphql', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: print(getListMetaQuery),
      variables: { listId }
    }),
  });
  const { data } = await resp.json();

  const isAdmin = store.getState().runtime.isAdminAuthenticated;
  if ((!data?.getListMeta) || (!data.getListMeta.isPublished && !preview && !isAdmin)) {
    renderNotFound();
    return;
  }

  if ('address' in query) {
    store.dispatch(setPersonalizedValues({ name: 'location', value: query.address }));
  }

  let guests = 1;
  if ('guests' in query) {
    guests = Number(query.guests);
  }

  let listingData = data.getListMeta.listingData;
  let showMonthlyRental = false;
  if (((listingData.isMonthlyRentalOnly === 1) || (listingData.monthlyRentalPrice > 0)) && ('monthly' in query && ('1' === query.monthly))) {
    showMonthlyRental = true;
  }

  let today = moment(new Date()).format("YYYY-MM-DD");

  let startDate = null;
  if ('startdate' in query) {
    startDate = moment(query.startdate).format("YYYY-MM-DD");
    if (!moment(startDate).isValid() || startDate < today) {
      startDate = query.startdate = null;
    }
    else {
      await store.dispatch(getAvailableMonthsForMonthlyRental(listId, startDate));
    }
  }
  
  let monthlyRentalCheckoutMonth = null;
  if (showMonthlyRental && startDate && ('checkoutmonth' in query)) {
    monthlyRentalCheckoutMonth = Number(query.checkoutmonth);
  }

  let endDate = null;
  if (!showMonthlyRental && startDate && ('enddate' in query)) {

    endDate = moment(query.enddate).format("YYYY-MM-DD");
    if (!moment(endDate).isValid() || (endDate <= startDate)) {
      endDate = query.enddate = null;
    }
    else {
      await store.dispatch(getSpecialPricingData(listId, startDate, endDate));
      await store.dispatch(checkAvailability(listId, startDate, endDate, listingData.maxNight || 0, listingData.minNight || 0));
    }
  }

  store.dispatch(initialize('BookingForm', {
    startDate,
    endDate,
    guests,
    showMonthlyRental,
    monthlyRentalCheckoutMonth
  }));

  await store.dispatch(getListingFields());
  await store.dispatch(getListBlockedDates(listId, preview))
  await store.dispatch(getFullBlockDates(listId, preview))
  await store.dispatch(getServiceFees());
  
  let listPhoto = '';
  if (data.getListMeta.listPhotos?.length > 0) {
    let coverImage = data.getListMeta.listPhotos.find(o => o.id == data.getListMeta.coverPhoto);
    listPhoto = siteUrl + fileuploadDir.replace('.', "") + (coverImage ? coverImage.name : data.getListMeta.listPhotos[0].name);
  }

  return {
    title: data.getListMeta.title || title,
    description: data.getListMeta.description || '',
    image: listPhoto,
    component:
      <Layout
        page='viewListing'
        guests={guests} 
      >
        <ViewListing
          title={title}
          listId={listId}
          preview={preview}
          lat={data.getListMeta.lat}
          lng={data.getListMeta.lng}
        />
      </Layout>,
  };
}
