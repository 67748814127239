import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'react-apollo';

// Redux
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './UserReservationInfo.css';

// Locale
import messages from '../../locale/messages';

class UserReservationInfo extends Component {

  static propTypes = {
    formatMessage: PropTypes.any,
  };

  static defaultProps = {
  };

  render() {
    const { reservationInfo } = this.props;

    return (
      <div>
        {/* <h3 className={s.titleText}>{formatMessage(messages.)}</h3> */}
        <h3 className={s.titleText}>Current Reservation</h3>
        <p>Check-In: {reservationInfo.checkIn}</p>
        <p>Check-Out: {reservationInfo.checkOut}
          {
            (reservationInfo.isMonthly) &&
            <span>{' (' + reservationInfo.checkoutMonth + ' months)'}</span>
          }
        </p>
        {
          reservationInfo.stripePaymentLink &&
          <div>
            <p>Next month payment link (available for 3 days only)</p>
            <a href={reservationInfo.stripePaymentLink} target="_blank">{reservationInfo.stripePaymentLink}</a>
          </div>
        }
      </div>
    );
  }
}

const mapState = (state) => ({
});

const mapDispatch = {
};

export default compose(
  injectIntl,
  withStyles(s),
  connect(mapState, mapDispatch),
)(UserReservationInfo);