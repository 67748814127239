import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import { connect } from "react-redux";
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Field, reduxForm, reset, getFormValues } from "redux-form";
import {
  injectStripe,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from 'react-stripe-elements';
import {
  Row,
  Col,
  Form
} from "react-bootstrap";

// Component
import HouseRules from './HouseRules';
import Loader from '../../Loader';
import Link from '../../Link';
import ModalForm from './ModalForm/ModalForm';

// Locale
import { makePayment } from '../../../actions/booking/makePayment';
import { processCardAction } from '../../../actions/PaymentIntent/processCardAction';
import { openPaymentModal } from '../../../actions/modalActions';
import { checkAvailability } from "../../../actions/checkAvailability";
import { getAvailableMonthsForMonthlyRental } from "../../../actions/getMonthsForRental";

// Helpers
import validate from './validate';
import { isRTL } from '../../../helpers/formatLocale'
import showToaster from "../../../helpers/showToaster";

//Images 
import imageOne from '../../../../public/SiteIcons/payment-icons.png';
import imageTwo from '../../../../public/SiteIcons/stripe-connect.png';
import arrow from '../../../../public/SiteIcons/chevron-right.svg';
import defaultPic from '../../../../public/SiteImages/defaultPic.png';

import messages from '../../../locale/messages';

import bt from '../../../components/commonStyle.css';
import s from './Payment.css';

const createOptions = (theme, isRTLLocale) => {
  return {
    style: {
      base: {
        color: theme == "dark" ? "#fff" : "#282828",
        fontWeight: 400,
        fontFamily: "inherit",
        fontSize: "14px",
        textAlign: isRTLLocale ? "right" : "left",
        fontSmoothing: "antialiased",
        ":focus": {
          color: theme == "dark" ? "#fff" : "#282828",
        },

        "::placeholder": {
          color: "#aaa",
        },

        ":focus::placeholder": {
          color: "#aaa",
        },
      },
      invalid: {
        color: theme == "dark" ? "#fff" : "#282828",
        ":focus": {
          color: theme == "dark" ? "#fff" : "#282828",
        },
        "::placeholder": {
          color: "#aaa",
        },
      },
    },
  };
};

class PaymentForm extends Component {

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className, disabled }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <Form.Select
          disabled={disabled}
          {...input}
          className={className}
        >
          {children}
        </Form.Select>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </div>
    );
  };

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <Form.Control
          {...input}
          className={className}
          as="textarea"
          placeholder={label}
        >
          {children}
        </Form.Control>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </div>
    );
  };


  renderPaymentCurrencies() {
    const { paymentCurrencyList } = this.props;
    let rows = [];

    if (paymentCurrencyList && paymentCurrencyList.length > 0) {
      paymentCurrencyList.map((item, index) => {
        if (item.isEnable && item.isPayment) {
          rows.push(<option key={index} value={item.symbol}>{item.symbol}</option>);
        }
      });
    }

    return rows;
  };

  renderFormControl = ({ input, label, type, placeholder, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <Form.Control
          {...input}
          placeholder={placeholder}
          type={type}
          className={className}
          maxLength={11}
        />
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </div>
    );
  };

  // handleCancelButtonClick = () => {
  //   const { dispatch } = this.props;
  //   dispatch(reset("BookingForm"));
  // };

  handleSubmit = async (values, dispatch) => {
    console.log('handleSubmit() values', values);

    const { stripe, processCardAction, UserListing, account, getAvailableMonthsForMonthlyRental, checkAvailability } = this.props;

    let paymentType = values.paymentType ?? '2';

    let paymentCurrency = null;
    if (paymentType === '1') {
      paymentCurrency = values.paymentCurrency;
    }

    let validForReservation = false;
    if (values.showMonthlyRental) {
      let availableMonths = await getAvailableMonthsForMonthlyRental(UserListing.id, values.startDate);
      if (
        (availableMonths > 0) 
        && (values.monthlyRentalCheckoutMonth <= availableMonths)
        && (values.monthlyRentalCheckoutMonth >= UserListing.listingData.minMonthlyRental)
      ) {
        validForReservation = true;
      }
    }
    else {
      validForReservation = await checkAvailability(UserListing.id, values.startDate, values.endDate);
    }

    if (!validForReservation) {
      showToaster({ messageId: 'checkAvailableDates', toasterType: 'error' });
      return;
    }

    let paymentMethodId;
    if (paymentType === '2') {
      let paymentMethodCreationParam2 = {
        card: <CardElement />,
      };
      console.log('API stripe.createPaymentMethod param2', paymentMethodCreationParam2);
      const createPaymentMethod = await stripe?.createPaymentMethod("card", paymentMethodCreationParam2);
      console.log('API stripe.createPaymentMethod resp', createPaymentMethod);

      if (createPaymentMethod?.error?.message) {
        showToaster({
          messageId: 'commonError',
          toasterType: 'error',
          requestContent: createPaymentMethod.error.message
        });

        return;
      }
      else if (createPaymentMethod?.paymentMethod) {
        paymentMethodId = createPaymentMethod.paymentMethod.id
      }
    }

    const { status, paymentIntentSecret, reservationId, total, currency } = await dispatch(makePayment(
      UserListing.id,
      UserListing.title,
      values.showMonthlyRental ? 'monthly' : 'nightly',
      values.startDate,
      values.endDate || null,
      values.monthlyRentalCheckoutMonth || null,
      values.guests,
      values.message,
      UserListing.bookingType,
      paymentCurrency,
      paymentType,
      account.email,
      paymentMethodId,
    ));
    console.log('after dispatch makePayment', { status, paymentIntentSecret, reservationId, total, currency });

    if (status == 400 && paymentType === '2') {

      console.log('API stripe.handleCardAction params', paymentIntentSecret);
      const cardAction = await stripe.handleCardAction(paymentIntentSecret);
      console.log('API stripe.handleCardAction resp', cardAction);

      if (cardAction?.paymentIntent && cardAction?.paymentIntent?.id) {
        const { handleCardActionStatus, errorMessage } = await processCardAction(
          reservationId,
          UserListing.id,
          UserListing.userId,
          account.userId,
          UserListing.title,
          account.email,
          total,
          currency,
          cardAction.paymentIntent.id
        );
        console.log('after processCardAction', { handleCardActionStatus, errorMessage });
      }
      else {
        console.log('from elseee', cardAction);
        if (cardAction?.error && cardAction?.error?.message) {
          showToaster({
            messageId: 'commonError',
            toasterType: 'error',
            requestContent: cardAction?.error?.message
          });
        }
      }
    }
    else {
      console.log('from else status', status);
    }
  };

  render() {
    const { UserListing, bookValues, bookingFormValues, maximumStayExceeded, minimumStayNotReached, theme, handleSubmit, openPaymentModal, submitting, error } = this.props;
    const { formatMessage, locale } = this.props.intl;

    let monthlyRentalToggled = UserListing.listingData.isMonthlyRentalOnly || bookingFormValues.showMonthlyRental;

    let startDateToDisplay = '', endDateToDisplay = '';
    if (bookingFormValues.startDate) {

      startDateToDisplay = moment(bookingFormValues.startDate).format("DD/MM/YYYY");
      if (monthlyRentalToggled) {
        if (bookingFormValues.monthlyRentalCheckoutMonth) {
          let daysToAdd = 30 * Number(bookingFormValues.monthlyRentalCheckoutMonth);
          let tentativeEndDate = moment(bookingFormValues.startDate).add(daysToAdd, 'days');
          endDateToDisplay = `${moment(tentativeEndDate).format("DD/MM/YYYY")} (${bookingFormValues.monthlyRentalCheckoutMonth} months)`;
        }
      }
      else if (bookingFormValues.endDate) {
        endDateToDisplay = moment(bookingFormValues.endDate).format("DD/MM/YYYY");
      }
    }

    let hostData = UserListing.user.profile;

    let submitButtonIsDisabled = false;
    if (
      (submitting || error || !bookingFormValues.message)
      || (!monthlyRentalToggled && (maximumStayExceeded || minimumStayNotReached || !bookingFormValues.startDate || !bookingFormValues.endDate))
      || (monthlyRentalToggled && (!bookingFormValues.startDate || !bookingFormValues.monthlyRentalCheckoutMonth))
      || ((bookingFormValues.paymentType === '1') && !bookingFormValues.paymentCurrency)
    ) {
      submitButtonIsDisabled = true;
    }

    return (
      <div className={cx(s.bookItPanel, s.spaceTop2, s.aboutNoMargin, "customRatioButton")}>
        <ModalForm UserListing={UserListing} />

        <form onSubmit={handleSubmit(this.handleSubmit)}>

          <h1 className={s.titleText}>{monthlyRentalToggled ? 'Monthly Rental Reservation' : 'Reservation per Night'}</h1>
          {/* <p><FormattedMessage {...messages.reviewAndPay} /></p> */}

          {
            !bookValues.bookDetails?.preApprove &&
            <div>
              <div className={s.flex}>
                <div>
                  <div className={s.dateTitle}>
                    <FormattedMessage {...messages.dates} />
                  </div>
                  <div className={cx(s.showDate, "textWhite")}>
                    <span className={s.dateInline}>
                      <p>Check In</p> {startDateToDisplay}
                    </span>
                    <span className={s.dateInline}>
                      <p>{monthlyRentalToggled ? 'Estimated Check Out' : 'Check Out'}</p> {endDateToDisplay}
                    </span>
                  </div>
                </div>

                {
                  !bookValues.restrictEdit &&
                  <div>
                    <div>
                      <a
                        onClick={() => openPaymentModal()}
                        className={s.editCss}
                      >
                        <span>
                          <FormattedMessage {...messages.editLabel} />
                        </span>{" "}
                        <span className={cx(s.editIcon, "editIconPayRTL")}>
                          <img src={arrow} />
                        </span>
                      </a>
                    </div>
                  </div>
                }
              </div>
              <div className={cx(s.flex, s.marginTop)}>
                <div>
                  <div className={s.dateTitle}>
                    <FormattedMessage {...messages.guests} />
                  </div>
                  <div className={cx(s.showDate, "textWhite")}>
                    {bookingFormValues.guests}{" "}
                    {bookingFormValues.guests > 1 ? (
                      <FormattedMessage {...messages.guests} />
                    ) : (
                      <FormattedMessage {...messages.guest} />
                    )}
                  </div>
                </div>
                {
                  !bookValues.restrictEdit &&
                  <div>
                    <div>
                      <a onClick={() => openPaymentModal()} className={s.editCss}>
                        <span>
                          <FormattedMessage {...messages.editLabel} />
                        </span>{" "}
                        <span className={cx(s.editIcon, "editIconPayRTL")}>
                          <img src={arrow} />
                        </span>
                      </a>
                    </div>
                  </div>
                }
              </div>
              <div className={s.commonBorder}></div>
            </div>
          }

          {
            (UserListing.houseRules?.length > 0) &&
            <div className={s.space4}>
              <HouseRules
                hostDisplayName={hostData.firstName}
                houseRules={UserListing.houseRules}
              />
              <div className={s.commonBorder}></div>
            </div>
          }

          <div className={cx(s.textLeft, "textAlignRightRtl")}>
            <div className={cx(s.h3, s.bold)}>
              <FormattedMessage {...messages.aboutYourTrip} />
            </div>
            <div className={s.aboutPaymentDesc}>
              <FormattedMessage {...messages.aboutDescPayment} />
            </div>
            <div className={s.hostFlex}>
              <Link to={"/users/show/" + hostData.profileId}>
                <img src={hostData.picture ? "/images/avatar/medium_" + hostData.picture : defaultPic} className={s.avatarImage} />
              </Link>
              <div className={cx(s.messageSection)}>
                <span>
                  <FormattedMessage {...messages.hostedBy} />
                </span>{" "}
                <span>{hostData.firstName}</span>
              </div>
            </div>
            <div>
              <Field
                className={s.textArea}
                name="message"
                component={this.renderFormControlTextArea}
                label={formatMessage(messages.descriptionInfo)}
              />
            </div>
            <div className={s.commonBorder}></div>
          </div>
          <Col md={10} className={cx(s.textLeft, "textAlignRightRtl", s.noPadding)}>
            <section>
              <header className={s.paymentHeader}>
                <Row>
                  <Col md={10} className={cx(s.textLeft, s.paymentPadding, "textAlignRightRtl")}>
                    <h3 className={cx(s.pullLeft, s.h3, s.space2, "pullRightBooking")}>
                      <FormattedMessage {...messages.payment} />
                    </h3>
                  </Col>
                </Row>
              </header>
            </section>
            {
              bookValues.stripePayment &&
              <span>
                <Field
                  name="paymentType"
                  component="input"
                  type="radio"
                  value="2"
                  checked={!bookingFormValues.paymentType || bookingFormValues.paymentType === '2'}
                  className={cx(s.cursorPointer)}
                />
                <span className={cx(s.radioTextSection, "radioTextSectionRTL")}>
                  &nbsp;<FormattedMessage {...messages.creditCard} />
                </span>
              </span>
            }

            {
              (!bookingFormValues.paymentType || bookingFormValues.paymentType === '2') ? (
                <Row className={cx(s.responsivecardSection)}>
                  <Col lg={10} md={11} sm={8} xs={12} className={cx(s.noPadding, s.cardSection, s.noPaddingLeft)}>
                    <div className="placeHolderFont darkInputColor">
                      <label className={cx(s.labelText, "textWhite")}>
                        <FormattedMessage {...messages.paymentCardNumber} />
                      </label>
                      <CardNumberElement
                        {...createOptions(theme, isRTL(locale))}
                        placeholder={"4242 4242 4242 4242"}
                        className={cx(s.cardNumber, s.cardNumberSection, s.cardNumberSectionOne, "cardNumberRtl textWhite", isRTL(locale) ? "placeHolderNameRTL" : "placeHolderNameLTR")}
                      />
                      <Col lg={4} md={4} sm={4} xs={6} className={cx(s.noPaddingLeft, "noPaddingRightRTL")}>
                        <label className={cx(s.labelText, "textWhite")}>
                          <FormattedMessage {...messages.cardExpires} />
                        </label>
                        <CardExpiryElement
                          placeholder="MM / YY"
                          {...createOptions(theme, isRTL(locale))}
                          className={cx(s.cardNumber, s.cardNumberSectionTwo, s.cardNumberSection, "cardNumberRtl textWhite")}
                        />
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={6} className={cx(s.noMobileRightPadding, "noMobileLeftPaddingRTL")}>
                        <label className={cx(s.labelText, "textWhite")}>
                          <FormattedMessage {...messages.cvv} />
                        </label>
                        <CardCvcElement
                          placeholder="_ _ _"
                          {...createOptions(theme, isRTL(locale))}
                          className={cx(s.cardNumber, s.cardNumberSectionThree, s.cardNumberSection, "cardNumberRtlTwo textWhite")}
                        />
                      </Col>
                      <Col lg={6} md={6} sm={5} xs={7} className={s.noPaddingLeft}>
                        <img src={imageOne} className={cx(s.fullWidth, s.stripeImg)} />
                      </Col>
                      <Col lg={5} md={5} sm={4} xs={5} className={cx(s.pullRight, s.textRight, s.noPaddingRight)}>
                        <img src={imageTwo} className={cx(s.fullWidth, s.stripeImg)} />
                      </Col>
                    </div>
                  </Col>
                </Row>
              ) : (
                <span></span>
              )}

            {
              bookValues.payPalPayment &&
              <Row className={s.payPalTop}>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <span>
                    <Field
                      name="paymentType"
                      component="input"
                      type="radio"
                      value="1"
                      checked={bookingFormValues.paymentType === '1'}
                      className={cx(s.cursorPointer)}
                    />
                    <span className={cx(s.radioTextSection, "radioTextSectionRTL")}>
                      &nbsp;<FormattedMessage {...messages.paypal} />
                    </span>
                  </span>
                </Col>
              </Row>
            }

            {
              (bookingFormValues.paymentType === '1') &&
              <Row className={cx(s.space4, s.spaceTop3)}>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className={cx(s.countryName, 'textWhite')}>
                    <span>
                      <FormattedMessage {...messages.paymentCurrency} />
                    </span>
                  </div>
                  <div className={s.selectContainer}>
                    <Field
                      name="paymentCurrency"
                      component={this.renderFormControlSelect}
                      className={cx(s.formControlSelect, bt.commonControlSelect, "selectPaymentDropdown")}
                    >
                      <option value="" disabled hidden>{formatMessage(messages.chooseCurrency)}</option>
                      {this.renderPaymentCurrencies()}
                    </Field>
                  </div>
                  <span className={cx(s.textLight)}>
                    <FormattedMessage {...messages.loginInfo} />
                  </span>
                </Col>
              </Row>
            }

            <div className={s.footerBtns}>
              {/* {
                bookValues.paymentLoading ?
                <div className={s.cancelBtn}>
                  <a href="#" className={cx(s.cancelLinkText, { [s.disabledLink]: submitting == true })}>
                    <FormattedMessage {...messages.cancel} />
                  </a>
                </div>
                : (
                  <div className={s.cancelBtn}>
                    <Link 
                      to={`/rooms/${UserListing.id}`}
                      className={cx(s.cancelLinkText)}
                      onClick={this.handleCancelButtonClick}
                    >
                      <FormattedMessage {...messages.cancel} />
                    </Link>
                  </div>
                )
              } */}

              <div className={s.cancelBtn}>
                <Loader
                  type="button"
                  buttonType="submit"
                  className={cx(bt.btnPrimary, s.loaderFlex, "arButtonLoader")}
                  disabled={submitButtonIsDisabled}
                  show={bookValues.paymentLoading}
                  label={formatMessage(messages.payNow)}
                />
              </div>
            </div>
          </Col>
        </form>
      </div>
    );
  }
}

PaymentForm = reduxForm({
  form: "BookingForm",
  validate,
})(PaymentForm);

const mapState = (state) => ({
  paymentCurrencyList: state.currency.availableCurrencies,
  theme: state.currency.theme,
  bookValues: state.book,
  account: state.account.data,
  maximumStayExceeded: state.viewListing.maximumStayExceeded,
  minimumStayNotReached: state.viewListing.minimumStayNotReached,
  bookingFormValues: getFormValues('BookingForm')(state),
});

const mapDispatch = {
  makePayment,
  processCardAction,
  openPaymentModal,
  checkAvailability,
  getAvailableMonthsForMonthlyRental,
};

export default injectStripe(injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(PaymentForm))));
