import history from '../../core/history';

import {
  BOOKING_PROCESS_START,
  BOOKING_PROCESS_SUCCESS,
  BOOKING_PROCESS_ERROR
} from '../../constants';

export function storeBookingDetails(bookingDetails) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: BOOKING_PROCESS_START,
      payload: {
        bookingLoading: true
      }
    });

    try {
      dispatch({
        type: BOOKING_PROCESS_SUCCESS,
        payload: {
          bookingLoading: false,
          bookDetails: bookingDetails
        }
      });

      history.push('/book/' + bookingDetails.listingId);

    } catch (error) {
      dispatch({
        type: BOOKING_PROCESS_ERROR,
        payload: {
          error,
          bookingLoading: false
        }
      });
      return false;
    }

    return true;
  };
}

