import React from 'react';
import PropTypes from 'prop-types';
import { flowRight as compose } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { submit, change, Field, reduxForm, getFormValues } from 'redux-form';
import {
  Button,
  Form,
  Row,
  Col,
  Card,
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FaSearch } from "react-icons/fa";
import { TfiNewWindow } from "react-icons/tfi";

// Components
import PlaceGeoSuggest from './PlaceGeoSuggest';
import DateRange from './DateRange/DateRange';
import Guests from './Guests/Guests';
import RoomType from './RoomType/RoomType';
import MoreFiltersModal from './MoreFiltersModal';
import MonthlyRentalToggle from './MonthlyRentalToggle/MonthlyRentalToggle';

import submitSearchForm from "./submitSearchForm";

// Redux Action
import { getSpecificSettings } from '../../../actions/getSpecificSettings';
import { setPersonalizedValues } from '../../../actions/personalized';
import { openMoreFiltersModal } from "../../../actions/modalActions";
import { changePersonalizedData } from '../../../actions/toggleControl';

// Helper
import history from '../../../core/history';

// Locale
import messages from '../../../locale/messages';

import filterIcon from '../../../../public/SiteIcons/moreFilterIcon.svg';

import bt from '../../commonStyle.css';
import s from './SearchForm.css';

class SearchForm extends React.Component {
  static propTypes = {
    setPersonalizedValues: PropTypes.any.isRequired,
    getSpecificSettings: PropTypes.any.isRequired,
    personalized: PropTypes.shape({
      location: PropTypes.string,
      lat: PropTypes.number,
      lng: PropTypes.number,
      chosen: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      personCapacity: PropTypes.any,
      formatMessage: PropTypes.any,
      isOneTotalToggle: PropTypes.bool,
    }),
    settingsData: PropTypes.shape({
      listSettings: PropTypes.array.isRequired
    }).isRequired
  };

  static defaultProps = {
    listingFields: []
  };

  static defaultProps = {
    personalized: {
      location: null,
      lat: null,
      lng: null,
      startDate: null,
      endDate: null,
      personCapacity: null,
      chosen: null
    },
    settingsData: {
      listSettings: []
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      personCapacity: [],
      isOneTotalToggle: false,
      tabs: {
        dates: false,
        guests: false,
        homeType: false,
        price: false,
        instantBook: false,
        moreFilters: false,
      },
    };
    this.handleClick = this.handleClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { listingFields, personalized } = nextProps;
    if (listingFields != undefined) {
      this.setState({
        personCapacity: listingFields.personCapacity
      });
    }
    if (personalized.isOneTotalToggle) {
      this.setState({ isOneTotalToggle: personalized.isOneTotalToggle })
    }
  }

  UNSAFE_componentWillMount() {
    const { listingFields, personalized } = this.props;
    if (listingFields != undefined) {
      this.setState({
        personCapacity: listingFields.personCapacity
      });
    }
    if (personalized.isOneTotalToggle) {
      this.setState({ isOneTotalToggle: personalized.isOneTotalToggle })
    }
  }

  handleClick() {
    const { personalized, page, submit, setPersonalizedValues, change } = this.props;
    let uri = '/?';
    if (page != 'search' || (page == 'search' && (personalized?.chosen || personalized?.location))) {

      if (personalized?.chosen) {
        uri = uri + '&address=' + personalized?.location + '&chosen=' + personalized?.chosen;
      } else if (personalized?.location) {
        uri = uri + '&address=' + personalized?.location;
      }
      if (personalized?.startDate && personalized?.endDate) {
        setPersonalizedValues({ name: 'isOneTotalToggle', value: true });
        change('SearchForm', 'isOneTotalToggle', true);
        uri = uri + '&startdate=' + personalized?.startDate + '&enddate=' + personalized?.endDate;
      }
      if (personalized?.personCapacity && !isNaN(personalized?.personCapacity)) {
        uri = uri + '&guests=' + personalized?.personCapacity;
      }
      if (personalized?.roomType?.length > 0) {
        uri = uri + '&roomtype=' + JSON.stringify(personalized?.roomType);
      }
      history.push(encodeURI(uri));
    }
    else {
      if (personalized?.startDate && personalized?.endDate) {
        setPersonalizedValues({ name: 'isOneTotalToggle', value: true });
        change('SearchForm', 'isOneTotalToggle', true);
      }
      submit('SearchForm');
    }
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        {
          touched && error &&
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        }
        <Form.Control {...input} placeholder={label} type={type} className={className} />
      </div>
    )
  }

  loadField = () => {
    const { formatMessage } = this.props.intl;
    return (
      <Field
        component={this.renderFormControl}
        label={formatMessage(messages.header_search_form_location_placeholder)}
        className={cx(s.formControlInput, s.input, s.loadfield)}
        name="location"
      />
    )
  }

  formValue = (name, value) => {
    const { setPersonalizedValues, changePersonalizedData } = this.props;
    changePersonalizedData('SearchForm', name, value);
    setPersonalizedValues({ name, value })
  }

  handleTabToggle = (currentTab, isExpand) => {
    const { showResults, showFilter, smallDevice } = this.props;
    const { tabs } = this.state;

    for (let key in tabs) {
      if (key == currentTab) {
        tabs[key] = isExpand;
      } else {
        tabs[key] = false;
      }
    }

    this.setState({
      tabs,
    });

    if (smallDevice) {
      if (isExpand) {
        showFilter();
      }
      else {
        showResults();
      }
    }
  };

  render() {
    const { guests, personalized, searchSettings, showFilter, showResults, listingFields, openMoreFiltersModal, smallDevice, page, formValues } = this.props;
    const { formatMessage } = this.props.intl;
    const { personCapacity, tabs } = this.state;
    let rows = [], guestCount = 1;
    if (personCapacity?.[0] && personCapacity[0]?.startValue) {
      for (let i = personCapacity[0]?.startValue; i <= personCapacity[0]?.endValue; i++) {
        rows.push(<option value={i} key={i}>{i} {i > 1 ? personCapacity[0]?.otherItemName : personCapacity[0]?.itemName}</option>);
      }
    }
    if (personalized?.personCapacity) {
      guestCount = personalized?.personCapacity;
    }
    else {
      if (personCapacity?.[0] && personCapacity[0].startValue) {
        guestCount = personCapacity[0]?.startValue;
      }
    }

    if ('viewListing' == page) {
      guestCount = guests;
    }

    let isActiveMoreFilter = false;
    if (
      (formValues?.priceRange
        || formValues?.bookingType == "instant"
        || formValues?.beds
        || formValues?.bedrooms
        || formValues?.privateBathrooms
        || formValues?.sharedBathrooms
        || formValues?.amenities?.length
        || formValues?.safetyAmenities?.length
        || formValues?.spaces?.length
        || formValues?.houseRules?.length
        || formValues?.hostLanguages?.length
        || formValues?.section
        || formValues?.activity
      )
    ) {
      isActiveMoreFilter = true;
    }

    if (
      smallDevice &&
      (formValues?.dates
        || formValues?.personCapacity > 1
        || formValues?.roomType?.length
      )
    ) {
      isActiveMoreFilter = true;
    }

    return (
      <>
        <div className='headerSearch d-flex justify-content-center align-items-center mb-3'>
          <div className={cx(s.grid, 'searchHeaderPaddingRTL w-100')} style={{ height: '65px', maxWidth: '800px' }}>
            <div
              className={cx(
                (!smallDevice) ? s.inputBorder : '',
                'tableCellRTL',
              )}
              style={{ height: '65px', paddingTop: '10px' }}
            >
              <PlaceGeoSuggest
                label={formatMessage(messages.header_search_form_location_placeholder)}
                className={cx(s.formControlInput, s.input)}
                containerClassName={s.geoSuggestContainer}
                loadField={this.loadField}
              />
            </div>
            {
              (!smallDevice) &&
              <>
                <div className={cx(s.inputBorder, 'dividerTopRTL tableCellRTL')} style={{ height: '65px', paddingTop: '5px' }}>
                  <span className={cx('homeDate', s.formControlInput, s.input, 'homeDateAR', 'headerSearchDate')}>
                    <DateRange formName={'SearchForm'} numberOfMonths={2} />
                  </span>
                </div>

                <div
                  className={cx(s.inputBorder, s.mobilePadding, 'tableCellRTL')}
                  style={{ height: '65px', paddingTop: '20px', paddingInline: '10px' }}
                >
                  <Guests />
                </div>

                <div className={cx(s.mobilePadding)}>
                  <RoomType
                    smallDevice={smallDevice}
                  />
                </div>
              </>
            }

            <div className={cx(s.search)}>
              <Button
                onClick={this.handleClick}
                className={cx(
                  bt.btnPrimary,
                  s.btnBlock,
                  s.searchButton,
                  'searchFormBtn d-flex text-nowrap'
                )}
              >
                <span><FaSearch className={cx(s.iconStyle, 'textWhite')} /></span>
                {/* <div className='me-2 d-none d-xl-block'>
                  <FormattedMessage {...messages.search}/>
                </div> */}
              </Button>
            </div>
          </div>
          {
            ('search' == page) &&
            <>
              <div className={cx(s.filterButtonContainer)}>
                <Button
                  className={cx(
                    s.btn,
                    isActiveMoreFilter ? s.btnSecondary : s.filterBtn,
                    'searchBtnDark'
                  )}
                  onClick={openMoreFiltersModal}
                >
                  <span className={cx(s.btnFlex, 'svgImg')}>
                    {
                      (!smallDevice) &&
                      <span
                        className={cx(
                          isActiveMoreFilter ? 'filterTextNoBG' : ['filterTextBG'],
                          'text-nowrap d-none d-xl-block',
                        )}
                      >
                        <FormattedMessage {...messages.header_more_filters_button_label} />
                      </span>
                    }
                    <img src={filterIcon} />
                  </span>
                </Button>
              </div>

              <MoreFiltersModal
                className={s.filterButtonContainer}
                handleTabToggle={this.handleTabToggle}
                isExpand={tabs?.moreFilters}
                searchSettings={searchSettings}
                showFilter={showFilter}
                showResults={showResults}
                smallDevice={smallDevice}
              />
            </>
          }
        </div>
        {
          ('search' == page) &&
          <Row className='mx-md-3' style={{rowGap: '10px'}}>
            <Col xs={6}>
              <Card>
                <Card.Body style={{padding: '6px'}}>
                  <MonthlyRentalToggle />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6}>
              <Card className="h-100">
                <Card.Body className="d-flex justify-content-center align-items-center" style={{padding: '6px'}}>
                  <a href="/added-values" target="_blank" className={s.homepageFilterCard}>
                    <FormattedMessage {...messages.homepage_added_values_button_label} />
                    <span>&nbsp;&nbsp;<TfiNewWindow style={{marginBottom: '7px'}}/></span>
                  </a>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        }
      </>
    );
  }
}

SearchForm = reduxForm({
  form: 'SearchForm',
  onSubmit: submitSearchForm,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(SearchForm);

const mapState = (state) => ({
  formValues: getFormValues("SearchForm")(state),
  personalized: state.personalized,
  settingsData: state.viewListing.settingsData,
  listingFields: state.listingFields.data,
});

const mapDispatch = {
  getSpecificSettings,
  setPersonalizedValues,
  submit,
  change,
  changePersonalizedData,
  openMoreFiltersModal,
};

export default compose(
  injectIntl,
  withStyles(s, bt),
  connect(mapState, mapDispatch),
)(SearchForm);