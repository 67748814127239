import React from 'react';
import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
	Card,
} from 'react-bootstrap';

import s from './AVActivityItem.css';

class AVActivityItem extends React.Component {
	static propTypes = {
	};

	static defaultProps = {
	};

	render() {
		const { activity, region, sectionName } = this.props;

		let activitySearchLink = `/?section=${sectionName}&&activity=${activity.name}`;
		if (region) {
			activitySearchLink += `&&address=${region}`;
		}

		return (
			<Card style={{
				height: '200px',
				width: '250px',
			}}>
				<Card.Body className="d-flex flex-column justify-content-between">
					<Card.Title>{activity.name}</Card.Title>
					<Card.Text>{activity.description}</Card.Text>
					<Card.Link href={activitySearchLink} target="_blank">Filter Listings by this activity</Card.Link>
				</Card.Body>
			</Card>
		)
	}
}

export default compose(withStyles(s))(AVActivityItem);