import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '!isomorphic-style-loader!css-loader!lyef-switch-button/css/main.css';
import * as SwitchButton from 'react-switch';

import {
  SECONDARYCOLOR, BORDERSECONDARY
} from '../../constants';

export const uncheckedIcon = (
  <svg viewBox="0 0 52 52" fill="currentColor" fillOpacity="0"
    stroke="currentColor" strokeWidth="3"
    role="presentation" strokeLinecap="round" strokeLinejoin="round"
    style={{ position: "absolute", top: 1, height: '30px', width: '30px', display: 'block', transform: 'scale(1.5)' }}>
    <path d="m19.1 19.1 l14 14 m 0 -14 l -14 14"></path>
  </svg>
);

export const checkedIcon = (
  <svg viewBox="0 0 52 52" fill={SECONDARYCOLOR} fillOpacity="0"
    stroke="currentColor" strokeWidth="3"
    role="presentation" strokeLinecap="round" strokeLinejoin="round"
    style={{ position: "absolute", top: 1, height: '30px', width: '30px', display: 'block', transform: 'scale(1.5)' }}>
    <path d="m19.1 25.2 4.7 6.2 12.1-11.2"></path>
  </svg>
);

class Switch extends Component {
  static propTypes = {
    handleSubmit: PropTypes.any,
    formName: PropTypes.string,
    fieldName: PropTypes.string,
    checkedValue: PropTypes.any,
    unCheckedValue: PropTypes.any
  };

  static defaultProps = {
    checked: false,
    checkedValue: true,
    unCheckedValue: false,
    offColor: BORDERSECONDARY,
    onColor: SECONDARYCOLOR,
    checkedIcon: checkedIcon,
    uncheckedIcon: uncheckedIcon,
    height: 36,
    width: 55,
    boxShadow: 'none',
    isPersonalize: false
  };

  render() {
    const { 
      offColor, onColor, checkedIcon, uncheckedIcon, height, width, boxShadow, disabled, checked, onToggle,
    } = this.props;

    return (
      <SwitchButton
        id="booking-type"

        checked={checked}
        disabled={disabled || false}
        onChange={() => onToggle()}

        offColor={offColor}
        onColor={onColor}
        checkedIcon={checkedIcon}
        uncheckedIcon={uncheckedIcon}
        height={height}
        width={width}
        boxShadow={boxShadow}
      />
    );
  }
}

const mapState = (state) => ({
});

export default withStyles(s)(connect(mapState)(Switch));

