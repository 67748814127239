import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import messages from '../../../locale/messages';
import Language from './Language';
import { getAllLanguages } from '../../../actions/siteadmin/LanguageManagement/languageManagement';

const title = 'Language Management';

export default async function action({ store, intl }) {

  const title = intl?.formatMessage(messages.languageManagementTab);

  // From Redux Store
  let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
  let isSuperAdmin = store.getState().runtime.isSuperAdmin

  if (!isAdminAuthenticated || !isSuperAdmin) {
    return { redirect: '/siteadmin/login' };
  }
  
  await store.dispatch(getAllLanguages());

  return {
    title,
    component: <AdminLayout><Language title={title} /></AdminLayout>,
  };
}
