/* For Development Environment */
export const port = 3000; // when running we will use: 3000, 3001 and 3002 (Web + DB on 3001)
export const databaseUrl = 'mysql://abed:Abed123!@localhost:3306/abed_eygar';

/* For Production Environment */
// export const port = 3001;
// export const databaseUrl = 'mysql://abed:Abed123!@localhost:3306/eygar_progress';

/* ------------------------------------------------------------------------------------ */

/* Common Variables */
export const siteUrl = `localhost:${port}`;
export const sitename = 'EYGAR';

// Main Images Directory
export const imagesDirFullPath = '../eygar_images';

// Listing Photos Upload Directory
export const fileuploadDir = imagesDirFullPath + '/upload/';

// Logo upload directory
export const logouploadDir = imagesDirFullPath + '/logo/';

// Home page Banner upload directory
export const banneruploadDir = imagesDirFullPath + '/banner/';

// User Profile Photos Upload Directory
export const profilePhotouploadDir = imagesDirFullPath + '/avatar/';

//Document Upload
export const documentuploadDir = imagesDirFullPath + '/document/';

// Location upload directory
export const locationuploadDir = imagesDirFullPath + '/popularLocation/';

// Static block image upload directory
export const homebanneruploadDir = imagesDirFullPath + '/home/';

// Amenities upload directory
export const amenitiesUploadDir = imagesDirFullPath + '/amenities/';

// Favicon images update directory
export const faviconUploadDir = imagesDirFullPath + '/favicon/';

// whyHostUploadDir	
export const whyHostUploadDir = imagesDirFullPath + '/whyhost/';

// Sections upload directory
export const sectionsUploadDir = imagesDirFullPath + '/sections/';

// default locale is the first one
export const locales = ['en-US', 'ar'];

export const analytics = {

  // https://analytics.google.com/
  google: {
    trackingId: 'UA-XXXXX-X',
  },

};

export const googleMapAPI = 'AIzaSyAQamFDZD7XfdsFOLPOif1DiCvdEiwKsjA';
export const googleMapServerAPI = 'AIzaSyAQamFDZD7XfdsFOLPOif1DiCvdEiwKsjA';

export const searchLimit = 24;
export const searchPagination = 300;

export const payment = {

  paypal: {
    returnURL: `${siteUrl}/success`,
    cancelURL: `${siteUrl}/cancel`,
    redirectURL: {
      success: `${siteUrl}/users/trips/itinerary`,
      cancel: `${siteUrl}/cancel`
    },
    versions: {
      versionOne: '/v1',
      versionTwo: '/v2'
    },
    token_url: '/oauth2/token',
    payment_url: '/checkout/orders',
    capture_url: '/capture',
    payout_url: '/payments/payouts',
    refund: '/refund',
    refund_capture: '/payments/captures/'
  },

  stripe: {
    secretKey: 'sk_test_51OdpbaLF4B1oYS59iCamHBTkqPv6bz44dr4QFMJx6bwLsJ8smqUFUb5tZIDPLIpUVpHyxH96A18VeJ2g8Grtymxm00hiLcuPtN',
    publishableKey: 'pk_test_51OdpbaLF4B1oYS59VZLMgRfKBKCaZw5Yscbmo6EmI7j5I3LVrslCSHgV8M8BkEHUMuegQGOTKndhyIq5dNkbJNLT00xX8RRnQd'
  }

};

// site key for google recaptcha
export const googleCaptcha = {
  sitekey: '<Your Google reCAPCHA Site key>',
};

export const auth = {

  jwt: { secret: 'EYGAR' },

  redirectURL: {
    login: '/dashboard',
    verification: '/user/verification',
    userbanned: '/userbanned',
    returnURLDeletedUser: '/userbanned'
  },

  // https://developers.facebook.com/
  facebook: {
    returnURL: `${siteUrl}/login/facebook/return`,
  },

  // https://cloud.google.com/console/project
  google: {
    returnURL: `${siteUrl}/login/google/return`,
  }
};
