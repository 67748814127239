import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { getFormValues } from 'redux-form';
import {
  Modal
} from 'react-bootstrap';

import EditPayment from './EditPayment';

import { closePaymentModal } from '../../../../actions/modalActions';
import { getSpecialPricingData } from '../../../../actions/Listing/getSpecialPricingData';
import { checkAvailability } from '../../../../actions/checkAvailability';
import { getAvailableMonthsForMonthlyRental } from '../../../../actions/getMonthsForRental';

import s from './ModalForm.css';
import cx from 'classnames';

class ModalForm extends Component {
  static propTypes = {
    closePaymentModal: PropTypes.any.isRequired,
    paymentModal: PropTypes.bool
  };

  static defaultProps = {
    paymentModal: false
  };

  constructor(props) {
    super(props);

    this.state = {
      startDateState: props.bookingFormValues.startDate,
      endDateState: props.bookingFormValues.endDate,
      showMonthlyRentalState: props.bookingFormValues.showMonthlyRental,
      monthlyRentalCheckoutMonthState: props.bookingFormValues.monthlyRentalCheckoutMonth,
      guestsState: props.bookingFormValues.guests,
    };
  }

  async resetChanges() {
    const { bookingFormValues, UserListing, getAvailableMonthsForMonthlyRental, checkAvailability, getSpecialPricingData } = this.props;
    const { startDateState, endDateState } = this.state;

    let monthlyRentalValuesChanged = false, nightlyRentalValuesChanged = false;
    if (startDateState !== bookingFormValues.startDate) {
      monthlyRentalValuesChanged = true;
      nightlyRentalValuesChanged = true;
    }
    else if (endDateState !== bookingFormValues.endDate) {
      nightlyRentalValuesChanged = true;
    }
    
    this.setState({
      startDateState: bookingFormValues.startDate,
      endDateState: bookingFormValues.endDate,
      showMonthlyRentalState: bookingFormValues.showMonthlyRental,
      monthlyRentalCheckoutMonthState: bookingFormValues.monthlyRentalCheckoutMonth,
      guestsState: bookingFormValues.guests,
    });

    if (monthlyRentalValuesChanged) {
      await getAvailableMonthsForMonthlyRental(UserListing.id, bookingFormValues.startDate);

      if (bookingFormValues.endDate && nightlyRentalValuesChanged) {
        await getSpecialPricingData(UserListing.id, bookingFormValues.startDate, bookingFormValues.endDate);
        await checkAvailability(UserListing.id, bookingFormValues.startDate, bookingFormValues.endDate, UserListing.listingData.maxNight, UserListing.listingData.minNight);
      }
    }
  }

  async onHideModal() {
    const { closePaymentModal } = this.props;

    await this.resetChanges();
    closePaymentModal();
  }

  render() {
    const { paymentModal, UserListing } = this.props;

    return (
      <div>
        <Modal
          show={paymentModal}
          onHide={() => this.onHideModal()}
          dialogClassName={cx(s.bookingEditModalDialog, 'adminModal paymentFormModal paymentGuestFilter')}
        >
          <Modal.Header closeButton className='d-flex justify-content-between'>
            <Modal.Title>
              {/* <FormattedMessage {...messages.edit_booking_modal_title} /> */}
              Edit Booking
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className={cx(s.root, s.modalBorderBottom)}>
              <div className={s.container}>

                <EditPayment
                  UserListing={UserListing}
                  stateValues={this.state}
                  resetChanges={() => this.resetChanges()}
                  updateStateValues={(values) => this.setState(values)}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapState = (state) => ({
  paymentModal: state.modalStatus.paymentModal,
  bookingFormValues: getFormValues('BookingForm')(state),
});

const mapDispatch = {
  closePaymentModal,
  checkAvailability,
  getSpecialPricingData,
  getAvailableMonthsForMonthlyRental,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ModalForm)));