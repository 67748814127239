import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
  Button,
  Row,
  Col,
  Form,
} from 'react-bootstrap';

import { addLanguage } from '../../../actions/siteadmin/LanguageManagement/languageManagement';
import validate from './validate';
import messages from '../../../locale/messages';

// Style
import s from './ManageLanguagesForm.css';
import bt from '../../../components/commonStyle.css';

class AddLanguageForm extends Component {

  renderFormControl = ({ input, label, type, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <label className={s.labelTextNew}>{label}</label>
        <Form.Control {...input} type={type} className={className} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    )
  }

  handleAddLanguage(e) {
    const { langCode, langName, addLanguage } = this.props;
    addLanguage({langCode, langName});
  }

  render() {
    const { error, handleSubmit, submitting } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.formMaxWidth, 'maxwidthcenter', 'empty')}>
          {error && <strong>{formatMessage(error)}</strong>}
          <Form.Group className={s.space3}>
            <Field
              name="langCode"
              type="text"
              component={this.renderFormControl}
              label={formatMessage(messages.langCodeLabel)}
              className={cx(bt.commonControlInput)}
            />
          </Form.Group>
          <Form.Group className={s.space3}>
            <Field
              name="langName"
              type="text"
              component={this.renderFormControl}
              label={formatMessage(messages.langNameLabel)}
              className={cx(bt.commonControlInput)}
            />
          </Form.Group>
          <Form.Group className={s.space1}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} className={cx(bt.textAlignRight, s.spaceTop3, 'textAlignLeftRtl')}>
                <Button className={cx(bt.btnPrimaryBorder, bt.btnLarge, 'bgBlack')} type="submit" disabled={submitting} onClick={() => this.handleAddLanguage()}>
                  {formatMessage(messages.add)}
                </Button>
              </Col>
            </Row>
          </Form.Group>
      </div>
    )
  }
}

AddLanguageForm = reduxForm({
  form: "AddLanguageForm",
  validate,
})(AddLanguageForm);

const selector = formValueSelector("AddLanguageForm");
const mapState = (state) => ({
  langCode: selector(state, 'langCode'),
  langName: selector(state, 'langName'),
});

const mapDispatch = {
  addLanguage,
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(AddLanguageForm)));