import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  Form,
} from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

// Locale
import messages from '../../locale/messages';

// components
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';

// Helpers
import validate from './validate';
import update from './update';

//Image
import toolTipIcon from '../../../public/SiteIcons/listCommonToolTip.svg';

// Style
import s from './ListPlaceStep1.css';
import bt from '../../components/commonStyle.css';

class GeneralInfoPage extends Component {

  static propTypes = {
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      roomType: [],
      buildingSize: [],
    }
  }

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className, disabledOptionLabel }) => {
    return (
      <div>
        <Form.Select {...input} className={className}>
          <option value='' disabled hidden>{disabledOptionLabel}</option>
          {children}
        </Form.Select>
      </div>
    )
  }

  render() {
    const { handleSubmit, previousPage, nextPage, existingList, formPage, step, listingFields, formValues } = this.props;
    const { formatMessage } = this.props.intl;

    let path = "index";
    if (existingList) {
      path = "home";
    }

    let nextButtonIsDisabled = true;
    // if (formValues.roomType != ''
    //   && formValues.buildingSize != ''
    //   && formValues.residenceType != ''
    // ) {
      nextButtonIsDisabled = false;
    // }
    // else {
    //   nextButtonIsDisabled = true;
    // }

    return (
      <div>
        <div className={s.grid}>
          <SidePanel
            title={formatMessage(messages.stepOneCommonHeading)}
            landingContent={formatMessage(messages.whatKindOfPlaceListing)}
          />
          <div>
            <form onSubmit={handleSubmit}>
              <div>
                <div className={s.landingMainContent}>
                  <Form.Group className={s.space5}>
                    <Form.Label className={cx(s.landingLabel, 'textWhite')}>
                      <FormattedMessage {...messages.whatGuestHave} />
                    </Form.Label>
                    <Field 
                      name="roomType" 
                      component={this.renderFormControlSelect} 
                      className={cx(s.formControlSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')} 
                      disabledOptionLabel={formatMessage(messages.become_host_select_room_type_default_value)}
                    >
                      {
                        listingFields?.roomType?.map((value, key) => {
                          return (
                            value.isEnable == 1 &&
                            <option 
                              value={value.id} 
                              key={key}
                            >
                              { messages[value.itemName] ? formatMessage(messages[value.itemName]) : value.itemName }
                            </option>
                          )
                        })
                      }
                    </Field>
                  </Form.Group>
                  <Form.Group className={s.space5}>
                    <Form.Label className={cx(s.landingLabel, 'textWhite')}>
                      <FormattedMessage {...messages.howManyRooms} />
                    </Form.Label>
                    <Field 
                      name="buildingSize" 
                      component={this.renderFormControlSelect} 
                      className={cx(s.formControlSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
                      disabledOptionLabel={formatMessage(messages.become_host_select_building_size_default_value)}
                    >
                      {
                        listingFields?.buildingSize?.map((value, key) => {
                          return (
                            value.isEnable == 1 &&
                            <option 
                              value={value.id} 
                              key={key}
                            >
                              { messages[value.itemName] ? formatMessage(messages[value.itemName]) : value.itemName }
                            </option>
                          )
                        })
                      }
                    </Field>
                  </Form.Group>
                  <Form.Group className={s.space5}>
                    <Form.Label className={cx(s.landingLabel, 'textWhite')}>
                      <FormattedMessage {...messages.isPersonalHome} />
                    </Form.Label>
                    <Field
                      name="residenceType"
                      component={this.renderFormControlSelect}
                      className={cx(s.formControlSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}
                      disabledOptionLabel={formatMessage(messages.become_host_select_residence_type_default_value)}
                    >
                      <option value={"1"} >{formatMessage(messages.yesText)}</option>
                      <option value={"0"} >{formatMessage(messages.noText)}</option>
                    </Field>
                  </Form.Group>
                  <div className={s.tipCommonCss}>
                    <img src={toolTipIcon} />
                    <span className={cx(s.commonTipCsss, 'textWhite')}><FormattedMessage {...messages.isPersonalHomeInfo} /></span>
                  </div>
                </div>
                <FooterButton
                  isDisabled={nextButtonIsDisabled}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  nextPagePath={"bedrooms"}
                  previousPagePath={path}
                  formPage={formPage}
                  step={step}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

GeneralInfoPage = reduxForm({
  form: 'ListPlaceStep1',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmit: update
})(GeneralInfoPage);

const mapState = (state) => ({
  existingList: state.location.isExistingList,
  listingFields: state.listingFields.data,
  formValues: getFormValues("ListPlaceStep1")(state),
});

export default injectIntl(withStyles(s, bt)(connect(mapState)(GeneralInfoPage)));