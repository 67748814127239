import React from 'react';
import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './AVOfferProviderItem.css';

class AVOfferProviderItem extends React.Component {
	static propTypes = {
	};

	static defaultProps = {
	};

	render() {
		const { offerProvider, onOfferProviderClick } = this.props;

		return (
			<div onClick={() => onOfferProviderClick(offerProvider.id)}>
				<img src={'/images/offerProviders/' + offerProvider.image} width={100} height={100} style={{ borderRadius: '50px'}} />
				<h5>{offerProvider.name}</h5>
			</div>
		)
	}
}

export default compose(withStyles(s))(AVOfferProviderItem);