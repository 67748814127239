import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { Button } from 'react-bootstrap';

import Loader from '../../../Loader';
import IncrementBtnCircle from '../../../IncrementBtnCircle';
import AvailabilityCalendar from '../../../ViewListing/AvailabilityCalendar/AvailabilityCalendar';
import MonthlyRentalCheckoutMonthSelector from '../../../ViewListing/Calendar/MonthlyRentalCheckoutMonthSelector';
import ReservationTypeToggle from '../../../ViewListing/ReservationTypeToggle/ReservationTypeToggle';

import { closePaymentModal } from '../../../../actions/modalActions';
import { getSpecialPricingData } from '../../../../actions/Listing/getSpecialPricingData';
import { checkAvailability } from '../../../../actions/checkAvailability';
import { getAvailableMonthsForMonthlyRental } from '../../../../actions/getMonthsForRental';

import gusetIcon from '../../../../../public/SiteIcons/paymenyGusetIcon.svg';

import messages from '../../../../locale/messages';

import bt from '../../../commonStyle.css';
import s from './ModalForm.css';

class EditPayment extends Component {

  constructor(props) {
    super(props);

    this.state = {
      smallDevice: true,
    };
  }

  componentDidMount() {
    let isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
    }
  }

  componentWillUnmount() {
    let isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      window.removeEventListener('resize', this.handleResize);
    }
  }

  handleResize = (e) => {
    const { smallDevice } = this.state;

    let smallDeviceValue = smallDevice;
    if (typeof window !== 'undefined') { // is browser
      smallDeviceValue = window.matchMedia('(max-width: 920px)').matches;

      if (smallDevice !== smallDeviceValue) {
        this.setState({ smallDevice: smallDeviceValue });
      }
    }
  }

  applyChanges() {
    const { change, closePaymentModal, stateValues } = this.props;

    change('BookingForm', 'startDate', stateValues.startDateState);
    change('BookingForm', 'endDate', stateValues.endDateState);
    change('BookingForm', 'showMonthlyRental', stateValues.showMonthlyRentalState);
    change('BookingForm', 'monthlyRentalCheckoutMonth', stateValues.monthlyRentalCheckoutMonthState);
    change('BookingForm', 'guests', stateValues.guestsState);

    closePaymentModal();
  }

  async onCalendarDatesChange({ type, startDate, endDate, maxNight, minNight }) {
    const { UserListing, getAvailableMonthsForMonthlyRental, updateStateValues, stateValues } = this.props;

    if ('range' === type) {

      let startDateFormatted = moment(startDate).format('YYYY-MM-DD');
      let endDateFormatted = null;
      if (endDate) {
        endDateFormatted = moment(endDate).format('YYYY-MM-DD');
      }

      if ((stateValues.startDateState !== startDateFormatted) || (stateValues.endDateState !== endDateFormatted)) {
        updateStateValues({
          startDateState: startDateFormatted,
          endDateState: endDateFormatted,
        });

        if (startDateFormatted) {
          await getAvailableMonthsForMonthlyRental(UserListing.id, startDateFormatted);
          if (endDateFormatted) {
            await this.props.getSpecialPricingData(UserListing.id, startDateFormatted, endDateFormatted);
            await this.props.checkAvailability(UserListing.id, startDateFormatted, endDateFormatted, maxNight, minNight);
          }
        }
      }
    }
    else if ('single' === type) {

      let startDateFormatted = moment(startDate).format('YYYY-MM-DD');
      if (stateValues.startDateState !== startDateFormatted) {
        updateStateValues({
          startDateState: startDateFormatted,
          endDateState: null,
        });

        if (startDateFormatted) {
          await getAvailableMonthsForMonthlyRental(UserListing.id, startDateFormatted);
        }
      }
    }
  }

  render() {
    const {
      maximumStayExceeded, minimumStayNotReached, availability, isLoading, availableMonths,
      loading, UserListing, resetChanges, updateStateValues, stateValues,
    } = this.props;

    const { formatMessage } = this.props.intl;
    const { smallDevice } = this.state;
    
    let monthlyRentalToggled = UserListing.listingData.isMonthlyRentalOnly || stateValues.showMonthlyRentalState;

    let applyButtonIsDisabled = false;
    if (
      (isLoading || !stateValues.startDateState)
      || (monthlyRentalToggled && (!stateValues.monthlyRentalCheckoutMonthState || (availableMonths === 0)))
      || (!monthlyRentalToggled && (!stateValues.endDateState || !availability || maximumStayExceeded || minimumStayNotReached))
    ) {
      applyButtonIsDisabled = true;
    }

    return (
      <div>
        {
          loading ?
            <Loader type="text" />
            : (
              <div>
                {
                  (UserListing.listingData.monthlyRentalPrice > 0) &&
                  <ReservationTypeToggle 
                    isMonthlyRentalOnly={UserListing.listingData.isMonthlyRentalOnly}
                    showMonthlyRental={stateValues.showMonthlyRentalState}
                    onReservationTypeToggle={() =>
                      updateStateValues({ showMonthlyRentalState: !stateValues.showMonthlyRentalState })
                    }
                  />
                }

                {
                  (monthlyRentalToggled && (availableMonths > 0)) &&
                  <MonthlyRentalCheckoutMonthSelector
                    minMonthlyRental={UserListing.listingData.minMonthlyRental}
                    startDate={stateValues.startDateState}
                    bookingEditModal={true}
                    onSelectChange={(value) => updateStateValues({ monthlyRentalCheckoutMonthState: value })}
                  />
                }

                <AvailabilityCalendar
                  listId={UserListing.id}
                  blockedDates={UserListing.blockedDates}
                  listingData={UserListing.listingData}
                  country={UserListing?.country}
                  calendarMonthsToDisplay={ smallDevice ? 1 : 2 }
                  bookingEditModal={true}
                  onCalendarDatesChange={(values) => this.onCalendarDatesChange(values)}
                  startDate={stateValues.startDateState}
                  endDate={stateValues.endDateState}
                  monthlyRentalToggled={monthlyRentalToggled}
                />

                <div className={cx(s.flex, 'mb-4')}>
                  <div>
                    <div className={s.title}><FormattedMessage {...messages.guests} /></div>
                    <div className={cx(s.guestModalFlex, 'svgImg')}>
                      <img src={gusetIcon} className={cx(s.iconCss, 'iconCssRTL')} />
                      <span>
                        {stateValues.guestsState}&nbsp;{(stateValues.guestsState > 1) ? <FormattedMessage {...messages.guests} /> : <FormattedMessage {...messages.guest} />}
                      </span>
                    </div>
                  </div>
                  <div>
                    <IncrementBtnCircle
                      input={{
                        value: stateValues.guestsState,
                        onChange: (value) => updateStateValues({ guestsState: value })
                      }}
                      maxValue={UserListing.personCapacity}
                      minValue={1}
                      incrementBy={1}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <Button
                    variant="link"
                    onClick={(event) => {
                      event.preventDefault();
                      resetChanges();
                    }}
                  >
                    reset
                  </Button>

                  <div className={cx(s.textAlignRight, 'textAlignLeftRtlEdit')}>
                    <Loader
                      className={cx(s.applyPaymentBtn)}
                      disabled={applyButtonIsDisabled}
                      show={isLoading}
                      type={'button'}
                      handleClick={() => this.applyChanges()}
                      label={formatMessage(messages.applyFilters)}
                    />
                  </div>
                </div>
                {
                  !isLoading &&
                  <>
                    {
                      (!monthlyRentalToggled && maximumStayExceeded) &&
                      <div className={s.errorMessage}>
                        <FormattedMessage {...messages.maximumStayExceeded} /> {UserListing.listingData.maxNight} {UserListing.listingData.maxNight > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}
                      </div>
                    }
                    {
                      (!monthlyRentalToggled && minimumStayNotReached) &&
                      <div className={s.errorMessage}>
                        <FormattedMessage {...messages.minimumNightStay} /> {UserListing.listingData.minNight} {UserListing.listingData.minNight > 1 ? formatMessage(messages.nights) : formatMessage(messages.night)}
                      </div>
                    }
                    {
                      (
                        (!monthlyRentalToggled && (!availability && !maximumStayExceeded && !minimumStayNotReached))
                        || (monthlyRentalToggled && (availableMonths === 0))
                      ) &&
                      <div className={s.errorMessage}>
                        <FormattedMessage {...messages.hostErrorMessage2} />
                      </div>
                    }
                  </>
                }
              </div>
            )
        }
      </div>
    );
  }
}

const mapState = (state) => ({
  availability: state.viewListing.availability,
  maximumStayExceeded: state.viewListing.maximumStayExceeded,
  minimumStayNotReached: state.viewListing.minimumStayNotReached,
  isLoading: state.viewListing.isLoading,
  availableMonths: state.viewListing.availableMonths,
});

const mapDispatch = {
  closePaymentModal,
  change,
  checkAvailability,
  getSpecialPricingData,
  getAvailableMonthsForMonthlyRental,
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(EditPayment)));