import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import moment from 'moment';
import Confirm from 'react-confirm-bootstrap';
import { graphql, gql, compose } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Form } from 'react-bootstrap';

import CustomPagination from '../../CustomPagination';

import { removeListing } from '../../../actions/siteadmin/ListingManagement/removeListing';
import { addListToRecommended, removeListFromRecommended } from '../../../actions/siteadmin/ListingManagement/manageRecommend';
import messages from '../../../locale/messages';
import listingsQuery from './listingsQuery.graphql';

import ExportImage from '../../../../public/adminIcons/export.png';

import s from './ListingManagement.css';
class ListingManagement extends React.Component {

  static propTypes = {
    getAllListings: PropTypes.any,
    addListToRecommended: PropTypes.func.isRequired,
    removeListFromRecommended: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0
    }
  }
  
  paginationData = (currentPage) => {
    const { getAllListings: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);
  }

  handleClick = (searchList) =>  {
    const { getAllListings: { refetch } } = this.props;
    const { currentPage } = this.state;
    let variables = {
      currentPage: 1,
      searchList: searchList
    };
    this.setState({ currentPage: 1 });
    refetch(variables);
  }

  handleSearchChange = (e) => {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleClick(self.state.searchList);
      }, 450)
    });
  }

   deleteListing = async(id, type) => {
    const { removeListing } = this.props;
    const { getAllListings: { refetch } } = this.props;

    let variables = { currentPage: 1 };
    await removeListing(id, type);
    this.setState({ currentPage: 1 });
    await refetch(variables);
  }

  render() {
    const { intl, removeListing, addListToRecommended, removeListFromRecommended } = this.props;
    const { getAllListings: { loading, getAllListings } } = this.props;
    const { currentPage, searchList } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <div>
          <h1 className={s.headerTitle}><FormattedMessage {...messages.listingsManagement} /></h1>
          <div className={cx(s.exportSection, s.exportSectionGridSub, 'bgBlack')}>
            <div>
              <Form.Control
                type="text"
                placeholder={formatMessage(messages.search)}
                onChange={(e) => this.handleSearchChange(e)}
                className={cx('searchInputControl', 'searchInputControlWidth', 'searchInputControlAR')}
              />
            </div>
            <div>
              {
                getAllListings && getAllListings.usersData.length > 0 && <a
                  href={`/export-admin-data?type=listings&keyword=${searchList}`}
                  className={cx(s.exportText, 'commonFloatLeft', 'textWhite')}
                >
                  <span className={s.vtrMiddle}><FormattedMessage {...messages.exportDataIntoCSV} /></span>
                  <span className={cx(s.exportLinkImg, 'exportLinkImgCommon')}>
                    <img src={ExportImage} className={s.exportImg} />
                  </span>
                </a>
              }
            </div>
          </div>
          <div className={cx('table-responsive', 'listing-table', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin', 'topScrollbarTable')}>
            <table className={cx("table", 'topScrollbarTableContent')}>
              <thead>
                <tr>
                  <th scope="col">{formatMessage(messages.idLabel)}</th>
                  <th scope="col">{formatMessage(messages.adminTitleLabel)}</th>
                  <th scope="col">{formatMessage(messages.hostNameLabel)}</th>
                  <th scope="col">{formatMessage(messages.hostEMailLabel)}</th>
                  <th scope="col">{formatMessage(messages.address)}</th>
                  <th scope="col">{formatMessage(messages.city)}</th>
                  <th scope="col">{formatMessage(messages.stateLabel)}</th>
                  <th scope="col">{formatMessage(messages.country)}</th>
                  <th scope="col">{formatMessage(messages.createdDate)}</th>
                  <th scope="col">{formatMessage(messages.recommendLabel)}</th>
                  <th scope="col">{formatMessage(messages.publishedLabel)}</th>
                  <th scope="col">{formatMessage(messages.ready)}</th>
                  <th scope="col">{formatMessage(messages.editLabel)}</th>
                  <th scope="col">{formatMessage(messages.viewLabel)}</th>
                  <th scope="col">{formatMessage(messages.delete)}</th>
                </tr>
              </thead>
              <tbody>
              {
                getAllListings && getAllListings.usersData.length > 0 ?
                getAllListings.usersData.map((value, key) => {
                  let viewListing = "/rooms/" + value.id;
                  let editListing = '/become-a-host/' + value.id + '/home';
                  let isPublished = value.isPublished ? 'Yes' : 'No';
                  let isReady = value.isReady ? 'Yes' : 'No';
                  return (
                    <tr key={key}>
                      <td>{value.id}</td>
                      <td>{value.title}</td>
                      <td>{value.user.profile.firstName}</td>
                      <td>{value.user.email}</td>
                      {
                        <td>{`${value.street + (value.buildingName ? ', ' + value.buildingName : '')}, ${value.city}, ${value.state}, ${value.country}, ${value.zipcode}`}</td>
                      }
                      <td>{value.city}</td>
                      <td>{value.state}</td>
                      <td>{value.country}</td>
                      <td>{moment(value.createdAt).format('MM/DD/YYYY')}</td>
                      {
                        value.recommend != null ?
                        <td><a href="#" onClick={() => removeListFromRecommended(value.id, currentPage, searchList)}><FormattedMessage {...messages.remove} /></a></td>
                        : <td><a href="#" onClick={() => addListToRecommended(value.id, currentPage, searchList)}><FormattedMessage {...messages.setLabel} /></a></td>
                      }
                      <td>{isPublished}</td>
                      <td>{isReady}</td>
                      <td><a href={editListing} target="_blank"><FormattedMessage {...messages.editLabel}/></a></td>
                      <td><a href={viewListing} target="_blank"><FormattedMessage {...messages.viewLabel}/></a></td>
                      <td>
                        <Confirm
                          onConfirm={() => this.deleteListing(value.id, "admin")}
                          body={formatMessage(messages.areYouSureDeleteWishList)}
                          confirmText={formatMessage(messages.confirmDelete)}
                          cancelText={formatMessage(messages.cancel)}
                          title={formatMessage(messages.deletingListingTitle)}
                        >
                          <a href="#"><FormattedMessage {...messages.delete} /></a>
                        </Confirm>
                      </td>
                    </tr>
                  )
                })
                : (
                  <tr><td colSpan="15">{formatMessage(messages.noRecordFound)}</td></tr>
                )
              }
              </tbody>
            </table>
          </div>
          <div>
            {
              getAllListings && getAllListings.usersData && getAllListings.usersData.length > 0
              && <div>
                <CustomPagination
                  total={getAllListings.count}
                  currentPage={currentPage}
                  defaultCurrent={1}
                  defaultPageSize={10}
                  change={this.paginationData}
                  paginationLabel={formatMessage(messages.lists)}
                  isScroll
                />
              </div>
            }
          </div>
        </div>
      </div>
    );
  }

}

const mapState = (state) => ({
});

const mapDispatch = {
  removeListing,
  addListToRecommended,
  removeListFromRecommended
};
export default compose(
  injectIntl,
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(listingsQuery, {
    name: 'getAllListings',
    options: {
      variables: {
        currentPage: 1,
        searchList: ''
      },
      fetchPolicy: 'network-only',
    }
  })
)(ListingManagement);