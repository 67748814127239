import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import { 
  Form,
} from 'react-bootstrap';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import FileList from './FileList';
import CustomPagination from '../../CustomPagination';

import DocumentManagement from './DocumentManagementQuery.graphql';
import showAllDocumentQuery from './showAllDocumentQueryFile.graphql';
import messages from '../../../locale/messages';
import { sendEmail } from '../../../core/email/sendEmail';
import { debounce } from '../../../helpers/debounce';
import showToaster from '../../../helpers/showToaster';

import s from './DocumentVerification.css';
import bt from '../../../components/commonStyle.css';

class DocumentVerification extends React.Component {

  static propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.handleSearchChange = debounce(this.handleSearchChange.bind(this));
  }

  paginationData = (currentPage) => {
    const { showAllDocument: { refetch }, setStateVariable } = this.props;
    let variables = { currentPage };
    setStateVariable({ currentPage });
    refetch(variables);
  }

  handleSearchChange(searchList) {
    const { showAllDocument: { refetch }, setStateVariable } = this.props;
    let variables = {
      currentPage: 1,
      searchList,
    };
    setStateVariable(variables);
    refetch(variables);
  }

  handleUpdate = async (id, status, item) => {
    const { mutate, currentPage } = this.props;
    const { showAllDocument: { refetch }, setStateVariable } = this.props;
    let variables = { currentPage };
    const { data } = await mutate({
      variables: {
        userId: id,
        isIdVerification: status
      },
    });

    setStateVariable({ currentPage });
    refetch(variables);

    if (data.DocumentManagement.status === 'success') {
      if (status == 0) {
        showToaster({ messageId: 'docVerify', toasterType: 'success' })
        return;
      }
      let msg = 'Documents has been ';
      msg += (status == 1) ? 'Approved!' : 'Rejected!';
      let content = {
        name: item.profile.firstName,
        verificationStatus: (status == 1) ? 'approved' : 'rejected'
      }
      // TODO enable emails
      // sendEmail(item.email, 'documentVerification', content);
      showToaster({ messageId: 'commonError', toasterType: 'success', requestContent: msg })
    } else {
      showToaster({ messageId: 'commonError', toasterType: 'error' })
    }

  }


  render() {
    const { showAllDocument: { showAllDocument }, currentPage } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <div>
          <h1 className={s.headerTitle}><FormattedMessage {...messages.documentVerificationManagement} /></h1>
          <div className={cx(s.exportSection, s.exportSectionGridSub, 'bgBlack')}>
            <Form.Group className={s.noMargin}>
              <Form.Control
                type="text"
                placeholder={formatMessage(messages.search)}
                onChange={(e) => this.handleSearchChange(e.target && e.target.value)}
                className={cx('searchInputControl', 'searchInputControlWidth', 'searchInputControlAR')}
              />
            </Form.Group>
          </div>
          <div className={cx('table-responsive', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{formatMessage(messages.profileID)}</th>
                  <th scope="col">{formatMessage(messages.hostNameLabel)}</th>
                  <th scope="col">{formatMessage(messages.hostEMailLabel)}</th>
                  <th scope="col">{formatMessage(messages.RequestedFiles)}</th>
                  <th scope="col">{formatMessage(messages.actionLabel)}</th>
                </tr>
              </thead>
              <tbody>
                {
                  showAllDocument && showAllDocument.results && showAllDocument.results.length > 0 ?
                  showAllDocument.results.map((value, key) => {
                    return (
                      <tr key={key}>
                        <td>{value.profile.profileId}</td>
                        <td>{value.profile.firstName}</td>
                        <td>{value.email}</td>
                        <td>
                          <FileList key={'f' + key} data={value.document} />
                        </td>
                        <td>
                          <div>
                            <select 
                              className={cx(bt.commonControlSelect, s.userVerticalAlign, s.btnMarginBottom, s.selectMargin)}
                              value={value.verification.isIdVerification} 
                              onChange={(e) => this.handleUpdate(value.id, e && e.target && e.target.value, value)}
                            >
                              <option value={0}>{formatMessage(messages.messageStatus5)}</option>
                              <option value={1}>{formatMessage(messages.documentApprove)}</option>
                              <option value={2}>{formatMessage(messages.documentReject)}</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                  : (
                    <tr><td colSpan="5">{formatMessage(messages.noRecordFound)}</td></tr>
                  )
                }
              </tbody>
            </table>
          </div>
          {
            showAllDocument && showAllDocument.results && showAllDocument.results.length > 0
            && <div>
              <CustomPagination
                total={showAllDocument.count}
                currentPage={currentPage}
                defaultCurrent={1}
                defaultPageSize={10}
                change={this.paginationData}
                paginationLabel={formatMessage(messages.usersLabel)}
                isScroll
              />
            </div>
          }
        </div>
      </div>
    );
  }

}

const mapState = (state) => ({
});

const mapDispatch = {
};

export default compose(injectIntl,
  withStyles(s, bt),
  connect(mapState, mapDispatch),
  graphql(DocumentManagement, { options: { fetchPolicy: 'network-only' } }),
  graphql(showAllDocumentQuery, {
    name: 'showAllDocument',
    options: (props) => ({
      variables: {
        currentPage: props.currentPage,
        searchList: props.searchList,
      },
      fetchPolicy: 'network-only',
    })
  })
)(DocumentVerification);